import React from "react";

import TextInputBlock from "./blocks/TextInputBlock";
import TextareaBlock from "./blocks/TextareaBlock";
import MoveBlock from "./blocks/MoveBlock";
import ImageBlock from "./blocks/ImageBlock";

import BlockAdder from "./BlockAdder";
import BlockControl from "./BlockControl";

import './Editor.css';
import YoutubeBlock from "./blocks/YoutubeBlock";
import GifBlock from "./blocks/GifBlock";

class Editor extends React.Component {

  updateName = (blockData) => {
    let tacticContent = this.getTacticContentCopy();
    tacticContent.name = blockData.value;
    this.props.onTacticContentChange(tacticContent);
  }

  updateSummary = (blockData) => {
    let tacticContent = this.getTacticContentCopy();
    tacticContent.summary = blockData.value;
    this.props.onTacticContentChange(tacticContent);
  }

  onBlockChange = (blockData, index) => {
    let tacticContent = this.getTacticContentCopy();
    tacticContent.blocks[index].value = blockData;
    this.props.onTacticContentChange(tacticContent);
  }

  onBlockAdd = (blockDefaultData, afterIndex) => {
    let tacticContent = this.getTacticContentCopy();
    if (afterIndex === null) {
      tacticContent.blocks.push(blockDefaultData);
    } else if (afterIndex === -1) {
      tacticContent.blocks.unshift(blockDefaultData);
    } else {
      tacticContent.blocks.splice(afterIndex + 1, 0, blockDefaultData);
    }
    this.props.onTacticContentChange(tacticContent);
  }

  getTacticContentCopy = () => {
    return JSON.parse(JSON.stringify(this.props.tacticContent));
  }

  getMoveIndex = (blockIndex) => {
    return this.props.tacticContent.blocks.reduce((accumulator, block, index) => {
      return index > blockIndex || block.type !== 'move' ? accumulator : ++accumulator;
    }, -1);
  }

  onBlockDelete = (index) => {
    let tacticContent = this.getTacticContentCopy();
    tacticContent.blocks.splice(index, 1);
    this.props.onTacticContentChange(tacticContent);
  }

  onBlockUp = (index) => {
    let tacticContent = this.getTacticContentCopy();
    const block = tacticContent.blocks[index];
    tacticContent.blocks[index] = tacticContent.blocks[index - 1];
    tacticContent.blocks[index - 1] = block;
    this.props.onTacticContentChange(tacticContent);
  }

  onBlockDown = (index) => {
    let tacticContent = this.getTacticContentCopy();
    const block = tacticContent.blocks[index];
    tacticContent.blocks[index] = tacticContent.blocks[index + 1];
    tacticContent.blocks[index + 1] = block;
    this.props.onTacticContentChange(tacticContent);
  }

  render() {
    return (
      <div className="editor-wrap">
        <TextInputBlock
          value={this.props.tacticContent.name}
          className="header-block"
          placeholder="Title Tactic"
          onChange={this.updateName}
        />
        <TextareaBlock
          value={this.props.tacticContent.summary}
          className="header-block"
          placeholder="Summary Tactic"
          onChange={this.updateSummary}
        />
        <BlockAdder
          isMove={false}
          afterIndex={-1}
          isLast={this.props.tacticContent.blocks.length === 0}
          onBlockAdd={this.onBlockAdd}
        />
        {this.props.tacticContent.blocks.map((block, index) => {
          const moveIndex = this.getMoveIndex(index);
          return (
            <BlockControl
              key={'block-' + index}
              blockIndex={index}
              showUp={index !== 0}
              showDown={index < this.props.tacticContent.blocks.length - 1}
              onBlockDelete={() => this.onBlockDelete(index)}
              onBlockUp={() => this.onBlockUp(index)}
              onBlockDown={() => this.onBlockDown(index)}
            >
              {
                {
                  move: (
                    <MoveBlock
                      move={block.value}
                      index={moveIndex}
                      onMoveChange={(blockData) => this.onBlockChange(blockData, index)}
                      axiosInstance={this.props.axiosInstance}
                    />
                  ),
                  text: (
                    <TextareaBlock
                      value={block.value}
                      className="regular-block"
                      placeholder="Enter text"
                      onChange={(blockData) => this.onBlockChange(blockData.value, index)}
                    />
                  ),
                  image: (
                    <ImageBlock
                      value={block.value}
                      onChange={(blockData) => this.onBlockChange(blockData.value, index)}
                      axiosInstance={this.props.axiosInstance}
                    />
                  ),
                  youtube: (
                    <YoutubeBlock
                      value={block.value}
                      onChange={(blockData) => this.onBlockChange(blockData.value, index)}
                    />
                  ),
                  gif: (
                    <GifBlock
                      value={block.value}
                      onChange={(blockData) => this.onBlockChange(blockData.value, index)}
                    />
                  ),
                }[block.type]
              }
              <BlockAdder
                isMove={false}
                afterIndex={index}
                isLast={this.props.tacticContent.blocks.length - 1 === index}
                onBlockAdd={this.onBlockAdd}
              />
            </BlockControl>
          );
        })}
      </div>
    );
  }
}

export default Editor;
