import React from 'react';
import {Col, Row, Input} from "antd";
import { SearchOutlined } from '@ant-design/icons';

import './FeedFilter.css';

class FeedFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filtersCollapsed: props.feed === 'subscriptions',
      showReset: false,
      searchPhrase: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.feed !== this.props.feed) {
      this.setState({
        filtersCollapsed: this.props.feed === 'subscriptions'
      })
    }
  }

  onChange = (value) => {
    this.setState({
      showReset: !!value
    }, () => {
      this.props.onChangeSearchPhrase(value)
    });
  }

  toggleFilterCollapse = () => {
    this.setState({
      filtersCollapsed: !this.state.filtersCollapsed
    });
  }

  renderFilter(filter) {
    return (
      <div key={filter.name} className="select-filter-wrap">
        {filter.options.map((option) => {
          return (
            <span
              key={option.value}
              className={"select-filter-option " + (this.props.filterValues[filter.name].includes(option.value) ? 'selected' : '')}
              onClick={() => this.props.onSelectFilter(filter.name, option.value)}
            >
              {option.name}
            </span>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div className="feed-filter">
        <div className="first-line-wrap">
          <Row>
            <Col flex="auto">
              <Input
                size="large"
                placeholder="Search tactics, moves ..."
                value={this.props.searchPhrase}
                className="filter-search-input"
                prefix={<SearchOutlined />}
                suffix={
                  this.state.showReset ?
                    <svg onClick={() => this.onChange('')} className="reset-search" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.2857 0H1.71429C0.767857 0 0 0.767857 0 1.71429V14.2857C0 15.2321 0.767857 16 1.71429 16H14.2857C15.2321 16 16 15.2321 16 14.2857V1.71429C16 0.767857 15.2321 0 14.2857 0ZM14.8571 14.2857C14.8571 14.6 14.6 14.8571 14.2857 14.8571H1.71429C1.4 14.8571 1.14286 14.6 1.14286 14.2857V1.71429C1.14286 1.4 1.4 1.14286 1.71429 1.14286H14.2857C14.6 1.14286 14.8571 1.4 14.8571 1.71429V14.2857ZM11.3857 5.525L8.91072 8L11.3857 10.475C11.5536 10.6429 11.5536 10.9143 11.3857 11.0821L11.0821 11.3857C10.9143 11.5536 10.6429 11.5536 10.475 11.3857L8 8.91072L5.525 11.3857C5.35714 11.5536 5.08571 11.5536 4.91786 11.3857L4.61429 11.0821C4.44643 10.9143 4.44643 10.6429 4.61429 10.475L7.08929 8L4.61429 5.525C4.44643 5.35714 4.44643 5.08571 4.61429 4.91786L4.91786 4.61429C5.08571 4.44643 5.35714 4.44643 5.525 4.61429L8 7.08929L10.475 4.61429C10.6429 4.44643 10.9143 4.44643 11.0821 4.61429L11.3857 4.91786C11.55 5.08571 11.55 5.35714 11.3857 5.525Z" fill="#828282"/>
                    </svg>
                    : null
                }
                onChange={(e) => this.onChange(e.target.value)}
              />
            </Col>
            <Col flex="90px">
              <div className="filter-wrap-button" onClick={this.toggleFilterCollapse}>
                <span className="filters-icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 2.28571V13.7143C0 14.9643 1.03571 16 2.28571 16H13.7143C14.9643 16 16 14.9643 16 13.7143V2.28571C16 1.03571 14.9643 0 13.7143 0H2.28571C1.03571 0 0 1.03571 0 2.28571ZM13.7143 1.14286C14.3571 1.14286 14.8571 1.64286 14.8571 2.28571V13.7143C14.8571 14.3571 14.3571 14.8571 13.7143 14.8571H2.28571C1.64286 14.8571 1.14286 14.3571 1.14286 13.7143V2.28571C1.14286 1.64286 1.64286 1.14286 2.28571 1.14286H13.7143ZM11.4286 10.8571C11.4286 11.1786 11.6786 11.4286 12 11.4286H13.1429C13.4643 11.4286 13.7143 11.1786 13.7143 10.8571C13.7143 10.5357 13.4643 10.2857 13.1429 10.2857H12C11.6786 10.2857 11.4286 10.5357 11.4286 10.8571ZM9.14286 9.14286V10.2857H2.85714C2.53571 10.2857 2.28571 10.5357 2.28571 10.8571C2.28571 11.1786 2.53571 11.4286 2.85714 11.4286H9.14286V12.5714C9.14286 12.8929 9.39286 13.1429 9.71429 13.1429C10.0357 13.1429 10.2857 12.8929 10.2857 12.5714V9.14286C10.2857 8.82143 10.0357 8.57143 9.71429 8.57143C9.39286 8.57143 9.14286 8.82143 9.14286 9.14286ZM6.85714 5.14286C6.85714 5.46429 7.10714 5.71429 7.42857 5.71429H13.1429C13.4643 5.71429 13.7143 5.46429 13.7143 5.14286C13.7143 4.82143 13.4643 4.57143 13.1429 4.57143H7.42857C7.10714 4.57143 6.85714 4.82143 6.85714 5.14286ZM4.57143 3.42857V4.57143H2.85714C2.53571 4.57143 2.28571 4.82143 2.28571 5.14286C2.28571 5.46429 2.53571 5.71429 2.85714 5.71429H4.57143V6.85714C4.57143 7.17857 4.82143 7.42857 5.14286 7.42857C5.46429 7.42857 5.71429 7.17857 5.71429 6.85714V3.42857C5.71429 3.10714 5.46429 2.85714 5.14286 2.85714C4.82143 2.85714 4.57143 3.10714 4.57143 3.42857Z" fill="#828282"/>
                </svg>
              </span>
                <span className="filter-wrap-label">
                Filters
              </span>
                <span className="filters-collapse-icon">
                <svg className={this.state.filtersCollapsed ? 'collapsed' : ''} width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.147541 3.52074L3.68671 0.129032C3.77888 0.0368664 3.88948 0 4.00007 0C4.11067 0 4.22127 0.0368664 4.31344 0.129032L7.85261 3.52074C8.01851 3.68664 8.03694 3.96313 7.85261 4.14747C7.68671 4.31336 7.41021 4.31336 7.22588 4.14747L4.00007 1.05069L0.774269 4.14747C0.589937 4.31336 0.313439 4.31336 0.147541 4.14747C-0.0367913 3.96313 -0.0183581 3.68664 0.147541 3.52074Z" fill="#828282"/>
                </svg>
              </span>
              </div>
            </Col>
          </Row>
        </div>
        <div className={"second-line-wrap " + (this.state.filtersCollapsed ? 'collapsed' : '')}>
          {this.props.filters.map((filter) => {
            return this.renderFilter(filter)
          })}
        </div>
      </div>
    );
  }
}

export default FeedFilter;
