import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import './Info.css';
import {Col, Row} from "antd";

export default function Info (props) {

  const PRIVACY = 'privacy-policy';
  const TERMS = 'terms-of-use';
  const COOKIES = 'cookie-policy';

  const pages = {
    [PRIVACY]: 'Privacy Policy',
    [TERMS]: 'Terms of Use',
    [COOKIES]: 'Cookie Policy',
  };

  let navigate = useNavigate();

  const dummy = (
    <>
      <p>
        If you are not yet the required age to manage your Google Account, you can only use it with the permission of a parent or legal guardian. To do this, the parent or legal representative needs to read these terms with you.
        If you are a parent or legal representative of a child and allow him to use Google services, these terms apply to you and you are responsible for the child's actions in our services.
        Some Google services have specific age restrictions. They are specified in additional terms and conditions of use for individual services.
      </p>
      <p>
        If you are not yet the required age to manage your Google Account, you can only use it with the permission of a parent or legal guardian. To do this, the parent or legal representative needs to read these terms with you.
        If you are a parent or legal representative of a child and allow him to use Google services, these terms apply to you and you are responsible for the child's actions in our services.
        Some Google services have specific age restrictions. They are specified in additional terms and conditions of use for individual services.
      </p>
      <h3>Terms Of Service</h3>
      <p>
        If you are not yet the required age to manage your Google Account, you can only use it with the permission of a parent or legal guardian. To do this, the parent or legal representative needs to read these terms with you.
        If you are a parent or legal representative of a child and allow him to use Google services, these terms apply to you and you are responsible for the child's actions in our services.
        Some Google services have specific age restrictions. They are specified in additional terms and conditions of use for individual services.
      </p>
      <p>
        If you are not yet the required age to manage your Google Account, you can only use it with the permission of a parent or legal guardian. To do this, the parent or legal representative needs to read these terms with you.
        If you are a parent or legal representative of a child and allow him to use Google services, these terms apply to you and you are responsible for the child's actions in our services.
        Some Google services have specific age restrictions. They are specified in additional terms and conditions of use for individual services.
      </p>
      <p>
        If you are not yet the required age to manage your Google Account, you can only use it with the permission of a parent or legal guardian. To do this, the parent or legal representative needs to read these terms with you.
        If you are a parent or legal representative of a child and allow him to use Google services, these terms apply to you and you are responsible for the child's actions in our services.
        Some Google services have specific age restrictions. They are specified in additional terms and conditions of use for individual services.
      </p>
      <p>
        If you are not yet the required age to manage your Google Account, you can only use it with the permission of a parent or legal guardian. To do this, the parent or legal representative needs to read these terms with you.
        If you are a parent or legal representative of a child and allow him to use Google services, these terms apply to you and you are responsible for the child's actions in our services.
        Some Google services have specific age restrictions. They are specified in additional terms and conditions of use for individual services.
      </p>
      <h3>Terms Of Service</h3>
      <p>
        If you are not yet the required age to manage your Google Account, you can only use it with the permission of a parent or legal guardian. To do this, the parent or legal representative needs to read these terms with you.
        If you are a parent or legal representative of a child and allow him to use Google services, these terms apply to you and you are responsible for the child's actions in our services.
        Some Google services have specific age restrictions. They are specified in additional terms and conditions of use for individual services.
      </p>
      <p>
        If you are not yet the required age to manage your Google Account, you can only use it with the permission of a parent or legal guardian. To do this, the parent or legal representative needs to read these terms with you.
        If you are a parent or legal representative of a child and allow him to use Google services, these terms apply to you and you are responsible for the child's actions in our services.
        Some Google services have specific age restrictions. They are specified in additional terms and conditions of use for individual services.
      </p>
      <p>
        If you are not yet the required age to manage your Google Account, you can only use it with the permission of a parent or legal guardian. To do this, the parent or legal representative needs to read these terms with you.
        If you are a parent or legal representative of a child and allow him to use Google services, these terms apply to you and you are responsible for the child's actions in our services.
        Some Google services have specific age restrictions. They are specified in additional terms and conditions of use for individual services.
      </p>
    </>
  );

  const privacyContent = (
    <>
      <h3>1. Definitions</h3>
      <p>1.1. We want you to understand how and why Varcharr (“Varcharr,”
        “we” or “us”) collects, uses, and shares information about you when you use our site or mobile application
        (“Services”) or when you otherwise interact with us or receive a communication from us. </p><p>1.2. For the
        purposes of this Privacy Policy, all terms written with capital letters, such as e.g. Agreement, Service etc.
        shall have meanings given to them in the Terms and Conditions available here (Chapter: Definitions), unless the
        Privacy Policy defines them otherwise below:</p><p>1.2.1. Controller or We - an entity providing the Service to
        the Users: Varcharr.</p><p>1.2.2. Data - Personal data in relation to which an informational obligation is being
        fulfilled by continuously providing information included in this Privacy Policy;</p><p>1.2.3. You - You, i.e. a
        natural person whose Data are being processed by us as the Controller (as defined in GDPR), in relation to your
        use of the Service;</p><p>1.2.4. GDPR - Regulation 2016/679 of the European Parliament and the European Council
        from April 27, 2016 on the protection of individuals with regard to the processing of personal data and free
        movement of such data, as well as repealing Directive 95/46/WE (general regulation on data protection).</p>
      <h3>2. Info You Provide to Us</h3><p>2.1. We collect information you provide to us directly when you use the
        Services. This includes: </p>
      <table>
        <colgroup>
          <col/>
          <col/>
        </colgroup>
        <tbody>
        <tr>
          <td><p>Account information </p></td>
          <td><p>If you create a Varcharr account, we may require you to provide a username and
            password. Your username is public, and it doesn’t have to be related to your real name. You may also provide
            other account information, like an email address, bio, or profile picture. We also store your user account
            preferences and settings. </p></td>
        </tr>
        <tr>
          <td><p>Content you submit</p></td>
          <td><p>We collect the content you submit to the Services. This includes your tactics
            and comments including saved drafts, team logo, team banner, team description, and your reports and other
            communications with moderators and with us. Your content may include text, links, images, gifs, and
            videos. </p></td>
        </tr>
        <tr>
          <td><p>Actions you take </p></td>
          <td><p>We collect information about the actions you take when using the Services.
            This includes your interactions with content, like voting, saving, and reporting. It also includes your
            interactions with other users, such as following, subscribing, and blocking. We collect your interactions
            with communities, like your subscriptions or moderator status.</p></td>
        </tr>
        <tr>
          <td><p>Transactional information</p></td>
          <td><p>If you purchase products or services from us (e.g., Varcharr Premium), we will
            collect certain information from you, including your name, address, email address, and information about the
            product or service you are purchasing. Varcharr uses industry-standard payment processor services (for
            example, Stripe) to handle payment information.</p></td>
        </tr>
        <tr>
          <td><p>Other information </p></td>
          <td><p>You may choose to provide other information directly to us. For example, we
            may collect information when you fill out a form, participate in Varcharr-sponsored activities or
            promotions, apply for a job, request customer support, or otherwise communicate with us.</p></td>
        </tr>
        </tbody>
      </table>
      <h3>3. Info We Collect Automatically</h3><p>3.1. When you access or use our Services, we may also automatically
        collect information about you. This includes:</p>
      <table>
        <colgroup>
          <col/>
            <col/>
        </colgroup>
        <tbody>
        <tr>
          <td><p>Log and usage data</p></td>
          <td><p>We may log information when you access and use the Services. This may include
            your IP address, user-agent string, browser type, operating system, referral URLs, device information (e.g.,
            device IDs), device settings, mobile carrier name, pages visited, links clicked, the requested URL, and
            search terms. Except for the IP address used to create your account, Varcharr will delete any IP addresses
            collected after 100 days.</p></td>
        </tr>
        <tr>
          <td><p>Information collected from cookies and similar technologies</p></td>
          <td><p>We may receive information from cookies, which are pieces of data your browser
            stores and sends back to us when making requests, and similar technologies. We use this information to
            improve your experience, understand user activity, personalize content and advertisements, and improve the
            quality of our Services. For example, we store and retrieve information about your preferred language and
            other settings. See our <Link to={"/cookies"}>Cookie Notice</Link> for more
            information about how Varcharr uses cookies. </p></td>
        </tr>
        <tr>
          <td><p>Location information</p></td>
          <td><p>We may receive and process information about your location. For example, with
            your consent, we may collect information about the specific location of your mobile device (for example, by
            using GPS or Bluetooth). We may also receive location information from you when you choose to share such
            information on our Services, including by associating your content with a location, or we may derive your
            approximate location from other information about you, including your IP address.</p></td>
        </tr>
        </tbody>
      </table>
      <h3>4. Info Collected by 3rd Parties &amp; from Other Sources </h3><p>4.1. We may receive information about you
        from other sources, including from other users and third parties, and combine that information with the other
        information we have about you. For example, we may receive demographic or interest information about you from
        third parties, including advertisers (such as the fact that an advertiser is interested in showing you an ad),
        and combine it with our own data using a common account identifier such as a hash of an email address or a
        mobile-device ID. </p>
      <table>
        <colgroup>
          <col/>
            <col/>
        </colgroup>
        <tbody>
        <tr>
          <td><p>Linked services</p></td>
          <td><p>If you authorize or link a third-party service (e.g., an unofficial mobile app
            client) to access your Varcharr account, Varcharr receives information about your use of that service when
            it uses that authorization. Linking services may also cause the other service to send us information about
            your account with that service. For example, if you sign in to Varcharr with a third-party identity
            provider, that provider may share an email address with us. </p></td>
        </tr>
        <tr>
          <td><p>Embedded content</p></td>
          <td><p>Varcharr displays some linked content in-line on the Varcharr services via
            “embeds.” For example, Varcharr posts that link to YouTube may load the linked video or tweet within
            Varcharr directly from those services to your device so you don’t have to leave Varcharr to see it. In
            general, Varcharr does not control how third-party services collect data when they serve you their content
            directly via these embeds. As a result, embedded content is not covered by this privacy policy but by the
            policies of the service from which the content is embedded.</p></td>
        </tr>
        </tbody>
      </table>
      <p>4.2. We use information about you to:</p>
      <ul>
        <li><p>Provide, maintain, and improve the Services;</p></li>
        <li><p>Research and develop new services;</p></li>
        <li><p>Help protect the safety of Varcharr and our users, which includes blocking suspected spammers, addressing
          abuse, and enforcing the Varcharr User Agreement and our other policies;</p></li>
        <li><p>Send you technical notices, updates, security alerts, invoices, and other support and administrative
          messages;</p></li>
        <li><p>Provide customer service;</p></li>
        <li><p>Communicate with you about products, services, offers, promotions, and events, and provide other news and
          information we think will be of interest to you (for information about how to opt out of these communications,
          see Your Choices below);</p></li>
        <li><p>Monitor and analyze trends, usage, and activities in connection with our Services;</p></li>
        <li><p>Measure the effectiveness of ads shown on our Services; and</p></li>
        <li><p>Personalize the Services, and provide and optimize advertisements, content, and features that match user
          profiles or interests.</p></li>
      </ul>
      <p>4.3. Much of the information on the Services is public and accessible to everyone, even without an account. By
        using the Services, you are directing us to share this information publicly and freely.</p><p>4.4. When you
        submit content (including a post, comment) to a public part of the Services, any visitors to and users of our
        Services will be able to see that content, the username associated with the content, and the date and time you
        originally submitted the content. </p><p>4.5. Your Varcharr account has a profile page that is public. Your
        profile contains information about your activities on the Services, such as your username, prior posts and
        comments, karma, moderator status, Varcharr Premium status, and how long you have been a member of the Services.
        You can also choose for your profile to include the content you upvote.</p><p>4.6. We offer social sharing
        features that let you share content or actions you take on our Services with other media. Your use of these
        features enables the sharing of certain information with your friends or the public, depending on the settings
        you establish with the third party that provides the social sharing feature. For more information about the
        purpose and scope of data collection and processing in connection with social sharing features, please visit the
        privacy policies of the third parties that provide these social sharing features (e.g., Facebook, and
        Twitter).</p><p>4.7. We do not sell your personal information. However, in addition to the personal information
        that is displayed publicly as described above, we may share personal information in the following ways:</p>
      <ul>
        <li><p>With your consent. We may share information about you with your consent or at your direction.</p></li>
        <li><p>With linked services. If you link your Varcharr account with a third-party service, Varcharr will share
          the information you authorize with that third-party service. You can control this sharing as described in Your
          Choices below.</p></li>
        <li><p>With our service providers. We may share information with vendors, consultants, and other service
          providers who need access to such information to carry out work for us. Their use of personal data will be
          subject to appropriate confidentiality and security measures. A few examples: (i) payment processors who
          process transactions on our behalf, (ii) cloud providers who host our data and our services, (iii) third-party
          ads measurement providers who help us and advertisers measure the performance of ads shown on our
          Services.</p></li>
        <li><p>To comply with the law. We may share information in response to a request for information if we believe
          disclosure is in accordance with, or required by, any applicable law, regulation, legal process, or
          governmental request, including, but not limited to, meeting national security or law enforcement
          requirements. To the extent the law allows it, we will attempt to provide you with prior notice before
          disclosing your information in response to such a request. Our Transparency Report has additional information
          about how we respond to government requests.</p></li>
      </ul>
      <p>4.8. In an emergency. We may share information if we believe it's necessary to prevent imminent and serious
        bodily harm to a person.</p><p>4.9. To enforce our policies and rights. We may share information if we believe
        your actions are inconsistent with our User Agreement, rules, or other Varcharr policies, or to protect the
        rights, property, and safety of ourselves and others.</p><p>4.10. With our affiliates. We may share information
        between and among Varcharr, and any of our parents, affiliates, subsidiaries, and other companies under common
        control and ownership.</p><p>4.11. Aggregated or de-identified information. We may share information about you
        that has been aggregated or anonymized such that it cannot reasonably be used to identify you. For example, we
        may show the total number of times a post has been upvoted without identifying who the visitors were, or we may
        tell an advertiser how many people saw their ad.</p><h3>5. Data Subject and Consumer Info Requests</h3><p>5.1.
        Requests for a copy of the information Varcharr has about your account — including EU General Data Protection
        Regulation (<strong>“GDPR”</strong>) data subject access requests and California Consumer Privacy Act
        (“<strong>CCPA</strong>”) consumer information requests — and all other data subject and consumer requests under
        data protection laws should be sent via email to <a
          href="mailto:varcharrdatarequests@varcharr.gg">datarequests@varcharr.gg</a> from the email address that you
        have verified with your Varcharr account. </p><p>5.2. Before we process a request from you about your personal
        information, we need to verify the request via your access to your Varcharr account or to a verified email
        address associated with your Varcharr account. You may also designate an authorized agent to exercise these
        rights on your behalf. Varcharr does not discriminate against users for exercising their rights under data
        protection laws to make requests regarding their personal information.</p><h3>6. Additional Info for European
        Users</h3><p>6.1. Users in the European Economic Area have the right to request access to, rectification of, or
        erasure of their personal data; to data portability in certain circumstances; to request restriction of
        processing; to object to processing; and to withdraw consent for processing where they have previously provided
        consent. EEA users also have the right to lodge a complaint with their local supervisory authority.</p><p>6.2.
        As required by applicable law, we collect and process information about individuals in the EEA only where we
        have a legal basis for doing so. Our legal bases depend on the Services you use and how you use them. We process
        your information on the following legal bases:</p>
      <ul>
        <li><p>You have consented for us to do so for a specific purpose;</p></li>
        <li><p>We need to process the information to provide you the Services, including to operate the Services,
          provide customer support and personalized features and to protect the safety and security of the Services;</p>
        </li>
        <li><p>It satisfies a legitimate interest (which is not overridden by your data protection interests), such as
          preventing fraud, ensuring network and information security, enforcing our rules and policies, protecting our
          legal rights and interests, research and development, personalizing the Services, and marketing and promoting
          the Services; or</p></li>
        <li><p>We need to process your information to comply with our legal obligations.</p></li>
      </ul>
      <h3>7. Children Policy</h3><p>7.1. Children under the age of 13 are not allowed to create an account or otherwise
        use the Services. Additionally, if you are in the EEA, you must be over the age required by the laws of your
        country to create an account or otherwise use the Services, or we need to have obtained verifiable consent from
        your parent or legal guardian.</p><h3>8. Changes to This Policy</h3><p>8.1. We may change this Privacy Policy
        from time to time. If we do, we will let you know by revising the date at the top of the policy. If the changes,
        in our sole discretion, are material, we may also notify you by sending an email to the address associated with
        your account (if you have chosen to provide an email address) or by otherwise providing notice through our
        Services. We encourage you to review the Privacy Policy whenever you access or use our Services or otherwise
        interact with us to stay informed about our information practices and the ways you can help protect your
        privacy. By continuing to use our Services after Privacy Policy changes go into effect, you agree to be bound by
        the revised policy.</p><h3>9. Contact Us</h3><p>9.1. To send a GDPR data subject request or CCPA consumer
        request, or if you have other inquiries about your privacy rights, or if you have other questions about this
        Privacy Policy, please contact us at: <a
          href="mailto:varcharrdatarequests@varcharr.gg">datarequests@varcharr.gg</a><br/></p><p><strong>10. Date of
        publication</strong></p><p>10.1. Effective April 30, 2022. Last Revised April 30, 2022</p><p></p><p></p><p></p>
      <p></p><p>
      </p><p>&nbsp;</p>
    </>
  );

  const termsContent = (
    <>
      <h3>1. General Provisions</h3><p>1.1. Varcharr (hereinafter referred to as
        “Varcharr”) invites an Internet user (hereinafter referred to as the “User”) to use Varcharr available at:
        http://varcharr.gg (hereinafter referred to as the “Service”).</p><p>1.2. By starting to use the Service/its
        certain functions, the User is deemed to have accepted these Terms and terms of all of the above documents, in
        full, without any reservations and exceptions. If the User disagrees with any of the provisions of the said
        documents, the User may not use the Service.</p><p>1.3. Varcharr may amend these Terms without any special
        notice; revised Terms shall come into effect once posted online at the URL specified in this paragraph, unless
        otherwise stipulated in revised Terms. The Terms in force are always available at: https://varcharr.gg/info/</p>
      <p>1.4. If Varcharr makes any amendments to these Terms according to the procedure specified in Clause 1.3 hereof,
        and the User disagrees with these amendments, the User shall stop using the Service.</p><p>1.5. The Service
        grants to the User access to content and other information. All currently available functions of the Service
        along with any developments and/or newly added functions are subject to these Terms.</p><h3>2. Use of the
        Service. Certain Functions of the Service</h3><p>2.1. The Service enables the User to gain free access
        to data on esport tactics, text, video, image content, comments, etc.</p><p>2.2. Any data used in the Service
        are intended solely for non-commercial use. Upon that, it is prohibited to copy the information and/or
        materials, reproduce them, process, distribute, make available to the public (publish) through the Internet, use
        them in any way in the media and/or for commercial purposes without the right holder’s prior written permission,
        except for the cases expressly stipulated by these Terms, terms of use of other Varcharr services or documents
        specified in cl. 1.2 hereof.</p><p>2.3. Varcharr reserves the right, at its sole discretion, to limit the User’s
        access to the Service (or its particular functions, if technologically feasible) using their account, or
        completely block the User’s account in case of repeated non-compliance with these Terms, or take other measures
        against the User in order to comply with legislative requirements or respect third-party rights and legitimate
        interests.</p><h3>3. Date of publication</h3><p>3.1. Effective April 30, 2022. Last Revised April
        30, 2022</p><p>
      </p><p>&nbsp;</p>
    </>
  );

  const cookieContent = (
    <>
      <h3>1. General Intro</h3><p>1.1. This Cookie Notice explains how we use
        cookies and similar technologies as well as the options you have to control them.</p><h3>2. What are
        cookies and how does Varcharr use them?</h3><p>2.1. A cookie is a small text file that a site stores on
        your computer or mobile device when you visit the site. Browsers support cookies and similar technologies (such
        as local storage and pixels) so that a site like Varcharr can remember information about your visit and can use
        the information to improve your experience and to create aggregated anonymized statistics about usage of the
        site. In this Notice, we use the term “cookie” to refer both to cookies and similar technologies.</p><p>2.2.
        Cookies may be set by the site you are visiting (called “first-party cookies”) or by a third party, such as
        those who provide analytics or advertising services or interactive content on the site (“third-party cookies”).
        In addition to using cookies on our sites, we may also serve our cookies (specifically, our advertising pixel)
        on third-party sites operated by Varcharr advertisers who use our advertising technology.</p><p>2.3. Our
        first-party cookies include cookies that are strictly necessary, functional cookies, cookies related to
        analytics/performance and advertising-related cookies.</p><p>2.3.1. Strictly Necessary: These cookies are
        necessary for our services to function properly and securely and cannot be switched off in our systems. You can
        set your browser to block or alert you about these cookies, but then some parts of the site will not work. These
        include:</p>
      <table>
        <colgroup>
          <col/>
            <col/>
              <col/>
        </colgroup>
        <tbody>
        <tr>
          <td><p><strong>Cookie</strong></p></td>
          <td><p><strong>Purpose</strong></p></td>
          <td><p><strong>Expires</strong></p></td>
        </tr>
        <tr>
          <td><p>token<br/></p></td>
          <td><p>Stores a login token needed to act as a logged in user.</p></td>
          <td><p>1 month</p></td>
        </tr>
        </tbody>
      </table>
      <p>2.3.2. Functional: These cookies enable Varcharr to provide enhanced functionality and personalization. If you
        do not allow these cookies then some or all of these features may not function properly.</p><p>2.3.3. Analytics
        and Performance: These cookies allow us to count visits and traffic sources so we can measure and improve the
        performance of our site. They help us to know which pages are the most and least popular and see how visitors
        move around the site.</p><p>2.3.4. Advertising: We use these cookies to deliver advertisements, to make them
        more relevant and meaningful to users, and to track the efficiency of our advertising campaigns, both on our
        services and on other sites or mobile apps. Our third-party advertising partners may use these cookies to build
        a profile of your interests and deliver relevant advertising on other sites.</p><p>2.4. Third-Party
        Cookies:<em> </em>Varcharr’s services also include cookies from third parties that we partner with directly or
        indirectly. Many of these third parties are companies that work with us or with advertisers who advertise on
        Varcharr in order to help target ads or measure the results of an advertising campaign.</p><p>2.5. Third-Party
        Sites: Third parties who advertise on Varcharr may use our advertising technology, including our advertising
        pixel, on their sites. We may serve advertising cookies when you visit their sites so that we can measure the
        effectiveness of ads on Varcharr and to show you more relevant advertising on Varcharr. The placement of these
        cookies is controlled by the third-party sites.</p><h3>3. How do I control cookies and how my data is
        used?</h3><p>3.1. There are a number of ways you can control how information is collected from cookies
        on Varcharr and how that information is used.</p><h3>4. Your browser settings</h3><p>4.1. Your
        browser includes controls that allow you to manage the use of cookies by the sites that you visit. Most browsers
        have features that enable you to see and delete cookies stored on your device and to block cookies from all or
        selected sites. For more information, here are links to external help materials from some of the popular
        browsers:</p>
      <ul>
        <li><p><a target="_blank" href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla
          Firefox</a></p></li>
        <li><p><a target="_blank" href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Apple
          Safari</a></p></li>
        <li><p><a target="_blank" href="https://support.google.com/chrome/answer/95647">Google Chrome</a></p></li>
        <li><p><a target="_blank" href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a></p></li>
        <li><p><a target="_blank" href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies">Microsoft
          Edge</a></p></li>
        <li><p><a target="_blank"
          href="https://support.microsoft.com/en-us/topic/description-of-cookies-ad01aa7e-66c9-8ab2-7898-6652c100999d">Internet
          Explorer</a></p></li>
      </ul>
      <h3>5. Your mobile device settings</h3><p>5.1. Your mobile device may also include browser settings
        that allow you to manage the use of cookies, especially if the device supports installing apps such as iOS and
        Android devices. iOS and Android devices also include additional device settings that control whether
        advertising partners can use information about your app activity for advertising purposes. On iOS, you can
        search for a setting called <em>Limit Ad Tracking</em>. On Android, you can search for a setting called <em>Opt
          out of Ads Personalization</em>.</p><h3>6. Third-party opt-outs</h3><p>6.1. The major online
        advertising industry groups offer tools to limit how cookies are used for advertising purposes by participating
        third parties. More information is available on these sites from the <a
          href="https://optout.networkadvertising.org/">Network Advertising Initiative</a>, the <a
          href="https://optout.aboutads.info/">Digital Advertising Alliance</a>, and for users in the EU, the <a
          href="https://www.youronlinechoices.com/">European Interactive Digital Advertising Alliance</a>. Also, Google
        provides web users with the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser
          Add-on</a> to prevent the collection of data via Google Analytics.</p><h3>7. Date of
        publication</h3><p>7.1. Effective April 30, 2022. Last Revised April 30, 2022</p><p>
      </p>
    </>
  );

  return (
    <div className="info-wrap">
      <h1>Policies & Terms</h1>
      <Row>
        <Col span={6}>
          <div className="menu">
            {Object.keys(pages).map(( key ) => {
              return (
                <a
                  key={key}
                  className={props.page === key ? 'active' : ''}
                  onClick={() => {
                    navigate('/policies/' + key);
                    document.getElementById('root').scrollIntoView({behavior: 'smooth'});
                  }}
                >
                  {pages[key]}
                </a>
              );
            })}
          </div>
        </Col>
        <Col span={18}>
          {props.page === PRIVACY ? (
            <div className="content">
              <h2>Privacy Policy</h2>
              {privacyContent}
            </div>
          ) : null}
          {props.page === TERMS ? (
            <div  className="content">
              <h2>Terms of Use</h2>
              {termsContent}
            </div>
          ) : null}
          {props.page === COOKIES ? (
            <div  className="content">
              <h2>Cookie Policy</h2>
              {cookieContent}
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
