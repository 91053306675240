import React from "react";

import './SelectGame.css';

class SelectGame extends React.Component {

  getGames = () => {
    return [
      {
        game: 'csgo',
        name: 'CS:GO',
        image: '/img/game_logo_csgo.png',
        available: true,
      },
      {
        game: 'dota2',
        name: 'Dota 2',
        image: '/img/game_logo_dota2.png',
        available: false,
      },
      {
        game: 'lol',
        name: 'League of Legends',
        image: '/img/game_logo_lol.png',
        available: false,
      },
      {
        game: 'pubg',
        name: 'PUBG',
        image: '/img/game_logo_pubg.jpg',
        available: false,
      },
      {
        game: 'starcraft2',
        name: 'StarCraft II',
        image: '/img/game_logo_starcraft.jpg',
        available: false,
      },
      {
        game: 'valorant',
        name: 'Valorant',
        image: '/img/game_logo_valorant.jpg',
        available: false,
      },
    ];
  }

  render() {

    return (
      <div className="editor-select-game-wrap">
        <h1>Select Game</h1>
        <div className="games">
        {this.getGames().map((game) => {
          return (
            <div
              className={"game " + (game.available ? '' : 'not-available ' + (this.props.game === game.game ? 'selected' : ''))}
              style={{ backgroundImage: 'url("' + game.image + '")' }}
              key={game.game}
              onClick={() => {
                if (game.available) {
                  this.props.onGameSelect(game.game);
                }
              }}
            >
              <div className="overlay" />
              {!game.available ? (
                <span className="coming-soon">
                  Coming Soon
                </span>
              ) : null}
              <span className="name">{game.name}</span>
            </div>
          );
        })}
        </div>
      </div>
    );
  }
}

export default SelectGame;
