import React from "react";

import './IconCheck.css';

class IconCheck extends React.Component {
  render() {
    return (
      <span className="app-icon app-icon-check">
        <svg className="app-icon-check-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M6 0C2.675 0 0 2.675 0 6C0 9.325 2.675 12 6 12C9.325 12 12 9.325 12 6C12 2.675 9.325 0 6 0ZM8.9 4.9L5.7 8.1C5.55 8.225 5.375 8.3 5.2 8.3C5.025 8.3 4.85 8.225 4.7 8.1L3.1 6.5C2.825 6.225 2.825 5.775 3.1 5.5C3.375 5.225 3.825 5.225 4.1 5.5L5.2 6.6L7.9 3.9C8.175 3.625 8.625 3.625 8.9 3.9C9.175 4.175 9.175 4.625 8.9 4.9Z" fill="white"/>
        </svg>
        <svg className="app-icon-check-2" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.89999 1.9001L3.69999 5.1001C3.54999 5.2251 3.37499 5.3001 3.19999 5.3001C3.02499 5.3001 2.84999 5.2251 2.69999 5.1001L1.09999 3.5001C0.824988 3.2251 0.824988 2.7751 1.09999 2.5001C1.37499 2.2251 1.82499 2.2251 2.09999 2.5001L3.19999 3.6001L5.89999 0.900098C6.17499 0.625098 6.62499 0.625098 6.89999 0.900098C7.17499 1.1751 7.17499 1.6251 6.89999 1.9001Z" fill="white"/>
        </svg>
      </span>
    );
  }
}

export default IconCheck;
