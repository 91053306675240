import React from "react";

import './IconPublish.css';

class IconPublish extends React.Component {
  render() {
    return (
      <span className="app-icon app-icon-publish">
        <svg className="app-icon-publish-1" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M15.1875 11.5H14.0625C13.7461 11.5 13.5 11.7461 13.5 12.0625V12.625C13.5 13.2578 13.0078 13.75 12.375 13.75H3.375C2.74219 13.75 2.25 13.2578 2.25 12.625V3.625C2.25 2.99219 2.74219 2.5 3.375 2.5H3.9375C4.25391 2.5 4.5 2.25391 4.5 1.9375V0.8125C4.5 0.496094 4.25391 0.25 3.9375 0.25H3.375C1.51172 0.25 0 1.76172 0 3.625V12.625C0 14.4883 1.51172 16 3.375 16H12.375C14.2383 16 15.75 14.4883 15.75 12.625V12.0625C15.75 11.7461 15.5039 11.5 15.1875 11.5Z" fill="white"/>
        </svg>
        <svg className="app-icon-publish-2" width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.0039 5.02744L10.6953 0.175876C10.2383 -0.245999 9.5 0.105564 9.5 0.773533V3.3751C4.47266 3.44541 0.5 4.42978 0.5 9.17588C0.5 11.0743 1.73047 12.9728 3.10156 13.9571C3.52344 14.2735 4.12109 13.8868 3.98047 13.3595C2.57422 8.85947 5 7.8751 9.5 7.8751V10.4767C9.5 11.1446 10.2383 11.4962 10.6953 11.0743L16.0039 6.22275C16.3203 5.90635 16.3203 5.34385 16.0039 5.02744Z" fill="white"/>
        </svg>
      </span>
    );
  }
}

export default IconPublish;
