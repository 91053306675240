import React from "react";
import IconPlus from "../../ui/Icons/IconPlus";
import IconButton from "../../ui/IconButton/IconButton";

import './Teams.css'
import { Modal, BackTop } from "antd";
import TextInput from "../../ui/Input/TextInput";
import {Link} from "react-router-dom";
import SelectInput from "../../ui/Input/SelectInput";

class Teams extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toCreate: false,
      newTeamName: '',
      newTeamGame: 'csgo',
    }
  }

  onCreateModalOk = () => {
    this.setState({
      toCreate: false,
    })
  }

  onCreateModalCancel = () => {
    this.setState({
      toCreate: false,
    })
  }

  render() {
    return (
      <div className="teams-wrap">

        {this.props.teams !== null && this.props.teams.length === 0 ? (
          <div className="no-teams">
            <svg width="125" height="100" viewBox="0 0 125 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.4" d="M23.2422 53.125C21.0938 49.4141 17.1875 46.875 12.5 46.875C5.66406 46.875 0 52.5391 0 59.375C0 66.2109 5.66406 71.875 12.5 71.875C17.1875 71.875 21.0938 69.3359 23.2422 65.625H34.9609C34.5703 63.6719 34.375 61.5234 34.375 59.375C34.375 57.2266 34.5703 55.0781 34.9609 53.125H23.2422ZM112.5 12.5C105.664 12.5 100 18.1641 100 25C100 31.8359 105.664 37.5 112.5 37.5C119.336 37.5 125 31.8359 125 25C125 18.1641 119.336 12.5 112.5 12.5Z" fill="#2A6CED"/>
              <path d="M112.5 75C110.352 75 108.203 75.5859 106.445 76.5625L89.4531 66.4062C90.2344 64.2578 90.625 61.9141 90.625 59.375C90.625 56.0547 90.0391 52.9297 88.8672 50.1953L101.953 40.4297C98.4375 38.0859 95.8984 34.5703 94.5312 30.2734L81.25 40.0391C76.9531 36.5234 71.6797 34.375 65.625 34.375C64.0625 34.375 62.5 34.5703 61.1328 34.7656L53.9062 19.5312C55.4688 17.5781 56.25 15.2344 56.25 12.5C56.25 5.66406 50.5859 0 43.75 0C36.9141 0 31.25 5.66406 31.25 12.5C31.25 18.9453 36.3281 24.2188 42.5781 24.8047L49.8047 40.2344C44.1406 44.7266 40.625 51.5625 40.625 59.375C40.625 73.2422 51.7578 84.375 65.625 84.375C72.4609 84.375 78.7109 81.6406 83.2031 77.1484L100 87.3047V87.5C100 94.3359 105.664 100 112.5 100C119.336 100 125 94.3359 125 87.5C125 80.6641 119.336 75 112.5 75ZM65.625 68.75C60.5469 68.75 56.25 64.4531 56.25 59.375C56.25 54.2969 60.5469 50 65.625 50C70.7031 50 75 54.2969 75 59.375C75 64.4531 70.7031 68.75 65.625 68.75Z" fill="#2A6CED"/>
            </svg>
            <h1>No Teams</h1>
            <p>
              Hmm… looks like you haven’t join any teams yet
            </p>
            <IconButton
              title="Create a team"
              size="big"
              icon={<IconPlus/>}
              onClick={() => {
                this.setState({
                  newTeamName: '',
                  toCreate: true,
                })
              }}
            />
          </div>
        ) : null}
        {this.props.teams !== null && this.props.teams.length > 0 ? (
          <>
            <div className="content-header no-border">
              <h1>Teams</h1>
            </div>
            <div className="teams">
              {this.props.teams.map((team) => {
                return (
                  <Link to={'/team/' + team.hash} key={team.hash} className="team">
                    <div
                      className="team-image"
                      style={team.image ? { backgroundImage: 'url("' + team.image + '")' } : {}}
                    >
                      {team.image ? null : (
                        <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M44.6429 0.571289H5.35714C2.45536 0.571289 0 3.02665 0 5.92843V45.2141C0 48.1159 2.45536 50.5713 5.35714 50.5713H44.6429C47.5446 50.5713 50 48.1159 50 45.2141V5.92843C50 3.02665 47.5446 0.571289 44.6429 0.571289ZM48.2143 45.2141C48.2143 47.2231 46.6518 48.7856 44.6429 48.7856H5.35714C3.34821 48.7856 1.78571 47.2231 1.78571 45.2141V36.2856H48.2143V45.2141ZM15.7366 34.4999H3.90625L13.9509 23.8972C14.1741 23.7856 14.3973 23.7856 14.6205 23.8972L20.2009 29.8124L15.7366 34.4999ZM31.4732 20.4374C31.808 20.1025 32.4777 20.1025 32.8125 20.4374L46.0938 34.4999H18.192L31.4732 20.4374ZM48.2143 34.0534L34.0402 19.2097C33.0357 18.2052 31.25 18.2052 30.2455 19.2097L21.4286 28.4731L15.9598 22.6695C15.067 21.7766 13.5045 21.7766 12.6116 22.6695L1.78571 34.0534V5.92843C1.78571 3.9195 3.34821 2.357 5.35714 2.357H44.6429C46.6518 2.357 48.2143 3.9195 48.2143 5.92843V34.0534ZM12.5 8.607C10.0446 8.607 8.03571 10.6159 8.03571 13.0713C8.03571 15.5266 10.0446 17.5356 12.5 17.5356C14.9554 17.5356 16.9643 15.5266 16.9643 13.0713C16.9643 10.6159 14.9554 8.607 12.5 8.607ZM12.5 15.7499C11.0491 15.7499 9.82143 14.5222 9.82143 13.0713C9.82143 11.6204 11.0491 10.3927 12.5 10.3927C13.9509 10.3927 15.1786 11.6204 15.1786 13.0713C15.1786 14.5222 13.9509 15.7499 12.5 15.7499Z" fill="#E0E0E0"/>
                        </svg>
                      )}
                    </div>
                    <div className="team-info">
                      <div
                        className="team-icon"
                        style={team.icon ? { backgroundImage: 'url("' + team.icon + '")' } : {}}
                      >
                        {team.icon ? null : (
                          <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25ZM5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM8.8125 5.64844C9.21094 5.88281 9.65625 6 10.125 6C11.5781 6 12.75 4.82812 12.75 3.375C12.75 1.92188 11.5781 0.75 10.125 0.75C9.82031 0.75 9.53906 0.796875 9.25781 0.890625C9.04688 0.960938 8.95312 1.17188 9.02344 1.38281C9.09375 1.57031 9.30469 1.66406 9.49219 1.61719C9.70312 1.54688 9.91406 1.5 10.125 1.5C11.1562 1.5 12 2.34375 12 3.375C12 4.40625 11.1562 5.25 10.125 5.25C9.79688 5.25 9.46875 5.15625 9.1875 4.99219C9 4.89844 8.78906 4.94531 8.67188 5.13281C8.57812 5.32031 8.625 5.55469 8.8125 5.64844ZM11.5078 7.5H10.5C10.2891 7.5 10.125 7.66406 10.125 7.875C10.125 8.08594 10.2891 8.25 10.5 8.25H11.5078C13.0078 8.25 14.25 9.49219 14.25 10.9922C14.25 11.1328 14.1328 11.25 13.9922 11.25H11.625C11.4141 11.25 11.25 11.4141 11.25 11.625C11.25 11.8359 11.4141 12 11.625 12H13.9922C14.5547 12 15 11.5547 15 10.9922C15 9.07031 13.4297 7.5 11.5078 7.5Z" fill="#828282"/>
                          </svg>
                        )}
                      </div>
                      <div className="team-link">{team.name}</div>
                      <div className="team-players">
                        {team.players} player{team.players === 1 ? '' : 's'}
                      </div>
                    </div>
                  </Link>
                )
              })}
              <div
                className="team new-team"
                onClick={() => {
                  this.setState({
                    newTeamName: '',
                    toCreate: true,
                  })
                }}
              >
                + Add Team
              </div>
            </div>
          </>
        ) : null}
        <Modal
          title={'Create team'}
          visible={this.state.toCreate}
          className={'app-modal app-modal-team'}
          onCancel={() => {
            this.setState({
              newTeamName: '',
              toCreate: false
            })
          }}
          footer={[
            <IconButton
              title="Create"
              size="big"
              onClick={() => {
                if (!this.state.newTeamName) {
                  return;
                }
                this.props.onCreateTeam(this.state.newTeamName, this.state.newTeamGame);
                this.setState({
                  toCreate: false
                })
              }}
              cantPress={!this.state.newTeamName}
              className={this.state.newTeamName ? '' : 'disabled'}
            />
          ]}
        >
          <TextInput
            value={this.state.newTeamName}
            label="Team Name"
            onChange={(value) => {
              if (value.length > 30) {
                value = value.substr(0, 30);
              }
              this.setState({
                newTeamName: value
              });
            }}
          />
          <SelectInput
            value={this.state.newTeamGame}
            values={{
              csgo: 'CS:GO',
            }}
            label="Game"
            onChange={(value) => {
              this.setState({
                newTeamGame: value
              });
            }}
          />
        </Modal>
        <BackTop />
      </div>
    );
  }
}

export default Teams
