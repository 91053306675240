import React, { useState, useContext, useEffect } from "react";
import AxiosContext from "../context/AxiosContext";
import UserContext from "../context/UserContext";
import Teams from "../components/Team/Teams";
import LoginViaSteam from "../dummies/LoginViaSteam";

export default function TeamsView() {

  const axiosInstance = useContext(AxiosContext);
  const user = useContext(UserContext);

  const [teams, setTeams] = useState(null);

  useEffect(() => {
    if (user && teams === null) {
      loadTeams()
    }
  }, []);

  useEffect(() => {
    if (user && teams === null) {
      loadTeams()
    }
  }, [user]);

  const loadTeams = () => {
    axiosInstance.get('/api/teams')
      .then((response) => {
        setTeams(response.data.teams)
      }).catch(error => console.log(error));
  };

  const onCreateTeam = (name, game) => {
    axiosInstance.post('/api/team/create', { name, game })
      .then((response) => {
        setTeams(response.data.teams)
      }).catch(error => console.log(error));
  }

  if (!user) {
    return <LoginViaSteam/>;
  }

  return (
    <Teams
      teams={teams}
      onCreateTeam={onCreateTeam}
    />
  );
}
