import React from "react";
import { Tag, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import './SelectOptions.css';
import IconButton from "../../ui/IconButton/IconButton";

class SelectOptions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tagInputVisible: false,
      tagInputValue: '',
    };
    this.inputRef = React.createRef();
  }

  handleInputChange = (e) => {
    this.setState({ tagInputValue: e.target.value });
  }

  handleInputConfirm = (e) => {
    let value = e.target.value.toLowerCase().replace(/[^a-z0-9]/g, '');
    let tags = this.props.tags;
    if (value && tags.indexOf(value) === -1) {
      tags = [...tags, value];
    }
    this.props.onTagsChange(tags);
    this.setState({
      tagInputVisible: false,
      tagInputValue: '',
    })
  }

  render() {
    return (
      <div className="editor-select-options-wrap">
        <h1>Select Options</h1>
        <div className="options">
          {this.props.options.map((option) => {
            return (
              <div className="option-wrap" key={option.name}>
                <div className="option">
                  <span className="header">{option.label}</span>
                  {option.options.map((item) => {
                    return (
                      <span
                        className={"item " + (this.props.selectedOptions[option.name] === item.value ? 'selected' : '')}
                        key={item.value}
                        onClick={() => {
                          let selectedOptions = JSON.parse(JSON.stringify(this.props.selectedOptions));
                          selectedOptions[option.name] = item.value;
                          this.props.onOptionsSelect(selectedOptions);
                        }}
                      >
                        {item.name}
                      </span>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="tags">
            {this.props.tags.map((tag) => {
              return (
                <Tag
                  closable
                  onClose={() => {
                    const tags = this.props.tags.filter(t => t !== tag);
                    this.props.onTagsChange(tags);
                  }}
                  key={tag}
                >
                  {tag}
                </Tag>
              );
            })}
            {this.state.tagInputVisible ? (
              <Input
                ref={this.inputRef}
                type="text"
                className="tag-input"
                value={this.state.tagInputValue}
                onChange={this.handleInputChange}
                onBlur={this.handleInputConfirm}
                onPressEnter={this.handleInputConfirm}
              />
            ) : (
              <Tag
                className="site-tag-plus"
                onClick={() => {
                  this.setState({
                    tagInputVisible: true,
                  }, () => this.inputRef.current.focus());
                }}
              >
                <PlusOutlined /> New Tag
              </Tag>
            )}
          </div>
        </div>
        <div className="button-wrap">
          <IconButton
            title="Continue"
            size="big"
            onClick={this.props.onContinue}
          />
        </div>
      </div>
    );
  }
}

export default SelectOptions;
