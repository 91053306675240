import React from "react";
import {Col, Row, Select, Tooltip} from 'antd';

import 'antd/dist/antd.css'
import './FeedHeader.css';

class FeedHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      team: null,
      availableStatuses: [
        {
          status: 'to_do',
          label: 'To do'
        },
        {
          status: 'in_progress',
          label: 'In progress'
        },
        {
          status: 'done',
          label: 'Done'
        },
      ],
    };
  }

  componentDidMount() {
    this.loadTeam();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.teamHash !== this.props.teamHash || prevProps.user !== this.props.user) {
      this.loadTeam();
    }
  }

  loadTeam = () => {
    if (!this.props.teamHash || !this.props.user) {
      return null;
    }
    this.props.axiosInstance.get('/api/team/' + this.props.teamHash)
      .then((response) => {
        this.setState({
          team: response.data,
        });
      }).catch(error => console.log(error));
  }

  onSelectStatus = (status) => {
    let statuses = this.props.statuses;
    if (statuses.includes(status)) {
      statuses = statuses.filter(value => value !== status);
    } else {
      statuses.push(status);
    }
    this.props.onTeamFilterChange({
      assignees: this.props.assignees,
      statuses: statuses,
    });
  }

  onSelectMember = (hash) => {
    let members = this.props.assignees;
    if (members.includes(hash)) {
      members = members.filter(value => value !== hash);
    } else {
      members.push(hash);
    }
    this.props.onTeamFilterChange({
      assignees: members,
      statuses: this.props.statuses,
    });
  }

  renderHeader = () => {
    return <h2>Tactics {this.props.tacticsCount}</h2>;
  }

  renderOrdering = () => {
    const { Option } = Select;

    return (
      <Col flex="200px" className="feed-actions-wrap">
        {this.props.feed === 'mainFeed' ? (
          <Select className="order-select" value={this.props.orderBy} onChange={(value) => this.props.onOrderByChange(value)}>
            <Option value="date" className="order-select-option-wrap">
              By date
            </Option>
            <Option value="likes" className="order-select-option-wrap">
              By likes
            </Option>
            <Option value="comments" className="order-select-option-wrap">
              By comments
            </Option>
          </Select>
        ) : null}
        <span className="view-switcher">
          <Tooltip overlayClassName="app-tooltip" mouseEnterDelay={1} title="List View">
            <span className={"view-button view-button-list " + (this.props.viewMode === 'list' ? 'selected' : '')} onClick={() => {this.props.setViewMode('list')}}>
            <svg width="14" height="12" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.75 0.3125H0.875C0.382812 0.3125 0 0.695312 0 1.1875V2.0625C0 2.55469 0.382812 2.9375 0.875 2.9375H1.75C2.24219 2.9375 2.625 2.55469 2.625 2.0625V1.1875C2.625 0.695312 2.24219 0.3125 1.75 0.3125ZM1.75 2.0625H0.875V1.1875H1.75V2.0625ZM1.75 9.0625H0.875C0.382812 9.0625 0 9.44531 0 9.9375V10.8125C0 11.3047 0.382812 11.6875 0.875 11.6875H1.75C2.24219 11.6875 2.625 11.3047 2.625 10.8125V9.9375C2.625 9.44531 2.24219 9.0625 1.75 9.0625ZM1.75 10.8125H0.875V9.9375H1.75V10.8125ZM4.8125 2.0625H13.5625C13.8086 2.0625 14 1.87109 14 1.625C14 1.37891 13.8086 1.1875 13.5625 1.1875H4.8125C4.56641 1.1875 4.375 1.37891 4.375 1.625C4.375 1.87109 4.56641 2.0625 4.8125 2.0625ZM13.5625 5.5625H4.8125C4.56641 5.5625 4.375 5.75391 4.375 6C4.375 6.24609 4.56641 6.4375 4.8125 6.4375H13.5625C13.8086 6.4375 14 6.24609 14 6C14 5.75391 13.8086 5.5625 13.5625 5.5625ZM13.5625 9.9375H4.8125C4.56641 9.9375 4.375 10.1289 4.375 10.375C4.375 10.6211 4.56641 10.8125 4.8125 10.8125H13.5625C13.8086 10.8125 14 10.6211 14 10.375C14 10.1289 13.8086 9.9375 13.5625 9.9375ZM1.75 4.6875H0.875C0.382812 4.6875 0 5.07031 0 5.5625V6.4375C0 6.92969 0.382812 7.3125 0.875 7.3125H1.75C2.24219 7.3125 2.625 6.92969 2.625 6.4375V5.5625C2.625 5.07031 2.24219 4.6875 1.75 4.6875ZM1.75 6.4375H0.875V5.5625H1.75V6.4375Z" fill="#2A6CED"/>
            </svg>
          </span>
          </Tooltip>
          <Tooltip overlayClassName="app-tooltip" mouseEnterDelay={1} title="Table View">
            <span className={"view-button " + (this.props.viewMode === 'table' ? 'selected' : '')} onClick={() => {this.props.setViewMode('table')}}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 0H1.5C0.6875 0 0 0.6875 0 1.5V12.5C0 13.3125 0.6875 14 1.5 14H12.5C13.3125 14 14 13.3125 14 12.5V1.5C14 0.6875 13.3125 0 12.5 0ZM1 1.5C1 1.21875 1.21875 1 1.5 1H6.5V6.5H1V1.5ZM1.5 13C1.21875 13 1 12.7812 1 12.5V7.5H6.5V13H1.5ZM13 12.5C13 12.7812 12.7812 13 12.5 13H7.5V7.5H13V12.5ZM13 6.5H7.5V1H12.5C12.7812 1 13 1.21875 13 1.5V6.5Z" fill="#828282"/>
              </svg>
            </span>
          </Tooltip>
        </span>
      </Col>
    );
  }

  render() {
    return (
      <div className={"feed-header " + this.props.feed}>
        {(this.props.feed !== 'teamFeed') ? (
          <Row>
            <Col flex="auto">
              {this.renderHeader()}
            </Col>
            {this.renderOrdering()}
          </Row>
        ) : null}
        {this.props.feed === 'teamFeed' ? (
          <>
            <Row>
              <Col flex="auto">
                {this.renderHeader()}
              </Col>
            </Row>
            <Row>
              <Col flex="auto">
                {this.state.team ? (
                  <div className="member-select">
                    {this.state.team.members
                      .filter((member) => {
                        return member.status === 'active';
                      })
                      .map((member) => {
                        return (
                          <Tooltip key={member.user_hash} overlayClassName="app-tooltip" mouseEnterDelay={1} title={member.name}>
                            <span
                              className={"member " + (this.props.assignees.includes(member.user_hash) ? 'selected' : '')}
                              onClick={() => this.onSelectMember(member.user_hash)}
                            >
                              <div
                                className="member-avatar"
                                style={member.avatar ? { backgroundImage: 'url("' + member.avatar + '")' } : {}}
                              />
                            </span>
                          </Tooltip>
                        );
                    })}
                  </div>
                ) : null}
                <div className="status-select">
                  {this.state.availableStatuses.map((option) => {
                    return (
                      <span
                        key={option.status}
                        className={"status-select-option " + (this.props.statuses.includes(option.status) ? 'selected' : '')}
                        onClick={() => this.onSelectStatus(option.status)}
                      >
                        {option.label}
                      </span>
                    );
                  })}
                </div>
              </Col>
              {this.renderOrdering()}
            </Row>
          </>
        ) : null}
      </div>
    );
  }
}

export default FeedHeader;
