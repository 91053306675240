import React from "react";

import './Team.css'
import {Row, Col, Modal, Button, notification} from "antd";
import IconButton from "../../ui/IconButton/IconButton";
import TextInput from "../../ui/Input/TextInput";
import TextareaInput from "../../ui/Input/TextareaInput";
import TeamMembers from "./TeamMembers";

class Team extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      team: null,
      toEdit: false,
      toEditMembers: false,
      toDelete: false,
      toLeave: false,
      inviteEmail: '',
      inviteInProgress: false,
      removeInProgress: false,
      inviteError: null,
    };
  }

  componentDidMount() {
    if (this.props.user) {
      this.loadTeam();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.user && !prevProps.user) {
      this.loadTeam();
    }
  }

  isCorrectInviteEmail = () => {
    return String(this.state.inviteEmail)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  isInviteButtonActive = () => {
    return this.isCorrectInviteEmail() && !this.state.inviteInProgress;
  }

  isEditable = () => {
    return this.state.team.authorHash === this.props.user.hash;
  }

  loadTeam = () => {
    this.props.axiosInstance.get('/api/team/' + this.props.hash)
      .then((response) => {
        this.setState({
          team: response.data
        });
      }).catch(error => console.log(error));
  }

  updateTeam = (params) => {
    console.log(params);
    this.props.axiosInstance.post('/api/team/' + this.props.hash, params)
      .then((response) => {
        this.setState({
          team: response.data,
          toEdit: false,
        });
      }).catch(error => console.log(error));
  }

  onFileInput = (e, field) => {
    const fileList = e.target.files;
    if (fileList.length === 0) {
      return;
    }
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (![
        'image/jpeg',
        'image/jpg',
        'image/png',
      ].includes(file.type)) {
        console.log('Wrong file extension'); // @todo Add errors
        continue;
      }
      if (file.size > 5 * 1024 * 1024) {
        console.log('Too big file'); // @todo Add errors
        continue;
      }
      const reader = new FileReader();
      reader.onloadend = () => this.onLoadEnd(reader.result, field);
      reader.readAsDataURL(file);
    }
  }

  onLoadEnd = (imageData, field) => {
    this.props.axiosInstance.post('/api/upload/tactic-file', { imageData })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          return '';
        }
        this.updateTeam({[field]: response.data.url})
        const message = field === 'image'
          ? 'Successfully updated team banner'
          : 'Successfully updated team logo';
        notification.success({ message });
      }).catch(error => console.log(error));
  }

  onEmailEnter = () => {
    if (!this.isCorrectInviteEmail) {
      return;
    }
    this.setState({
      inviteInProgress: true,
    }, () => {
      this.props.axiosInstance.post('/api/team/' + this.props.hash + '/invite', { email: this.state.inviteEmail })
        .then((response) => {
          const inviteEmail = this.state.inviteEmail;
          if (response.data.error) {
            this.setState({
              inviteEmail: '',
              inviteInProgress: false,
              inviteError: response.data.error,
            });
            return '';
          }
          this.setState({
            inviteEmail: '',
            inviteInProgress: false,
            inviteError: null,
          }, () => {
            this.loadTeam();
            notification.success({
              message: 'Invite was successfully sent to ' + inviteEmail
            });
          });
        }).catch(error => console.log(error));
    });
  }

  onRemoveMember = (member) => {
    this.setState({
      removeInProgress: true,
    }, () => {
      this.props.axiosInstance.post('/api/team/' + this.props.hash + '/remove-member', { memberHash: member.hash })
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            return '';
          }
          this.setState({
            removeInProgress: false,
          }, () => {
            const name = member.name;
            const email = member.email;
            const status = member.status;
            this.loadTeam();
            notification.error({
              message: 'Successfully removed '
                + (status === 'invited'
                  ? 'invited member with email ' + email
                  : name)
                + ' from the team ' + this.state.team.name
            });
          });
        }).catch(error => console.log(error));
    });
  }

  onDeleteOk = () => {
    this.props.axiosInstance.post('/api/team/' + this.props.hash + '/delete')
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          return '';
        }
        this.setState({
          toDelete: false,
          toEdit: false,
        }, () => {
          this.props.navigate('/teams');
        });
      }).catch(error => console.log(error));
  }

  onLeaveOk = () => {
    this.props.axiosInstance.post('/api/team/' + this.props.hash + '/leave')
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          return '';
        }
        this.setState({
          toLeave: false,
        }, () => {
          this.props.navigate('/teams');
        });
      }).catch(error => console.log(error));
  }

  render() {
    if (!this.state.team) {
      return null;
    }
    return (
      <div className="team-wrap">
        <div className="team-image">
          <label htmlFor="image_upload">
            {this.state.team.image ? (
              <img src={this.state.team.image}/>
            ) : (
              <div className="label">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M54.375 3.75H5.625C2.57812 3.75 0 6.32812 0 9.375V50.625C0 53.6719 2.57812 56.25 5.625 56.25H54.375C57.4219 56.25 60 53.6719 60 50.625V9.375C60 6.32812 57.4219 3.75 54.375 3.75ZM13.125 11.25C16.1719 11.25 18.75 13.8281 18.75 16.875C18.75 19.9219 16.1719 22.5 13.125 22.5C10.0781 22.5 7.5 19.9219 7.5 16.875C7.5 13.8281 10.0781 11.25 13.125 11.25ZM52.2656 47.8125C51.9141 48.3984 51.3281 48.75 50.625 48.75H9.60938C8.90625 48.75 8.20312 48.3984 7.96875 47.6953C7.61719 47.1094 7.73438 46.2891 8.08594 45.8203L16.2891 34.5703C16.6406 33.9844 17.2266 33.75 17.8125 33.75C18.3984 33.75 18.9844 33.9844 19.3359 34.5703L23.0859 39.7266L34.1016 23.3203C34.4531 22.8516 35.0391 22.5 35.625 22.5C36.2109 22.5 36.7969 22.8516 37.1484 23.3203L52.1484 45.8203C52.6172 46.4062 52.6172 47.1094 52.2656 47.8125Z" fill="#AAC4F8"/>
                  <path d="M52.265 47.8125C51.9134 48.3984 51.3275 48.75 50.6244 48.75H9.60876C8.90563 48.75 8.20251 48.3984 7.96813 47.6953C7.61657 47.1094 7.73376 46.2891 8.08532 45.8203L16.2884 34.5703C16.64 33.9844 17.2259 33.75 17.8119 33.75C18.3978 33.75 18.9838 33.9844 19.3353 34.5703L23.0853 39.7266L34.1009 23.3203C34.4525 22.8516 35.0384 22.5 35.6244 22.5C36.2103 22.5 36.7963 22.8516 37.1478 23.3203L52.1478 45.8203C52.6166 46.4062 52.6166 47.1094 52.265 47.8125Z" fill="#AAC4F8"/>
                </svg>
                <span className="label-text">
                <div className="bold">File browse ...</div>
                <div className="regular">Allowed file formats  .JPG, .PNG</div>
              </span>
              </div>
            )}
            {this.isEditable() ? (
              <input
                onChange={(e) => this.onFileInput(e, 'image')}
                style={{display: 'none'}}
                id="image_upload"
                type="file"
                accept=".jpg, .jpeg, .png"
              />
            ) : null}
          </label>
        </div>
        <div className="team-name-wrap">
          <Row>
            <Col flex="80px">
              <label
                className="team-icon"
                htmlFor="icon_upload"
                style={this.state.team.icon ? { backgroundImage: 'url("' + this.state.team.icon + '")' } : {}}
              >
                {this.state.team.icon ? null : (
                  <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.7422 11.875H6.75781C3.04688 11.875 0 14.9219 0 18.6328C0 19.375 0.625 20 1.36719 20H16.1328C16.875 20 17.5 19.375 17.5 18.6328C17.5 14.9219 14.4531 11.875 10.7422 11.875ZM16.1328 18.75H1.36719C1.28906 18.75 1.25 18.7109 1.25 18.6328C1.25 15.5859 3.71094 13.125 6.75781 13.125H10.7422C13.7891 13.125 16.25 15.5859 16.25 18.6328C16.25 18.7109 16.2109 18.75 16.1328 18.75ZM8.75 10C11.5234 10 13.75 7.77344 13.75 5C13.75 2.22656 11.5234 0 8.75 0C5.97656 0 3.75 2.22656 3.75 5C3.75 7.77344 5.97656 10 8.75 10ZM8.75 1.25C10.8203 1.25 12.5 2.92969 12.5 5C12.5 7.07031 10.8203 8.75 8.75 8.75C6.67969 8.75 5 7.07031 5 5C5 2.92969 6.67969 1.25 8.75 1.25ZM14.6875 9.41406C15.3516 9.80469 16.0938 10 16.875 10C19.2969 10 21.25 8.04688 21.25 5.625C21.25 3.20312 19.2969 1.25 16.875 1.25C16.3672 1.25 15.8984 1.32812 15.4297 1.48438C15.0781 1.60156 14.9219 1.95312 15.0391 2.30469C15.1562 2.61719 15.5078 2.77344 15.8203 2.69531C16.1719 2.57812 16.5234 2.5 16.875 2.5C18.5938 2.5 20 3.90625 20 5.625C20 7.34375 18.5938 8.75 16.875 8.75C16.3281 8.75 15.7812 8.59375 15.3125 8.32031C15 8.16406 14.6484 8.24219 14.4531 8.55469C14.2969 8.86719 14.375 9.25781 14.6875 9.41406ZM19.1797 12.5H17.5C17.1484 12.5 16.875 12.7734 16.875 13.125C16.875 13.4766 17.1484 13.75 17.5 13.75H19.1797C21.6797 13.75 23.75 15.8203 23.75 18.3203C23.75 18.5547 23.5547 18.75 23.3203 18.75H19.375C19.0234 18.75 18.75 19.0234 18.75 19.375C18.75 19.7266 19.0234 20 19.375 20H23.3203C24.2578 20 25 19.2578 25 18.3203C25 15.1172 22.3828 12.5 19.1797 12.5Z" fill="#828282"/>
                  </svg>
                )}
                {this.isEditable() ? (
                  <input
                    onChange={(e) => this.onFileInput(e, 'icon')}
                    style={{display: 'none'}}
                    id="icon_upload"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                  />
                ) : null}
              </label>
            </Col>
            <Col flex="auto">
              <h1>{this.state.team.name}</h1>
              {this.isEditable() ? (
                <span onClick={() => this.setState({
                  toEdit: true,
                  editTeamName: this.state.team.name,
                  editTeamDescription: this.state.team.description,
                })}>Edit</span>
              ) : null }
              <br/>
              <p className={!this.state.team.description ? 'no-data' : ''}>
                {this.state.team.description || 'No command description'}
              </p>
            </Col>
          </Row>

          {this.state.toEdit ? <Modal
            title={'Edit team'}
            visible={this.state.toEdit}
            className={'app-modal app-modal-team'}
            onCancel={() => {
              this.setState({
                editTeamName: this.state.team.name,
                editTeamDescription: this.state.team.description,
                toEdit: false,
              })
            }}
            footer={[
              <IconButton
                title="Save"
                size="big"
                onClick={() => {
                  if (!this.state.editTeamName) {
                    return;
                  }
                  this.updateTeam({
                    name: this.state.editTeamName,
                    description: this.state.editTeamDescription,
                  });
                  if (this.state.editTeamDescription !== this.state.team.description) {
                    notification.success({
                      message: 'Successfully updated team description',
                    });
                  }
                  if (this.state.editTeamName !== this.state.team.name) {
                    notification.success({
                      message: 'Successfully updated team name'
                    });
                  }
                }}
                cantPress={!this.state.editTeamName}
                className={this.state.editTeamName ? '' : 'disabled'}
              />,
              <span onClick={() => this.setState({toDelete: true})} className="danger-link right">Delete team</span>
            ]}
          >
            <TextInput
              value={this.state.editTeamName}
              label="Team Name"
              onChange={(value) => {
                if (value.length > 30) {
                  value = value.substr(0, 30);
                }
                this.setState({
                  editTeamName: value
                });
              }}
            />
            <TextareaInput
              value={this.state.editTeamDescription}
              label="Description"
              onChange={(value) => {
                this.setState({
                  editTeamDescription: value
                });
              }}
            />
          </Modal> : null}
          <Modal
            title={'Delete Team'}
            visible={this.state.toDelete}
            className={'app-modal'}
            onOk={this.onDeleteOk}
            onCancel={() => this.setState({toDelete: false})}
            closeIcon={(
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
              </svg>
            )}
            footer={[
              <IconButton title="Delete" size="big" className="danger" onClick={this.onDeleteOk}/>
            ]}
          >
            {this.state.toDelete ? (
              <p>You are about to disband <b>{this.state.team.name}</b>. This will permanently delete your team and all related tactics and cannot be undone. Do you wish to proceed?</p>
            ) : null}
          </Modal>
          <Modal
              title={'Leave Team'}
              visible={this.state.toLeave}
              className={'app-modal'}
              onOk={this.onLeaveOk}
              onCancel={() => this.setState({toLeave: false})}
              closeIcon={(
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
                </svg>
              )}
              footer={[
                <IconButton title="Leave" size="big" className="danger" onClick={this.onLeaveOk}/>
              ]}
          >
            {this.state.toLeave ? (
                <p>You are about to leave <b>{this.state.team.name}</b>. Do you wish to proceed?</p>
            ) : null}
          </Modal>
        </div>
        <div className="team-data-wrap">
          <Row>
            <Col span={24}>
              <h2>Members
                {this.isEditable() ? (
                  <span
                    onClick={() => this.setState({
                      toEditMembers: true,
                      inviteEmail: '',
                    })}
                  >
                    Edit
                  </span>
                ) : (
                  <span
                      onClick={() => this.setState({
                        toLeave: true,
                      })}
                  >
                    Leave
                  </span>
                )}
              </h2>
              <TeamMembers
                members={this.state.team.members || []}
              />
            </Col>
          </Row>
          {this.state.toEditMembers ? <Modal
            title={'Members'}
            width={900}
            visible={this.state.toEditMembers}
            className={'app-modal app-modal-team-members'}
            onCancel={() => {
              this.setState({
                inviteEmail: '',
                toEditMembers: false,
              })
            }}
            footer={[
              <IconButton
                title="Save"
                size="big"
                onClick={() => {
                  this.setState({
                    inviteEmail: '',
                    toEditMembers: false
                  });
                }}
              />
            ]}
          >
            <Row>
              <Col flex="auto">
                <TextInput
                  value={this.state.inviteEmail}
                  error={this.state.inviteError}
                  label="Email for invite"
                  onChange={(value) => {
                    this.setState({
                      inviteEmail: value
                    });
                  }}
                />
              </Col>
              <Col flex="130px">
                <div className="invite-button-wrap">
                  <IconButton
                    title="Send Invite"
                    size="big"
                    onClick={this.onEmailEnter}
                    cantPress={!this.isInviteButtonActive()}
                    className={this.isInviteButtonActive() ? '' : 'disabled'}
                  />
                </div>
              </Col>
            </Row>
            <div className="team-members-wrap">
              <TeamMembers
                members={this.state.team.members || []}
                mode="edit"
                removeInProgress={this.removeInProgress}
                onRemoveMember={this.onRemoveMember}
              />
            </div>
          </Modal> : null}
        </div>
      </div>
    );
  }
}

export default Team
