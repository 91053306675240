import React from "react";

import './IconPlus.css';

class IconPlus extends React.Component {
  render() {
    return (
      <span className="app-icon app-icon-plus">
        <svg className="app-icon-plus-1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M9 0C4.0125 0 0 4.0125 0 9C0 13.9875 4.0125 18 9 18C13.9875 18 18 13.9875 18 9C18 4.0125 13.9875 0 9 0ZM12.6 9.9H9.9V12.6C9.9 13.0875 9.4875 13.5 9 13.5C8.5125 13.5 8.1 13.0875 8.1 12.6V9.9H5.4C4.9125 9.9 4.5 9.4875 4.5 9C4.5 8.5125 4.9125 8.1 5.4 8.1H8.1V5.4C8.1 4.9125 8.5125 4.5 9 4.5C9.4875 4.5 9.9 4.9125 9.9 5.4V8.1H12.6C13.0875 8.1 13.5 8.5125 13.5 9C13.5 9.4875 13.0875 9.9 12.6 9.9Z" fill="white"/>
        </svg>
        <svg className="app-icon-plus-2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.6 5.9H5.9V8.6C5.9 9.0875 5.4875 9.5 5 9.5C4.5125 9.5 4.1 9.0875 4.1 8.6V5.9H1.4C0.9125 5.9 0.5 5.4875 0.5 5C0.5 4.5125 0.9125 4.1 1.4 4.1H4.1V1.4C4.1 0.9125 4.5125 0.5 5 0.5C5.4875 0.5 5.9 0.9125 5.9 1.4V4.1H8.6C9.0875 4.1 9.5 4.5125 9.5 5C9.5 5.4875 9.0875 5.9 8.6 5.9Z" fill="white"/>
        </svg>
      </span>
    );
  }
}

export default IconPlus;
