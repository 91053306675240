import React from "react";

import './Logo.css';

class Logo extends React.Component {
  render() {

    return (
      <div className="app-logo">
        {this.props.size === 'big' ? (
          <svg width="180" height="54" viewBox="0 0 180 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.0852 1.88733C33.3672 1.88733 35.5788 2.19075 37.6795 2.76045L35.2845 7.5865C33.9313 7.29582 32.5258 7.14198 31.0852 7.14198C25.6308 7.14198 20.6925 9.34124 17.1181 12.8974C16.248 13.7631 15.4589 14.7094 14.7625 15.7236L9.96182 13.4225C10.9461 11.8863 12.0945 10.4637 13.3837 9.18104C17.9136 4.67432 24.172 1.88733 31.0852 1.88733ZM11.4131 25.0214C11.3609 25.6038 11.3331 26.1948 11.3331 26.792C11.3331 32.2186 13.5448 37.1316 17.1181 40.6867C17.4252 40.9922 17.743 41.2872 18.0693 41.5726L15.6668 46.4135C14.8649 45.7896 14.1025 45.117 13.3837 44.402C8.85388 39.8953 6.05151 33.6688 6.05151 26.792C6.05151 25.3704 6.17201 23.9764 6.40234 22.6195L11.4131 25.0214ZM26.8848 45.9965C28.238 46.2883 29.6435 46.4421 31.0852 46.4421C35.7931 46.4421 40.1161 44.803 43.5092 42.068H50.8564C50.2144 42.8881 49.5234 43.6678 48.7855 44.402C44.2557 48.9087 37.9973 51.6968 31.0852 51.6968C28.8021 51.6968 26.5905 51.3923 24.4898 50.8236L26.8848 45.9965ZM46.5025 7.17063C47.3044 7.79444 48.0668 8.46599 48.7855 9.18104C50.2272 10.6164 51.493 12.2248 52.5508 13.9731H46.0546C45.7336 13.6029 45.3988 13.2432 45.0512 12.8974C44.7441 12.5918 44.4263 12.2959 44.1 12.0115L46.5025 7.17063Z" fill="#417BEF"/>
            <path d="M27.8328 28.2125L23.1825 18.6591H17.9041L27.8328 37.4148L37.7627 18.6591H32.4832L27.8328 28.2125Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M92.6201 24.9259V20.2398C90.9651 19.1482 88.9764 18.7015 87.0079 18.7015C81.4052 18.7015 77.1217 22.1378 77.1217 27.9123C77.1217 33.6857 81.4052 37.122 87.0079 37.122C88.9764 37.122 90.9651 36.6754 92.6201 35.5837V30.8977C91.2136 32.3501 88.9955 33.1563 87.0079 33.1563C83.8749 33.1563 81.7358 31.0292 81.7358 27.9123C81.7358 24.7943 83.8749 22.6672 87.0079 22.6672C88.9955 22.6672 91.2136 23.4735 92.6201 24.9259Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M98.7921 18.8628V37.122H103.614V29.718H111.51V37.122H116.333V18.8628H111.51V25.5932H103.614V18.8628H98.7921Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M41.5866 0.0159912L34.6606 13.9732H39.8783L46.8053 0.0159912H41.5866Z" fill="#242538"/>
            <path d="M130.506 27.8615L125.857 37.415H120.578L130.506 18.6582L140.436 37.415H135.158L130.506 27.8615Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.7322 40.0269L14.8063 53.9841H20.025L26.9509 40.0269H21.7322Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.0961 18.2721L0 11.5162L0.0511848 16.7083L14.1462 23.4631L14.0961 18.2721Z" fill="#242538"/>
            <path d="M43.6521 27.8615L39.0017 37.415H33.7233L43.6521 18.6582L53.5808 37.415H48.3024L43.6521 27.8615Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M144.089 18.8628V37.122H148.369V30.9295H149.82L154.096 37.122H159.47L154.886 30.4808L155.25 30.3545C157.817 29.4591 158.868 27.524 158.868 24.8962C158.868 20.1656 155.423 18.8628 151.235 18.8628H144.089ZM148.369 22.7192H151.235C152.825 22.7192 154.317 22.9462 154.317 24.8962C154.317 26.8164 152.929 27.3437 151.235 27.3437H148.369V22.7192Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M164.619 18.8628V37.122H168.9V30.9295H170.351L174.627 37.122H180L175.417 30.4808L175.78 30.3545C178.347 29.4591 179.399 27.524 179.399 24.8962C179.399 20.1656 175.953 18.8628 171.766 18.8628H164.619ZM168.9 22.7192H171.766C173.356 22.7192 174.847 22.9462 174.847 24.8962C174.847 26.8164 173.46 27.3437 171.766 27.3437H168.9V22.7192Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M57.39 18.8628V37.122H61.6714V30.9295H63.1227L67.3977 37.122H72.7711L68.1889 30.4808L68.5515 30.3545C71.1182 29.4591 72.1696 27.524 72.1696 24.8962C72.1696 20.1656 68.7243 18.8628 64.5367 18.8628H57.39ZM61.6714 22.7192H64.5367C66.1266 22.7192 67.6184 22.9462 67.6184 24.8962C67.6184 26.8164 66.2311 27.3437 64.5367 27.3437H61.6714V22.7192Z" fill="#242538"/>
          </svg>
        ) : (
          <svg width="140" height="42" viewBox="0 0 140 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.1772 1.46777C25.952 1.46777 27.6722 1.70377 29.3061 2.14687L27.4433 5.90047C26.3908 5.67437 25.2977 5.55473 24.1772 5.55473C19.9349 5.55473 16.094 7.26526 13.3139 10.0312C12.6371 10.7045 12.0234 11.4405 11.4818 12.2293L7.74789 10.4396C8.51342 9.24479 9.40666 8.13826 10.4094 7.14066C13.9326 3.63544 18.8003 1.46777 24.1772 1.46777ZM8.87669 19.4609C8.83605 19.9139 8.81448 20.3735 8.81448 20.8381C8.81448 25.0587 10.5346 28.88 13.3139 31.6451C13.5528 31.8827 13.7999 32.1121 14.0537 32.3341L12.1851 36.0992C11.5614 35.614 10.9684 35.0909 10.4094 34.5347C6.88616 31.0295 4.70654 26.1867 4.70654 20.8381C4.70654 19.7324 4.80026 18.6482 4.97941 17.5928L8.87669 19.4609ZM20.9102 35.7749C21.9627 36.0018 23.0558 36.1215 24.1772 36.1215C27.8389 36.1215 31.2012 34.8466 33.8403 32.7194H39.5548C39.0555 33.3572 38.518 33.9637 37.9441 34.5347C34.4209 38.04 29.5532 40.2084 24.1772 40.2084C22.4015 40.2084 20.6813 39.9716 19.0474 39.5293L20.9102 35.7749ZM36.1684 5.57701C36.7921 6.06219 37.3851 6.58451 37.9441 7.14066C39.0654 8.25709 40.0499 9.50801 40.8727 10.8679H35.8201C35.5704 10.5799 35.31 10.3001 35.0396 10.0312C34.8007 9.79351 34.5536 9.56329 34.2998 9.34215L36.1684 5.57701Z" fill="#417BEF"/>
            <path d="M21.6477 21.9427L18.0307 14.5122H13.9253L21.6477 29.1L29.3709 14.5122H25.2646L21.6477 21.9427Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M72.0377 19.3865V15.7419C70.7505 14.8928 69.2037 14.5454 67.6726 14.5454C63.315 14.5454 59.9834 17.2181 59.9834 21.7093C59.9834 26.1998 63.315 28.8724 67.6726 28.8724C69.2037 28.8724 70.7505 28.5251 72.0377 27.676V24.0313C70.9437 25.1609 69.2186 25.788 67.6726 25.788C65.2359 25.788 63.5721 24.1336 63.5721 21.7093C63.5721 19.2842 65.2359 17.6298 67.6726 17.6298C69.2186 17.6298 70.9437 18.2569 72.0377 19.3865Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M76.8384 14.6709V28.8725H80.5888V23.1138H86.7304V28.8725H90.4809V14.6709H86.7304V19.9056H80.5888V14.6709H76.8384Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M32.3453 0.012207L26.9585 10.8678H31.0167L36.4043 0.012207H32.3453Z" fill="#242538"/>
            <path d="M101.505 21.6699L97.889 29.1003H93.7827L101.505 14.5117L109.228 29.1003H105.123L101.505 21.6699Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.903 31.1318L11.5161 41.9875H15.5751L20.962 31.1318H16.903Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.9636 14.2116L0 8.95703L0.0398104 12.9953L11.0026 18.249L10.9636 14.2116Z" fill="#242538"/>
            <path d="M33.9519 21.6699L30.3349 29.1003H26.2295L33.9519 14.5117L41.6743 29.1003H37.5688L33.9519 21.6699Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M112.069 14.6709V28.8725H115.398V24.0561H116.527L119.853 28.8725H124.032L120.467 23.7071L120.75 23.6089C122.746 22.9125 123.564 21.4074 123.564 19.3635C123.564 15.6842 120.884 14.6709 117.627 14.6709H112.069ZM115.398 17.6703H117.627C118.864 17.6703 120.024 17.8469 120.024 19.3635C120.024 20.857 118.944 21.2671 117.627 21.2671H115.398V17.6703Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M128.037 14.6709V28.8725H131.367V24.0561H132.495L135.821 28.8725H140L136.435 23.7071L136.718 23.6089C138.715 22.9125 139.532 21.4074 139.532 19.3635C139.532 15.6842 136.853 14.6709 133.596 14.6709H128.037ZM131.367 17.6703H133.596C134.832 17.6703 135.993 17.8469 135.993 19.3635C135.993 20.857 134.914 21.2671 133.596 21.2671H131.367V17.6703Z" fill="#242538"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M44.6367 14.6709V28.8725H47.9667V24.0561H49.0955L52.4205 28.8725H56.5998L53.0359 23.7071L53.3179 23.6089C55.3142 22.9125 56.132 21.4074 56.132 19.3635C56.132 15.6842 53.4522 14.6709 50.1953 14.6709H44.6367ZM47.9667 17.6703H50.1953C51.4319 17.6703 52.5922 17.8469 52.5922 19.3635C52.5922 20.857 51.5131 21.2671 50.1953 21.2671H47.9667V17.6703Z" fill="#242538"/>
          </svg>
        )}
      </div>
    );
  }
}

export default Logo;
