import React from "react";

import './IconSave.css';

class IconSave extends React.Component {
  render() {
    return (
      <span className="app-icon app-icon-save">
        <svg className="app-icon-save-1" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 8.25C4.1875 8.25 4.375 8.17969 4.53906 8.03906L7.63281 4.92188C7.79688 4.78125 7.79688 4.54688 7.63281 4.38281L7.11719 3.86719C6.95312 3.70312 6.71875 3.70312 6.57812 3.86719L4.75 5.69531V0.375C4.75 0.164063 4.58594 0 4.375 0L3.625 0C3.41406 0 3.25 0.164063 3.25 0.375V5.69531L1.42188 3.86719C1.28125 3.70313 1.04688 3.70313 0.882812 3.86719L0.367188 4.38281C0.203125 4.54688 0.203125 4.78125 0.367188 4.92188L3.46094 8.03906C3.625 8.17969 3.8125 8.25 4 8.25Z" fill="white"/>
        </svg>
        <svg className="app-icon-save-2" width="12" height="5" viewBox="0 0 12 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M11.25 2.75V0.875C11.25 0.664062 11.0859 0.5 10.875 0.5L10.125 0.5C9.91406 0.5 9.75 0.664063 9.75 0.875V2.75C9.75 3.17188 9.42188 3.5 9 3.5L3 3.5C2.57812 3.5 2.25 3.17188 2.25 2.75V0.875C2.25 0.664063 2.08594 0.5 1.875 0.5L1.125 0.5C0.914062 0.5 0.75 0.664063 0.75 0.875V2.75C0.75 3.99219 1.75781 5 3 5L9 5C10.2422 5 11.25 3.99219 11.25 2.75Z" fill="white"/>
        </svg>
      </span>
    );
  }
}

export default IconSave;
