import React from "react";

import './SelectMap.css';

class SelectMap extends React.Component {

  getMaps = () => {
    return {
      csgo: [
        {
          map: 'ancient',
          name: 'Ancient',
          image: '/img/csgo_maps/map_ancient_mini.jpg',
        },
        {
          map: 'cache',
          name: 'Cache',
          image: '/img/csgo_maps/map_cache_mini.jpg',
        },
        {
          map: 'dust2',
          name: 'Dust 2',
          image: '/img/csgo_maps/map_dust2_mini.jpg',
        },
        {
          map: 'inferno',
          name: 'Inferno',
          image: '/img/csgo_maps/map_inferno_mini.jpg',
        },
        {
          map: 'mirage',
          name: 'Mirage',
          image: '/img/csgo_maps/map_mirage_mini.jpg',
        },
        {
          map: 'nuke',
          name: 'Nuke',
          image: '/img/csgo_maps/map_nuke_mini.jpg',
        },
        {
          map: 'overpass',
          name: 'Overpass',
          image: '/img/csgo_maps/map_overpass_mini.jpg',
        },
        {
          map: 'train',
          name: 'Train',
          image: '/img/csgo_maps/map_train_mini.jpg',
        },
        {
          map: 'vertigo',
          name: 'Vertigo',
          image: '/img/csgo_maps/map_vertigo_mini.jpg',
        },
      ],
    }
  }

  render() {
    return (
      <div className="editor-select-map-wrap">
        <h1>Select map</h1>
        <div className="maps">
          {this.getMaps()[this.props.game].map((map) => {
            return (
              <div
                className={"map " + (this.props.map === map.map ? 'selected' : '')}
                style={{ backgroundImage: 'url("' + map.image + '")' }}
                key={map.map}
                onClick={() => this.props.onMapSelect(map.map)}
              >
                <div className="overlay" />
                <span className="name">{map.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default SelectMap;
