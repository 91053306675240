import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {Button, Dropdown, Menu, Modal, Tooltip, notification} from 'antd';

import 'antd/dist/antd.css'
import './AppHeader.css';
import IconButton from "../../ui/IconButton/IconButton";

export default function AppHeader (props)  {

  const games = [
    {
      key: 1,
      enabled: true,
      alias: 'csgo',
      name: 'CS:GO',
      icon: '/img/csgo_icon.svg',
    },
    {
      key: 2,
      enabled: false,
      alias: 'dota2',
      name: 'Dota 2',
      icon: '/img/game_small_logo/dota-logo.svg',
    },
    {
      key: 3,
      enabled: false,
      alias: 'lol',
      name: 'League of Legends',
      icon: '/img/game_small_logo/league-of-legends-logo.svg',
    },
    {
      key: 4,
      enabled: false,
      alias: 'pubg',
      name: 'PUBG',
      icon: '/img/game_small_logo/pubg-logo.svg',
    },
    {
      key: 5,
      enabled: false,
      alias: 'starcraft2',
      name: 'StarCraft II',
      icon: '/img/game_small_logo/starcraft-logo.svg',
    },
    {
      key: 6,
      enabled: false,
      alias: 'valorant',
      name: 'Valorant',
      icon: '/img/game_small_logo/valorant-logo.svg',
    },
  ];

  const [toUnsubscribe, setToUnsubscribe] = useState(false);

  let navigate = useNavigate();

  const renderItem = (game) => {
    return (
      <span className="game-select-option">
        <span className="game-select-option-icon">
          <img src={game.icon}/>
        </span>
        <span className="game-select-option-name">{game.name}</span>
      </span>
    );
  }

  const renderCurrentItem = () => {
    return renderItem(games.find((game) => game.alias === props.game));
  }

  const renderTeam = (team) => {
    return (
      <span className="game-select-option">
        <span className="game-select-option-icon">
          <div
            className="team-icon"
            style={team.icon ? { backgroundImage: 'url("' + team.icon + '")' } : {}}
          >
            {team.icon ? null : (
              <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25ZM5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM8.8125 5.64844C9.21094 5.88281 9.65625 6 10.125 6C11.5781 6 12.75 4.82812 12.75 3.375C12.75 1.92188 11.5781 0.75 10.125 0.75C9.82031 0.75 9.53906 0.796875 9.25781 0.890625C9.04688 0.960938 8.95312 1.17188 9.02344 1.38281C9.09375 1.57031 9.30469 1.66406 9.49219 1.61719C9.70312 1.54688 9.91406 1.5 10.125 1.5C11.1562 1.5 12 2.34375 12 3.375C12 4.40625 11.1562 5.25 10.125 5.25C9.79688 5.25 9.46875 5.15625 9.1875 4.99219C9 4.89844 8.78906 4.94531 8.67188 5.13281C8.57812 5.32031 8.625 5.55469 8.8125 5.64844ZM11.5078 7.5H10.5C10.2891 7.5 10.125 7.66406 10.125 7.875C10.125 8.08594 10.2891 8.25 10.5 8.25H11.5078C13.0078 8.25 14.25 9.49219 14.25 10.9922C14.25 11.1328 14.1328 11.25 13.9922 11.25H11.625C11.4141 11.25 11.25 11.4141 11.25 11.625C11.25 11.8359 11.4141 12 11.625 12H13.9922C14.5547 12 15 11.5547 15 10.9922C15 9.07031 13.4297 7.5 11.5078 7.5Z" fill="#828282"/>
              </svg>
            )}
          </div>
        </span>
        <span className="game-select-option-name">{team.name}</span>
      </span>
    );
  }

  const renderArrow = () => {
    return (
      <svg className="arrow" width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.147541 1.47926L3.68671 4.87097C3.77888 4.96313 3.88948 5 4.00007 5C4.11067 5 4.22127 4.96313 4.31344 4.87097L7.85261 1.47926C8.01851 1.31336 8.03694 1.03687 7.85261 0.852535C7.68671 0.686636 7.41021 0.686636 7.22588 0.852535L4.00007 3.94931L0.774269 0.852535C0.589937 0.686636 0.313439 0.686636 0.147541 0.852535C-0.0367913 1.03687 -0.0183581 1.31336 0.147541 1.47926Z" fill="#333333"/>
      </svg>
    );
  }

  const renderSubscribeButton = () => {
    if (props.user && props.user.hash === props.author.hash) {
      return;
    }
    const isSubscribed = props.user && props.user.subscribed_to.filter((subscribedToUser) => {
      return subscribedToUser.hash === props.author.hash;
    }).length > 0;

    return (
      <span className={"subscribe-button-wrap " + (isSubscribed ? 'subscribed' : 'not-subscribed')}>
        <IconButton
          title={isSubscribed ? "Subscribed" : "Subscribe"}
          size="middle"
          cantPress={!props.user}
          onClick={() => {
            if (!props.user) {
              return;
            }
            if (!isSubscribed) {
              props.onSubscribe();
              notification.success({
                message: 'Successfully subscribed to ' + props.author.name,
              });
            } else {
              setToUnsubscribe(true)
            }
          }}
        />
      </span>
    );
  }

  const feedName = () => {
    return {
      mainFeed: 'Main Feed',
      teamFeed: 'Team Feed',
      saved: 'Saved',
      liked: 'Liked',
      subscriptions: 'Subscriptions',
      author: props.author ? props.author.name : props.authorParam,
    }[props.feed] || '';
  }

  const onModalOk = () => {
    props.onUnsubscribe();
    notification.error({
      message: 'Successfully unsubscribed from ' + props.author.name,
    });
    setToUnsubscribe(false);
  }

  const onModalCancel = () => {
    setToUnsubscribe(false);
  }

  return (
    <div className="content-header">
      <h1>{feedName()}</h1>
      {props.feed !== 'teamFeed' ? (
        <Dropdown
          placement="bottomLeft"
          overlay={(
            <Menu className="app-dropdown app-header-dropdown">
              {games.map((game) => {
                return (
                  <Menu.Item
                    key={game.key}
                    className={"game-select-option-wrap " + (game.alias === props.game ? 'active' : '')}
                    disabled={!game.enabled}
                    onClick={() => {
                      if (game.enabled) {
                        navigate('/' + props.feed + '/' + game.alias)
                      }
                    }}
                  >
                    {game.enabled ? (
                      renderItem(game)
                    ) : (
                      <Tooltip overlayClassName="app-tooltip" mouseEnterDelay={1} title="Coming Soon">
                        {renderItem(game)}
                      </Tooltip>
                    )}
                  </Menu.Item>
                );
              })}
            </Menu>
          )}
          trigger={['click']}
        >
          <span className="app-dropdown-wrap">
            {renderCurrentItem()}
            {renderArrow()}
          </span>
        </Dropdown>
      ) : null}
      {props.feed === 'teamFeed' && props.teams.length > 0 ? (
        <Dropdown
          placement="bottomLeft"
          overlay={(
            <Menu className="app-dropdown app-header-dropdown">
              {props.teams.map((team) => {
                return (
                  <Menu.Item
                    key={team.hash}
                    className={"game-select-option-wrap " + (team.hash === props.teamHash ? 'active' : '')}
                    onClick={() => {
                      navigate('/teamFeed/' + team.hash)
                    }}
                  >
                    {renderTeam(team)}
                  </Menu.Item>
                );
              })}
            </Menu>
          )}
          trigger={['click']}
        >
          <span className="app-dropdown-wrap">
            {renderTeam(props.teams.filter(team => team.hash === props.teamHash)[0])}
            {renderArrow()}
          </span>
        </Dropdown>
      ) : null}
      {props.feed === 'author' && props.author ? (
        renderSubscribeButton()
      ) : null}
      {props.feed === 'author' && props.author && props.user ? (
        <Modal
          title={'Unsubscribe'}
          visible={toUnsubscribe}
          className={'app-modal'}
          onOk={onModalOk}
          onCancel={onModalCancel}
          closeIcon={(
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
            </svg>
          )}
          footer={[
            <IconButton title="Unsubscribe" size="big" className="danger" onClick={onModalOk}/>
          ]}
        >
          <p>Are you sure you want to unsubscribe from {props.author.name}?</p>
        </Modal>
      ) :  null}
    </div>
  );
}
