import React from "react";
import Logo from "../../layout/Logo/Logo";
import SelectGame from "./SelectGame";
import SelectMap from "./SelectMap";
import SelectOptions from "./SelectOptions";
import Editor from "./Editor";
import { Radio, Modal, Space, notification } from 'antd';

import IconButton from "../../ui/IconButton/IconButton";
import IconPublish from "../../ui/Icons/IconPublish";

import './TacticEditor.css';

class TacticEditor extends React.Component {

  constructor(props) {
    super(props);

    let selectedOptions = {};
    if (this.props.tactic && this.props.tactic.game === 'csgo') {
      selectedOptions = {
        tickrate: this.props.tactic.tickrate,
        side: this.props.tactic.side,
        economics: this.props.tactic.economics,
      }
    }

    this.state = {
      step: this.props.tactic ? 'createTactics' : 'selectGame',
      game: this.props.tactic ? this.props.tactic.game : null,
      map: this.props.tactic ? this.props.tactic.map : null,
      options: [],
      tags: this.props.tactic ? this.props.tactic.tags : [],
      selectedOptions: selectedOptions,
      showPublishModal: false,
      isPublishing: false,
      availableTeams: [],
      selectedTeam: null,
      tacticContent: this.props.tactic ? this.props.tactic.content : {
        name: '',
        summary: '',
        blocks: [
          {
            type: 'image',
            value: {
              src: null,
            }
          },
          {
            type: 'move',
            value: {
              name: '',
              summary: '',
              player: null,
              blocks: [
                {
                  type: 'image',
                  value: {
                    src: null,
                  }
                },
              ]
            },
          },
        ],
      }
    }
  }

  close = (reload = false) => {
    this.props.onClose(reload);
  }

  componentDidMount() {
    if (this.props.tactic) {
      this.loadOptions(false);
    }
    this.props.axiosInstance.get('/api/teams')
      .then((response) => {
        this.setState({
          availableTeams: response.data.teams
        });
      }).catch(error => console.log(error));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step !== this.state.step && this.state.step === 'selectMap' && !this.props.tactic) {
      this.loadOptions();
    }
  }

  loadOptions = (clearSelected = true) => {
    this.props.axiosInstance.get('/api/editor-options/' + this.state.game).then((response) => {
      let selectedOptions = {};
      response.data.forEach((option) => {
        selectedOptions[option.name] = option.options[0].value;
      });
      if (clearSelected) {
        this.setState({
          selectedOptions,
          options: response.data
        })
      } else {
        this.setState({
          options: response.data
        })
      }
    }).catch(error => console.log(error));
  }

  onTacticContentChange = (tacticContent) => {
    this.setState({
      tacticContent: JSON.parse(JSON.stringify(tacticContent))
    })
  }

  publish = () => {
    this.setState({
      isPublishing: true,
    }, () => {
      this.props.axiosInstance.post('/api/tactics/' + this.state.game + '/publish', {
        map: this.state.map,
        options: this.state.selectedOptions,
        tags: this.state.tags,
        teamHash: this.state.selectedTeam,
        tacticContent: this.state.tacticContent,
      })
        .then((response) => {
          let message = 'Tactic ' + this.state.tacticContent.name +' was published to the Main Feed';
          let url = '/mainFeed/' + this.state.game + '/' + response.data.tactic.alias;
          if (this.state.selectedTeam) {
            const team = this.state.availableTeams.find((team) => team.hash === this.state.selectedTeam);
            message = 'Tactic ' + this.state.tacticContent.name +' was published to the Team Feed ' + team.name;
            url = '/teamFeed/' + team.hash + '/' + response.data.tactic.alias;
          }
          notification.success({
            message: message
          });
          this.close(true);
          this.props.navigate(url);
        }).catch(error => console.log(error));
    });
  }

  save = () => {
    this.setState({
      isPublishing: true,
    }, () => {
      this.props.axiosInstance.post('/api/tactics/' + this.state.game + '/update/' + this.props.tactic.hash, {
        map: this.state.map,
        options: this.state.selectedOptions,
        tags: this.state.tags,
        tacticContent: this.state.tacticContent,
      })
        .then((response) => {
          notification.success({
            message: 'Tactic ' + this.state.tacticContent.name +' was updated'
          });
          this.close(true);
        }).catch(error => console.log(error));
    });
  }

  render() {

    return (
      <div className="tactic-editor-wrap">
        <div className="tactic-editor-header">
          <button onClick={this.close} role="button" className="close">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.607563 0L0.0224609 0.614357L7.05641 8L0.0224609 15.3856L0.607563 16L7.64151 8.61436L14.6755 16L15.2606 15.3856L8.22661 8L15.2606 0.614357L14.6755 0L7.64151 7.38564L0.607563 0Z" fill="#828282"/>
            </svg>
          </button>
          <span onClick={this.close} className="logo-wrap">
            <Logo />
          </span>
          {this.state.step === 'selectGame' ? (
            <div className="header-name">
              Create Tactic
            </div>
          ) : (
            <span className="selected-game">
              {this.state.game === 'csgo' ? (
                <>
                  <img src="/img/csgo_icon.svg"/>
                  <span className="game">
                    CS:GO
                  </span>
                </>
              ) : null}
            </span>
          )}
          {this.state.step !== 'selectGame' ? (
            <span className="steps-wrap">
              <span
                className={"step-item " + (['selectOptions', 'createTactics'].includes(this.state.step) ? 'done ' : ' ') + (this.state.step === 'selectMap' ? 'current' : '')}
                onClick={() => {
                  this.setState({
                    step: 'selectMap',
                  })
                }}
              >
                <span>1</span> {this.props.tactic ? 'Change' : 'Select'} Map
              </span>
              <span
                className={"step-item " + (['createTactics'].includes(this.state.step) ? 'done ' : ' ') + (this.state.step === 'selectOptions' ? 'current' : '')}
                onClick={() => {
                  this.setState({
                    step: 'selectOptions',
                  })
                }}
              >
                <span>2</span> {this.props.tactic ? 'Change' : 'Select'} Options
              </span>
              <span className={"step-item " + (this.state.step === 'createTactics' ? 'current' : '')}>
                <span>3</span> {this.props.tactic ? 'Edit' : 'Create'} Tactics
              </span>
            </span>
          ) : null}
          {this.state.step !== 'selectGame' ? (
            <div className="publish-button-wrap">
              <IconButton
                title={this.props.tactic ? 'Update' : 'Publish'}
                size="big"
                iconBehind={<IconPublish/>}
                cantPress={!this.state.tacticContent.name}
                className={(this.props.user && this.state.tacticContent.name) ? '' : 'disabled'}
                onClick={() => {
                  if (this.props.user && this.state.tacticContent.name) {
                    if (this.props.tactic) {
                      this.save();
                    } else {
                      this.setState({
                        showPublishModal: true,
                      });
                    }
                  }
                }}
              />
              <Modal
                title={'Tactic Publication'}
                visible={this.state.showPublishModal}
                className={'app-modal'}
                onCancel={() => {
                  this.setState({
                    showPublishModal: false,
                  });
                }}
                closeIcon={(
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
                  </svg>
                )}
                footer={[
                  !this.state.isPublishing ? (
                    <IconButton
                      title="Publish"
                      size="big"
                      onClick={this.publish}
                    />
                  ) : null
                ]}
              >
                <p>Сhoose a place to publish</p>
                <Radio.Group
                  className="app-radio-group"
                  onChange={(e) => {
                    this.setState({
                      selectedTeam: e.target.value
                    })
                  }}
                  value={this.state.selectedTeam}
                >
                  <Space direction="vertical">
                    <Radio value={null}>Main Feed</Radio>
                    {this.state.availableTeams
                      .filter((team) => team.game === this.state.game)
                      .map((team) => {
                        return (
                          <Radio
                            value={team.hash}
                            key={team.hash}
                          >
                            Team Feed <b>{team.name}</b>
                          </Radio>
                        );
                    })}
                  </Space>
                </Radio.Group>
              </Modal>
            </div>
          ) : null}
        </div>
        <div className="tactic-editor-content">
          {this.state.step === 'selectGame' ? (
            <SelectGame
              game={this.state.game}
              onGameSelect={(game) => {
                this.setState({
                  game,
                  step: 'selectMap',
                });
              }}
            />
          ) : null}
          {this.state.step === 'selectMap' ? (
            <SelectMap
              game={this.state.game}
              map={this.state.map}
              onMapSelect={(map) => {
                this.setState({
                  map,
                  step: 'selectOptions',
                });
              }}
            />
          ) : null}
          {this.state.step === 'selectOptions' ? (
            <SelectOptions
              options={this.state.options}
              selectedOptions={this.state.selectedOptions}
              onOptionsSelect={(selectedOptions) => {
                this.setState({
                  selectedOptions,
                });
              }}
              tags={this.state.tags}
              onTagsChange={(tags) => {
                this.setState({
                  tags,
                });
              }}
              onContinue={() => {
                this.setState({
                  step: 'createTactics',
                });
              }}
            />
          ) : null}
          {this.state.step === 'createTactics' ? (
            <Editor
              tacticContent={this.state.tacticContent}
              onTacticContentChange={this.onTacticContentChange}
              axiosInstance={this.props.axiosInstance}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default TacticEditor;
