import React from "react";

import './TeamMembers.css'
import {Row, Col, Avatar, Button, Modal} from "antd";
import IconButton from "../../ui/IconButton/IconButton";

class TeamMembers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      members: JSON.parse(JSON.stringify(this.props.members)),
      memberToRemove: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.members.length !== this.state.members.length) {
      this.setState({
        members: JSON.parse(JSON.stringify(this.props.members))
      });
    }
  }

  getPlanName = (plan) => {
    return {
      premium: 'Premium',
      free: 'Free',
    }[plan] || plan;
  }

  onModalOk = () => {
    this.props.onRemoveMember(this.state.memberToRemove);
    this.setState({ memberToRemove: null });
  }

  onModalCancel = () => {
    this.setState({ memberToRemove: null });
  }

  render() {
    return (
      <div className="team-members-full">
        {this.state.members.map((member, index) => {
          return (
            <Row key={index} className={"team-members-line " + (member.status === 'invited' ? 'invited' : '')}>
              <Col flex="50px" style={{textAlign: 'center'}}>
                <Avatar size={30} src={member.avatar || (
                  <span className="player-icon">
                    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25Z" fill="#828282"/>
                    </svg>
                  </span>
                )}/>
              </Col>
              <Col flex="20%">
                <span className="username">{member.name}</span>
              </Col>
              <Col flex="20%">
                <span className={"user-plan " + member.plan}>
                  {this.getPlanName(member.plan)}
                </span>
              </Col>
              {member.email ? (
                <Col flex="20%">
                  <span className="email">{member.email}</span>
                </Col>
              ) : null }
              {(this.props.mode === 'edit' && !member.is_owner && !this.props.removeInProgress) ? (
                <Col flex="auto">
                  <span className="remove" onClick={() => this.setState({ memberToRemove: member })}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.607807 0L0.0227051 0.614357L7.05665 8L0.0227051 15.3856L0.607807 16L7.64175 8.61436L14.6757 16L15.2608 15.3856L8.22686 8L15.2608 0.614357L14.6757 0L7.64175 7.38564L0.607807 0Z" fill="#828282"/>
                    </svg>
                  </span>
                </Col>
              ) : null}
            </Row>
          );
        })}
        <Modal
          title={'Remove team member?'}
          visible={!!this.state.memberToRemove}
          className={'app-modal'}
          onOk={this.onModalOk}
          onCancel={this.onModalCancel}
          closeIcon={(
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
            </svg>
          )}
          footer={[
            <IconButton title="Remove" size="big" className="danger" onClick={this.onModalOk}/>
          ]}
        >
          {this.state.memberToRemove ? (
            <p>Are you sure you want to remove "{this.state.memberToRemove.name || this.state.memberToRemove.email}"?</p>
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default TeamMembers;
