import React from "react";
import { Link } from "react-router-dom";
import {Avatar, Col, Row, Tooltip, Menu, Dropdown, Modal, Button, Radio, Space, notification} from 'antd';
import moment from "moment";

import IconButton from '../../ui/IconButton/IconButton';
import IconSave from "../../ui/Icons/IconSave";
import IconCheck from "../../ui/Icons/IconCheck";

import 'antd/dist/antd.css'
import './Feed.css';
import env from "react-dotenv";

class Feed extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      tacticToDelete: null,
      tacticToClone: null,
      tacticToSave: null,
      tacticToUnsave: null,
      isCloning: false,
      selectedTeamHash: null,
      availableStatuses: [
        {
          status: 'to_do',
          label: 'To do'
        },
        {
          status: 'in_progress',
          label: 'In progress'
        },
        {
          status: 'done',
          label: 'Done'
        },
      ],
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('feed-wrap');
    if (this.isBottom(wrappedElement)) {
      if (!this.state.scrolled) {
        this.setState({
          scrolled: true
        }, () => {
          console.log('header bottom reached');
          this.props.onScrollEnd();
          setTimeout(() => {
            this.setState({scrolled: false});
          }, 2000);
        });
      }
    }
  }

  isBottom = (el) => {
    if (!el) {
      return false;
    }
    return el.getBoundingClientRect().bottom <= window.innerHeight + 200;
  }

  getParamName = (param, value) => {
    return this.props.filters.find((filter) => {
      return filter.name === param;
    }).options.find((option) => {
      return option.value === value;
    }).name;
  }

  renderLikeButton = (tactic) => {
    // @todo to specific component
    const liked = tactic.liked || false;
    const notLikedIcon = (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.04167 3.73333C8.37083 3.73333 7.72917 4.05417 7.32083 4.4625L7 4.8125L6.67917 4.4625C6.27083 4.05417 5.62917 3.73333 4.95833 3.73333C4.52083 3.73333 4.025 3.87917 3.61667 4.25833C2.59583 5.13333 2.5375 6.7375 3.44167 7.7L6.62083 11.025C6.825 11.2583 7.175 11.2583 7.37917 11.025L10.5583 7.7C11.4625 6.7375 11.4042 5.13333 10.3833 4.25833C9.975 3.87917 9.47917 3.73333 9.04167 3.73333ZM9.8875 7.05833L7 10.0917L4.1125 7.05833C3.61667 6.5625 3.52917 5.54167 4.2 4.95833C4.4625 4.75417 4.725 4.66667 4.9875 4.66667C5.39583 4.66667 5.775 4.87083 6.00833 5.13333L7 6.15417L7.99167 5.13333C8.225 4.87083 8.60417 4.66667 9.0125 4.66667C9.275 4.66667 9.5375 4.75417 9.77083 4.95833C10.4708 5.54167 10.3833 6.5625 9.8875 7.05833ZM7 0C3.12083 0 0 3.12083 0 7C0 10.8792 3.12083 14 7 14C10.8792 14 14 10.8792 14 7C14 3.12083 10.8792 0 7 0ZM7 13.0667C3.64583 13.0667 0.933333 10.3542 0.933333 7C0.933333 3.64583 3.64583 0.933333 7 0.933333C10.3542 0.933333 13.0667 3.64583 13.0667 7C13.0667 10.3542 10.3542 13.0667 7 13.0667Z" fill="#2A6CED"/>
      </svg>
    );
    const likedIcon = (
      <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.61668 4.69979L5.37918 8.02479C5.17502 8.25812 4.82502 8.25812 4.62085 8.02479L1.41252 4.69979C0.479183 3.7373 0.508349 2.13313 1.55835 1.25813C2.49168 0.470636 3.83335 0.616469 4.67918 1.4623L5.00002 1.8123L5.35002 1.4623C6.16668 0.616469 7.53752 0.470636 8.44168 1.25813C9.49168 2.13313 9.55002 3.7373 8.61668 4.69979Z" fill="#D078FF"/>
      </svg>
    );
    return (
      <button
        className={"like-button " + (liked ? 'liked' : 'not-liked')}
        onClick={() => {
          if (this.props.user) {
            if (!liked) {
              this.props.onLike(tactic.hash);
            } else {
              this.props.onUnlike(tactic.hash);
            }
          } else {
            notification.info({
              message: (
                <span>
                  You can vote on tactics to help everyone find the best content with a Varcharr account. By continuing, you agree to our User Agreement and Privacy Policy.
                </span>
              ),
              btn: (
                <Button type="primary" onClick={() => { window.location.href = env.API_URL + '/auth/steam/redirect' }}>
                  Login via Steam
                </Button>
              ),
              duration: 9,
            });
          }
        }}
      >
        <span className="icon-wrap">
          {liked ? likedIcon : notLikedIcon}
        </span>
        {liked ? 'Liked' : 'Like'}
      </button>
    );
  }

  renderSaveButton = (tactic) => {
    // @todo to specific component
    const saved = tactic.saved || false;
    return (
      <span className={"save-button-wrap " + (saved ? 'saved' : 'not-saved')}>
        <IconButton
          title={saved ? "Saved" : "Save Tactic"}
          size="middle"
          icon={saved ? <IconCheck /> : <IconSave />}
          onClick={() => {
            if (!this.props.user) {
              notification.info({
                message: (
                  <span>
                    You can save any tactic to read later or share with your team with a Varcharr account. By continuing, you agree to our User Agreement and Privacy Policy.
                  </span>
                ),
                btn: (
                  <Button type="primary" onClick={() => { window.location.href = env.API_URL + '/auth/steam/redirect' }}>
                    Login via Steam
                  </Button>
                ),
                duration: 9,
              });
              return;
            }
            if (!saved) {
              this.setState({
                tacticToSave: tactic,
              });
            } else {
              this.setState({
                tacticToUnsave: tactic,
              });
            }
          }}
        />
      </span>
    );
  }

  onModalOk = () => {
    let message = 'Tactic ' + this.state.tacticToDelete.name + ' was deleted from the Main Feed';
    if (this.isPersonal()) {
      message = 'Tactic ' + this.state.tacticToDelete.name + ' was deleted from Your Feed';
    }
    if (this.isTeamFeed()) {
      const currentTeam = this.props.teams.find((team) => team.hash === this.props.teamHash);
      message = 'Tactic ' + this.state.tacticToDelete.name + ' was deleted from the Team Feed ' + currentTeam.name;
    }
    this.props.onTacticDelete(this.state.tacticToDelete.hash, message);
    this.setState({ tacticToDelete: null });
  }

  onModalCancel = () => {
    this.setState({ tacticToDelete: null });
  }

  onCloneModalOk = () => {
    if (!this.state.selectedTeamHash) {
      return;
    }

    const selectedTeam = this.getAvailableTeamsToClone(this.state.tacticToClone).find((team) => {
      return team.hash === this.state.selectedTeamHash;
    });
    let message = 'Tactic ' + this.state.tacticToClone.name + ' was cloned to the Team Feed ' + selectedTeam.name;

    this.props.onTacticClone(this.state.tacticToClone.hash, this.state.selectedTeamHash, this.isPersonal(), message);
    this.setState({ tacticToClone: null, selectedTeamHash: null });
  }

  onCloneModalCancel = () => {
    this.setState({ tacticToClone: null });
  }

  onSaveModalOk = () => {
    if (!this.state.selectedTeamHash) {
      return;
    }
    let message = 'Tactic ' + this.state.tacticToSave.name + ' was saved to Your Feed ';
    if (this.state.selectedTeamHash !== 'personal') {
      const selectedTeam = this.getAvailableTeamsToClone(this.state.tacticToSave).find((team) => {
        return team.hash === this.state.selectedTeamHash;
      });
      message = 'Tactic ' + this.state.tacticToSave.name + ' was saved to the Team Feed ' + selectedTeam.name;
    }

    this.props.onTacticSave(this.state.tacticToSave.hash, this.state.selectedTeamHash, message);
    this.setState({ tacticToSave: null, selectedTeamHash: null });
  }

  onSaveModalCancel = () => {
    this.setState({ tacticToSave: null });
  }

  onUnsaveModalOk = () => {
    let message = 'Tactic ' + this.state.tacticToUnsave.name + ' was removed from Your Feed';
    if (!this.state.tacticToUnsave.savedData.isPersonal) {
      message = 'Tactic ' + this.state.tacticToUnsave.name + ' was removed from the Team Feed ' + this.state.tacticToUnsave.savedData.teamName;
    }
    this.props.onTacticUnsave(this.state.tacticToUnsave.hash, message);
    this.setState({ tacticToUnsave: null });
  }

  onUnsaveModalCancel = () => {
    this.setState({ tacticToUnsave: null });
  }

  getTacticUrl = (tactic) => {
    if (this.isTeamFeed()) {
      return '/' + this.props.feed + '/' + this.props.teamHash + '/' + tactic.alias;
    }
    if (this.isAuthorFeed() && this.props.author) {
      return '/' + this.props.feed + '/' + this.props.author.hash + '/' + this.props.game + '/' + tactic.alias;
    }
    return '/' + this.props.feed + '/' + this.props.game + '/' + tactic.alias;
  }

  isTeamFeed = () => {
    return this.props.feed === 'teamFeed';
  }

  isPersonal = () => {
    return this.props.feed === 'saved';
  }

  isAuthorFeed = () => {
    return this.props.feed === 'author';
  }

  isTeamOwner = (tactic) => {
    return this.props.user
      && tactic.teamData.members.filter((member) => member.isAdmin)[0].hash === this.props.user.hash;
  }

  isAuthor = (tactic) => {
    return this.props.user && tactic.author.hash === this.props.user.hash;
  }

  getAvailableTeamsToClone = (tactic) => {
    return this.props.teams.filter((team) => team.game === tactic.game);
  }

  renderStatusDropdown = (status, callback, position = 'bottomRight') => {
    const currentStatus = this.state.availableStatuses.filter((item) => item.status === status)[0];
    return (
      <Dropdown
        placement={position}
        overlay={(
          <Menu className="member-assign-select">
            {this.state.availableStatuses.map((item) => {
              return (
                <Menu.Item
                  key={item.status}
                  className="member-assign-select-item"
                  onClick={() => callback(item.status) }
                >
                  <span>{item.label}</span>
                </Menu.Item>
              );
            })}
          </Menu>
        )}
        trigger={['click']}
      >
        <span className={"status-dropdown " + currentStatus.status}>
          {currentStatus.label}
          <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.131956 0.703344L3.68537 3.87918C3.77791 3.96548 3.88896 4 4 4C4.11104 4 4.22209 3.96548 4.31463 3.87918L7.86804 0.703344C8.03461 0.548004 8.05312 0.289105 7.86804 0.116505C7.70148 -0.0388346 7.42387 -0.0388346 7.23879 0.116505L4 3.01618L0.761207 0.116505C0.576134 -0.0388346 0.298523 -0.0388346 0.131956 0.116505C-0.0531175 0.289105 -0.0346102 0.548004 0.131956 0.703344Z" fill="white"/>
          </svg>
        </span>
      </Dropdown>
    );
  }

  renderTacticActionsDropdown = (tactic) => {
    const isTeamFeed = this.isTeamFeed();
    const isPersonal = this.isPersonal();

    return (
      <>
      {(!isTeamFeed && this.props.user) ? (
        <Dropdown
          placement="bottomRight"
          className={(this.props.user.role !== 'superadmin' && !isPersonal) ? 'hidden' : ''}
          overlay={(
            <Menu className="feed-action-menu">
              {(isPersonal || this.props.user.role === 'superadmin') ? (
                <Menu.Item
                  key="edit"
                  onClick={() => { this.props.onTacticEdit(tactic.hash) } }
                >
                  <span>Edit</span>
                </Menu.Item>
              ) : null}
              {isPersonal ? (
                <Menu.Item
                  key="clone"
                  onClick={() => {
                    const teams = this.getAvailableTeamsToClone(tactic);
                    this.setState({
                      tacticToClone: tactic,
                      selectedTeamHash: teams.length > 0 ? teams[0].hash : null,
                    })
                  }}
                >
                  <span>Clone</span>
                </Menu.Item>
              ) : null}
              {(isPersonal || this.props.user.role === 'superadmin') ? (
                <Menu.Item
                  key="delete"
                  className="delete"
                  onClick={() => { this.setState({ tacticToDelete: tactic })} }
                >
                  <span>Delete</span>
                </Menu.Item>
              ) : null}
            </Menu>
          )}
          trigger={['click']}
        >
          <div className="actions">
            <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.75 0.25C0.792969 0.25 0 1.04297 0 2C0 2.95703 0.792969 3.75 1.75 3.75C2.70703 3.75 3.5 2.95703 3.5 2C3.5 1.04297 2.70703 0.25 1.75 0.25ZM7 0.25C6.04297 0.25 5.25 1.04297 5.25 2C5.25 2.95703 6.04297 3.75 7 3.75C7.95703 3.75 8.75 2.95703 8.75 2C8.75 1.04297 7.95703 0.25 7 0.25ZM12.25 0.25C11.293 0.25 10.5 1.04297 10.5 2C10.5 2.95703 11.293 3.75 12.25 3.75C13.207 3.75 14 2.95703 14 2C14 1.04297 13.207 0.25 12.25 0.25Z" fill="#828282"/>
            </svg>
          </div>
        </Dropdown>
      ) : null}

    {(isTeamFeed && this.props.user) ? (
      <Dropdown
        placement="bottomRight"
        overlay={(
          <Menu className="feed-action-menu">
            {(this.isTeamOwner(tactic) || this.isAuthor(tactic)) ? (
              <Menu.Item
                key="edit"
                onClick={() => { this.props.onTeamTacticEdit(tactic.hash) } }
              >
                <span>Edit</span>
              </Menu.Item>
            ) : null}
            <Menu.Item
              key="clone"
              onClick={() => {
                const teams = this.getAvailableTeamsToClone(tactic);
                this.setState({
                  tacticToClone: tactic,
                  selectedTeamHash: teams.length > 0 ? teams[0].hash : null,
                })
              }
              }
            >
              <span>Clone</span>
            </Menu.Item>
            {(this.isTeamOwner(tactic) || this.isAuthor(tactic)) ? (
              <Menu.Item
                key="delete"
                className="delete"
                onClick={() => { this.setState({ tacticToDelete: tactic })} }
              >
                <span>Delete</span>
              </Menu.Item>
            ) : null}
          </Menu>
        )}
        trigger={['click']}
      >
        <div className="actions">
          <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.75 0.25C0.792969 0.25 0 1.04297 0 2C0 2.95703 0.792969 3.75 1.75 3.75C2.70703 3.75 3.5 2.95703 3.5 2C3.5 1.04297 2.70703 0.25 1.75 0.25ZM7 0.25C6.04297 0.25 5.25 1.04297 5.25 2C5.25 2.95703 6.04297 3.75 7 3.75C7.95703 3.75 8.75 2.95703 8.75 2C8.75 1.04297 7.95703 0.25 7 0.25ZM12.25 0.25C11.293 0.25 10.5 1.04297 10.5 2C10.5 2.95703 11.293 3.75 12.25 3.75C13.207 3.75 14 2.95703 14 2C14 1.04297 13.207 0.25 12.25 0.25Z" fill="#828282"/>
          </svg>
        </div>
      </Dropdown>
    ) : null}
    </>
    );
  }

  renderTableViewTactic = (tactic) => {
    return (
      <div className="table-view-tactic">
        <Row>
          <Col span={12}>
            <Link className="name" to={this.getTacticUrl(tactic)}>{tactic.name}</Link>
          </Col>
          <Col span={2} className="centered">
            <div className="author-wrap">
              <Avatar size={20} src={tactic.author.avatar} />
            </div>
          </Col>
          <Col span={2} className="centered">
            <span>{this.getParamName('map', tactic.map)}</span>
          </Col>
          <Col span={2} className="centered">
            <span>{this.getParamName('tickrate', tactic.tickrate)}</span>
          </Col>
          <Col span={2} className="centered">
            <span>{this.getParamName('economics', tactic.economics)}</span>
          </Col>
          <Col span={2} className="centered">
            <span>{tactic.players}</span>
          </Col>
          <Col span={2} className="centered">
            <span>{this.getParamName('side', tactic.side)}</span>
          </Col>
        </Row>
        {this.renderTacticActionsDropdown(tactic)}
      </div>
    );
  }

  render() {
    const isTeamFeed = this.isTeamFeed();
    const isPersonal = this.isPersonal();
    return (
      <>
        <div className={"feed-wrap " + (this.props.user ? '' : 'not-logged ') + (isTeamFeed ? 'team-feed ' : '') + (this.props.viewMode + '-mode ')} id="feed-wrap">
          {(this.props.viewMode === 'table') ? (
            <div className="table-view-header">
              <Row>
                <Col span={12}>
                  <span>Title</span>
                </Col>
                <Col span={2} className="centered">
                  <span>Author</span>
                </Col>
                <Col span={2} className="centered">
                  <span>Map</span>
                </Col>
                <Col span={2} className="centered">
                  <span>Tickrate</span>
                </Col>
                <Col span={2} className="centered">
                  <span>Economy</span>
                </Col>
                <Col span={2} className="centered">
                  <span>Players</span>
                </Col>
                <Col span={2} className="centered">
                  <span>Side</span>
                </Col>
              </Row>
            </div>
          ) : null}
          {this.props.tactics.map((tactic) => {
            if (this.props.viewMode === 'table') {
              return this.renderTableViewTactic(tactic);
            }
            return (
              <div key={tactic.hash} className="feed-item">
                <Row>
                  <Col span={isTeamFeed ? 4 : 9}>
                    <div
                      className="feed-item-image"
                      style={{ backgroundImage: 'url("' + tactic.main_image + '")' }}
                    >
                      {!isTeamFeed ? (
                        <span>{tactic.moves} move{(tactic.moves !== 1) ? 's' : ''}</span>
                      ) : null}
                    </div>
                  </Col>
                  <Col span={isTeamFeed ? 20 : 15} className="feed-item-content">
                    <Link className="name" to={this.getTacticUrl(tactic)}>{tactic.name}</Link>
                    <div className="param-wrap">
                      {isTeamFeed ? (
                        this.renderStatusDropdown(tactic.teamData.status, (status) => { this.props.onTacticStatusChange(tactic.hash, status) }, 'bottomLeft')
                      ) : null}
                      <span className="param">{this.getParamName('map', tactic.map)}</span>
                      <span className="param">Tickrate {this.getParamName('tickrate', tactic.tickrate)}</span>
                      <span className="param">{this.getParamName('side', tactic.side)}</span>
                      <span className="param">{this.getParamName('economics', tactic.economics)}</span>
                      <span className="param">{tactic.players} player{tactic.players * 1 !== 1 ? 's' : ''}</span>
                    </div>
                    {!isTeamFeed ? (
                      <>
                        <div className="author-wrap">
                          <Avatar size={20} src={tactic.author.avatar} />
                          <Link className="author-name" to={'/author/' + tactic.author.name + '/csgo'}>{tactic.author.name}</Link>
                          <span className="date"> by {moment(tactic.created_at).format("D MMM'YY")}</span>
                        </div>
                        {!isPersonal ? (
                          <div className="buttons-wrap">
                            <div className="stats">
                            <span className="stat">
                              <Tooltip overlayClassName="app-tooltip" mouseEnterDelay={1} title="Views">
                                <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8 2.66667C6.02778 2.66667 4.44444 4.25 4.44444 6.22222C4.44444 8.19444 6.02778 9.77778 8 9.77778C9.97222 9.77778 11.5556 8.19444 11.5556 6.22222C11.5556 4.25 9.97222 2.66667 8 2.66667ZM8 8.88889C6.52778 8.88889 5.33333 7.69444 5.33333 6.22222C5.33333 4.75 6.52778 3.55556 8 3.55556C9.47222 3.55556 10.6667 4.75 10.6667 6.22222C10.6667 7.69444 9.47222 8.88889 8 8.88889ZM15.9167 5.75C14.3889 2.33333 11.4167 0 8 0C4.58333 0 1.61111 2.33333 0.0833333 5.75C0.0555556 5.86111 0 6.08333 0 6.22222C0 6.36111 0.0555556 6.58333 0.0833333 6.69444C1.61111 10.1111 4.58333 12.4444 8 12.4444C11.4167 12.4444 14.3889 10.1111 15.9167 6.69444C15.9444 6.58333 16 6.36111 16 6.22222C16 6.08333 15.9444 5.86111 15.9167 5.75ZM15.0833 6.33333C13.6667 9.55556 10.9722 11.5556 8 11.5556C5.02778 11.5556 2.33333 9.55556 0.916667 6.36111C0.916667 6.33333 0.888889 6.25 0.888889 6.22222C0.888889 6.19444 0.916667 6.11111 0.916667 6.11111C2.33333 2.88889 5.02778 0.888889 8 0.888889C10.9722 0.888889 13.6667 2.88889 15.0833 6.08333C15.0833 6.11111 15.1111 6.19444 15.1111 6.22222C15.1111 6.25 15.0833 6.33333 15.0833 6.33333Z" fill="#AAC4F8"/>
                                </svg>
                                {tactic.views}
                              </Tooltip>
                            </span>
                              <span className="stat">
                              <Tooltip overlayClassName="app-tooltip" mouseEnterDelay={1} title="Saves">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 9.625C7.10938 9.625 7.21875 9.57031 7.30078 9.48828L11.2383 5.55078C11.4297 5.38672 11.4297 5.11328 11.2383 4.94922C11.0469 4.78516 10.8008 4.75781 10.6367 4.94922L7.4375 8.12109L7.4375 0.4375C7.4375 0.191406 7.24609 0 7 0C6.75391 0 6.5625 0.191406 6.5625 0.4375L6.5625 8.12109L3.36328 4.94922C3.19922 4.75781 2.92578 4.75781 2.76172 4.94922C2.59766 5.14063 2.57031 5.38672 2.76172 5.55078L6.69922 9.48828C6.78125 9.57031 6.89063 9.625 7 9.625ZM13.125 11.8125V9.1875C13.125 8.94141 12.9336 8.75 12.6875 8.75C12.4414 8.75 12.25 8.94141 12.25 9.1875V11.8125C12.25 12.5234 11.6484 13.125 10.9375 13.125H3.0625C2.35156 13.125 1.75 12.5234 1.75 11.8125V9.1875C1.75 8.94141 1.55859 8.75 1.3125 8.75C1.06641 8.75 0.875 8.94141 0.875 9.1875V11.8125C0.875 13.0156 1.85938 14 3.0625 14H10.9375C12.1406 14 13.125 13.0156 13.125 11.8125Z" fill="#AAC4F8"/>
                                </svg>
                                {tactic.saves}
                              </Tooltip>
                            </span>
                              <span className="stat">
                              <Tooltip overlayClassName="app-tooltip" mouseEnterDelay={1} title="Likes">
                                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12.633 0.875022C11.1565 -0.410134 8.91429 -0.191384 7.5471 1.23049L7.00022 1.80471L6.45335 1.23049C5.3596 0.0820534 3.09007 -0.60154 1.36741 0.875022C-0.355246 2.32424 -0.437277 4.94924 1.09397 6.53518L6.37132 12.0039C6.56272 12.1953 6.78147 12.2774 7.00022 12.2774C7.21897 12.2774 7.43772 12.1953 7.62913 12.0039L12.9065 6.53518C14.4377 4.94924 14.3557 2.32424 12.633 0.875022ZM12.2776 5.93362L7.00022 11.4024L1.72288 5.93362C0.656473 4.86721 0.437723 2.78908 1.94163 1.53127C3.41819 0.246116 5.19554 1.1758 5.82444 1.8594L7.00022 3.06252L8.176 1.8594C8.80491 1.20315 10.5823 0.27346 12.0862 1.53127C13.5627 2.78908 13.344 4.86721 12.2776 5.93362Z" fill="#AAC4F8"/>
                                </svg>
                                {tactic.likes}
                              </Tooltip>
                            </span>
                            </div>
                            <div className="buttons">
                              {this.renderLikeButton(tactic)}
                              {this.renderSaveButton(tactic)}
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {this.renderTacticActionsDropdown(tactic)}

                  </Col>
                  {isTeamFeed ? (
                    <div className="moves">
                      {tactic.teamData.moves.map((move, index) => {
                        if (
                            this.props.assignees.length > 0
                            && (
                              !move.assignee
                              || !(move.assignee && this.props.assignees.includes(move.assignee.hash))
                            )
                        ) {
                          return null;
                        }
                        if (this.props.statuses.length > 0 && !this.props.statuses.includes(move.status)) {
                          return null;
                        }
                        return (
                          <Row key={index} className="move">
                            <Col flex="30px" className="left-column">
                              <Tooltip
                                key={'assignee_' + index}
                                overlayClassName="app-tooltip"
                                mouseEnterDelay={1}
                                title={move.assignee ? move.assignee.name : 'Click to assign'}
                              >
                                <Dropdown
                                  placement="bottomLeft"
                                  overlay={(
                                    <Menu className="member-assign-select">
                                      {tactic.teamData.members.filter((member) => member.status === 'active').map((member) => {
                                        return (
                                          <Menu.Item
                                            key={member.hash}
                                            className="member-assign-select-item"
                                            onClick={() => this.props.onMemberAssign(tactic.hash, member, index) }
                                          >
                                            <div
                                              className="member-avatar"
                                              style={member.avatar ? { backgroundImage: 'url("' + member.avatar + '")' } : {}}
                                            >
                                              {member.avatar ? null : (
                                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25Z" fill="#828282"/>
                                                </svg>
                                              )}
                                            </div>
                                            <span>{member.name}</span>
                                          </Menu.Item>
                                        );
                                      })}
                                    </Menu>
                                  )}
                                  trigger={['click']}
                                >
                                  <span className={"member"} >
                                    <div
                                      className="member-avatar"
                                      style={(move.assignee && move.assignee.avatar) ? { backgroundImage: 'url("' + move.assignee.avatar + '")' } : {}}
                                    >
                                      {(move.assignee && move.assignee.avatar) ? null : (
                                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25Z" fill="#828282"/>
                                        </svg>
                                      )}
                                    </div>
                                  </span>
                                </Dropdown>
                              </Tooltip>
                            </Col>
                            <Col flex="auto" className="middle-column">
                              <span className="move-name">{move.name}</span>
                            </Col>
                            <Col flex="auto" className="right-column">
                              {this.renderStatusDropdown(move.status, (status) => { this.props.onMoveStatusChange(tactic.hash, status, index) })}
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  ) : null}
                </Row>
              </div>
            );
          })}
        </div>
        <Modal
          title={'Delete Tactic'}
          visible={!!this.state.tacticToDelete}
          className={'app-modal'}
          onOk={this.onModalOk}
          onCancel={this.onModalCancel}
          closeIcon={(
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
            </svg>
          )}
          footer={[
            <IconButton title="Delete" size="big" className="danger" onClick={this.onModalOk}/>
          ]}
        >
          {this.state.tacticToDelete ? (
            <p>Are you sure you want to delete tactic "{this.state.tacticToDelete.name}"?</p>
          ) : null}
        </Modal>
        <Modal
          title={'Unsave Tactic'}
          visible={!!this.state.tacticToUnsave}
          className={'app-modal'}
          onOk={this.onUnsaveModalOk}
          onCancel={this.onUnsaveModalCancel}
          closeIcon={(
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
            </svg>
          )}
          footer={[
            <IconButton title="Ok" size="big" className="danger" onClick={this.onUnsaveModalOk}/>
          ]}
        >
          {this.state.tacticToUnsave ? (
              <p>Are you sure to unsave this tactic? This tactic will be removed from your feed.</p>
          ) : null}
        </Modal>
        <Modal
          title={'Clone Tactic'}
          visible={!!this.state.tacticToClone}
          className={'app-modal'}
          onOk={this.onCloneModalOk}
          onCancel={this.onCloneModalCancel}
          closeIcon={(
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
            </svg>
          )}
          footer={[
            !this.state.isCloning ? (
              <IconButton
                title="Clone"
                size="big"
                cantPress={!this.state.selectedTeamHash}
                onClick={this.onCloneModalOk}
              />
            ) : null
          ]}
        >
          <p>Сhoose a team feed to clone</p>
          <Radio.Group
            className="app-radio-group"
            onChange={(e) => {
              this.setState({
                selectedTeamHash: e.target.value
              })
            }}
            value={this.state.selectedTeamHash}
          >
            {this.state.tacticToClone ? (
              <Space direction="vertical">
                {this.getAvailableTeamsToClone(this.state.tacticToClone).map((team) => {
                    return (
                      <Radio
                        value={team.hash}
                        key={team.hash}
                      >
                        Team Feed <b>{team.name}</b>
                      </Radio>
                    );
                  })}
              </Space>
            ) : null}
          </Radio.Group>
        </Modal>
        <Modal
            title={'Save Tactic'}
            visible={!!this.state.tacticToSave}
            className={'app-modal'}
            onOk={this.onSaveModalOk}
            onCancel={this.onSaveModalCancel}
            closeIcon={(
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
                </svg>
            )}
            footer={[
              !this.state.isCloning ? (
                  <IconButton
                      title="Save"
                      size="big"
                      cantPress={!this.state.selectedTeamHash}
                      onClick={this.onSaveModalOk}
                  />
              ) : null
            ]}
        >
          <p>Сhoose a team feed to save</p>
          <Radio.Group
              className="app-radio-group"
              onChange={(e) => {
                this.setState({
                  selectedTeamHash: e.target.value
                })
              }}
              value={this.state.selectedTeamHash}
          >
            {this.state.tacticToSave ? (
                <Space direction="vertical">
                  {this.getAvailableTeamsToClone(this.state.tacticToSave).map((team) => {
                    return (
                        <Radio
                            value={team.hash}
                            key={team.hash}
                        >
                          Team Feed <b>{team.name}</b>
                        </Radio>
                    );
                  })}
                  <Radio
                      value='personal'
                      key='personal'
                  >
                    My Feed
                  </Radio>
                </Space>
            ) : null}
          </Radio.Group>
        </Modal>
      </>
    );
  }
}

export default Feed;
