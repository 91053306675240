import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notification, BackTop } from 'antd';

import AppHeader from '../layout/AppHeader/AppHeader';
import FeedFilter from '../components/FeedFilter/FeedFilter';
import FeedHeader from '../components/FeedHeader/FeedHeader';
import Feed from "../components/Feed/Feed";
import AxiosContext from "../context/AxiosContext";
import UserContext from "../context/UserContext";
import ForceTacticReloadContext from "../context/ForceTacticReloadContext";
import ForceUserReloadContext from "../context/ForceUserReloadContext";
import TacticEditorContext from "../context/TacticEditorContext";
import IconButton from "../ui/IconButton/IconButton";
import IconPlus from "../ui/Icons/IconPlus";
import LoginViaSteam from "../dummies/LoginViaSteam";

let searchTimerId = null;

export default function FeedView(props) {
  let params = useParams();
  let navigate = useNavigate();

  const [filterData, setFilterData] = useState({
    filters: [],
    filterValues: {},
  });

  const [searchPhrase, setSearchPhrase] = useState('');

  const [tacticsData, setTacticsData] = useState({
    tactics: [],
    tacticsCount: 0,
    totalTacticsCount: 0,
  });

  const [teamFilterData, setTeamFilterData] = useState({
    assignees: [],
    statuses: [],
  });

  const [loaded, setLoaded] = useState(false);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [orderBy, setOrderBy] = useState('date');
  const [teams, setTeams] = useState([]);
  const [author, setAuthor] = useState(null);
  const [viewMode, setViewMode] = useState('list');
  const [urlSearchMode, setUrlSearchMode] = useState(true);

  const axiosInstance = useContext(AxiosContext);
  const user = useContext(UserContext);
  const onTacticEditorOpen = useContext(TacticEditorContext);
  const forceTacticReload = useContext(ForceTacticReloadContext);
  const forceUserReload = useContext(ForceUserReloadContext);

  const teamHash = params.teamHash;

  useEffect(() => {
    if (urlSearchMode) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const urlSearchPhrase = urlParams.get('search');
      if (urlSearchPhrase) {
        setSearchPhrase(urlSearchPhrase)
      }
      setUrlSearchMode(false);
    }
    loadFilters();
    if (user) {
      loadTeams();
    }
  }, []);

  useEffect(() => {
    if (user) {
      loadTeams();
    }
  }, [user]);

  useEffect(() => {
    if (props.feed === 'author') {
      loadAuthor();
    }
  }, [params.author]);

  useEffect(() => {
    setLoaded(false);
  }, [props.feed]);

  useEffect(() => {
    if (filterData.filters.length > 0) {
      searchTactics();
    }
  }, [filterData, orderBy, teamHash, teamFilterData, props.feed]);

  useEffect(() => {
    if (filterData.filters.length > 0) {
      searchTactics(true);
    }
  }, [offset]);

  useEffect(() => {
    if (filterData.filters.length === 0) {
      return null;
    }
    if (searchTimerId) {
      clearTimeout(searchTimerId);
    }
    searchTimerId = setTimeout(searchTactics, 500);
  }, [searchPhrase]);

  useEffect(() => {
    if (forceTacticReload) {
      searchTactics();
    }
  }, [forceTacticReload]);

  const loadFilters = () => {
    let url = '/api/filters/' + params.game;
    if (params.teamHash) {
      url = '/api/team-filters/' + params.teamHash;
    }

    axiosInstance.get(url)
      .then((response) => {
        const filterValues = {};
        response.data.forEach((filter) => {
          filterValues[filter.name] = ['all'];
        });
        setFilterData({
          filters: response.data,
          filterValues: filterValues,
          searchPhrase: '',
        });
      }).catch(error => console.log(error));
  }

  const loadAuthor = () => {
    axiosInstance.get('/api/author/' + params.author)
      .then((response) => {
        setAuthor(response.data)
      }).catch(error => console.log(error));
  }

  const searchTactics = (add = false) => {
    let searchParams = JSON.parse(JSON.stringify(filterData.filterValues));
    searchParams.searchPhrase = searchPhrase;
    searchParams.offset = offset;
    searchParams.limit = limit;
    searchParams.orderBy = orderBy;

    if (params.teamHash) {
      searchParams.assignees = teamFilterData.assignees;
      searchParams.statuses = teamFilterData.statuses;
    }

    if (props.feed === 'saved') {
      searchParams.isPersonal = true;
    }

    if (props.feed === 'liked') {
      searchParams.isLiked = true;
    }

    if (props.feed === 'subscriptions') {
      searchParams.isSubscribed = true;
    }

    if (props.feed === 'author') {
      searchParams.author = params.author;
    }

    let url = '/api/tactics/' + params.game;
    if (params.teamHash) {
      url = '/api/team-tactics/' + params.teamHash;
    }

    axiosInstance.get(url, { params: searchParams })
      .then((response) => {
        let tactics = response.data.tactics;
        if (add) {
          tactics = tacticsData.tactics.concat(tactics);
        }
        setTacticsData({
          tactics: tactics,
          tacticsCount: response.data.tacticsCount,
          totalTacticsCount: response.data.totalTacticsCount,
        });
        setLoaded(true);
      }).catch((error) => console.log(error));
  }

  const loadTeams = () => {
    axiosInstance.get('/api/teams')
      .then((response) => {
        setTeams(response.data.teams);
      }).catch(error => console.log(error));
  };

  const onFeedScrollEnd = () => {
    if (tacticsData.tacticsCount <= offset + limit) {
      return null;
    }
    setOffset(offset + limit);
  }

  const onSelectFilter = (filterName, optionValue) => {
    let tempFilterData = JSON.parse(JSON.stringify(filterData));
    let filterValue = tempFilterData.filterValues[filterName];
    if (!filterValue.includes(optionValue) && optionValue === 'all') {
      filterValue = ['all'];
    } else {
      filterValue = filterValue.filter(value => value !== 'all');
      if (filterValue.includes(optionValue)) {
        filterValue = filterValue.filter(value => value !== optionValue);
      } else {
        filterValue.push(optionValue);
      }
    }

    tempFilterData.filterValues[filterName] = filterValue;
    setFilterData(tempFilterData);
  }

  const onLike = (hash) => {
    setTacticsData({
      tactics: tacticsData.tactics.map((tactic) => {
        if (tactic.hash === hash) {
          tactic.liked = true;
        }
        return tactic;
      }),
      tacticsCount: tacticsData.tacticsCount,
      totalTacticsCount: tacticsData.totalTacticsCount,
    });
    axiosInstance.post('/api/tactics/' + params.game + '/like', { hash })
      .then((response) => {
        setTacticsData({
          tactics: tacticsData.tactics.map((tactic) => {
            if (tactic.hash === hash) {
              tactic.likes = response.data.likes;
            }
            return tactic;
          }),
          tacticsCount: tacticsData.tacticsCount,
          totalTacticsCount: tacticsData.totalTacticsCount,
        });
      }).catch(error => console.log(error));
  }

  const onUnlike = (hash) => {
    setTacticsData({
      tactics: tacticsData.tactics.map((tactic) => {
        if (tactic.hash === hash) {
          tactic.liked = false;
        }
        return tactic;
      }),
      tacticsCount: tacticsData.tacticsCount,
      totalTacticsCount: tacticsData.totalTacticsCount,
    });
    axiosInstance.post('/api/tactics/' + params.game + '/unlike', { hash })
      .then((response) => {
        setTacticsData({
          tactics: tacticsData.tactics.map((tactic) => {
            if (tactic.hash === hash) {
              tactic.likes = response.data.likes;
            }
            return tactic;
          }),
          tacticsCount: tacticsData.tacticsCount,
          totalTacticsCount: tacticsData.totalTacticsCount,
        });
      }).catch(error => console.log(error));
  }

  const onTacticSave = (hash, targetTeamHash, successMessage = '') => {
    const tacticsCount = tacticsData.tacticsCount;
    const totalTacticsCount = tacticsData.totalTacticsCount;
    setTacticsData({
      tactics: tacticsData.tactics.map((tactic) => {
        if (tactic.hash === hash) {
          tactic.saved = true;
        }
        return tactic;
      }),
      tacticsCount,
      totalTacticsCount,
    });
    axiosInstance.post('/api/tactics/' + params.game + '/save', { hash, targetTeamHash })
      .then((response) => {
        setTacticsData({
          tactics: tacticsData.tactics.map((tactic) => {
            if (tactic.hash === hash) {
              tactic.saves = response.data.saves;
              tactic.savedData = response.data.tactic.savedData;
            }
            return tactic;
          }),
          tacticsCount,
          totalTacticsCount,
        });
        notification.success({
          message: successMessage || 'Tactic saved'
        });
      }).catch(error => console.log(error));
  }

  const onTacticUnsave = (hash, successMessage = '') => {
    const tacticsCount = tacticsData.tacticsCount;
    const totalTacticsCount = tacticsData.totalTacticsCount;
    setTacticsData({
      tactics: tacticsData.tactics.map((tactic) => {
        if (tactic.hash === hash) {
          tactic.saved = false;
        }
        return tactic;
      }),
      tacticsCount,
      totalTacticsCount,
    });
    axiosInstance.post('/api/tactics/' + params.game + '/unsave', { hash })
      .then((response) => {
        setTacticsData({
          tactics: tacticsData.tactics.map((tactic) => {
            if (tactic.hash === hash) {
              tactic.saves = response.data.saves;
              delete(tactic.savedData);
            }
            return tactic;
          }),
          tacticsCount,
          totalTacticsCount,
        });
        notification.error({
          message: successMessage || 'Tactic unsaved'
        });
      }).catch(error => console.log(error));
  }

  const onTacticDelete = (hash, successMessage = '') => {
    setTacticsData({
      tactics: tacticsData.tactics.filter((tactic) => tactic.hash !== hash),
      tacticsCount: tacticsData.tacticsCount - 1,
      totalTacticsCount: tacticsData.totalTacticsCount - 1,
    });

    let url = '/api/tactics/' + params.game + '/delete';
    if (teamHash) {
      url = '/api/team-tactics/' + teamHash + '/delete';
    }

    axiosInstance.post(url, { hash })
      .then(() => {
        notification.error({
          message: successMessage || 'Tactic deleted'
        });
      })
      .catch(error => console.log(error));
  }

  const onTacticEdit = (hash) => {
    axiosInstance.get('/api/tactics/' + params.game + '/get/' + hash)
      .then((response) => {
        onTacticEditorOpen(response.data.tactic, 'edit');
      }).catch(error => console.log(error));
  }

  const onTacticCreate = () => {
    onTacticEditorOpen(null, 'create');
  }

  const onMemberAssign = (tacticHash, member, moveIndex) => {
    let tempTactics = JSON.parse(JSON.stringify(tacticsData.tactics));
    setTacticsData({
      tactics: tempTactics.map((tactic) => {
        if (tactic.hash === tacticHash) {
          tactic.teamData.moves[moveIndex].assignee = member;
        }
        return tactic;
      }),
      tacticsCount: tacticsData.tacticsCount,
      totalTacticsCount: tacticsData.totalTacticsCount,
    });
    axiosInstance.post(
      '/api/team-tactics/' + params.teamHash + '/' + tacticHash + '/assign',
      { hash: member.hash, moveIndex }
    ).then(() => {}).catch(error => console.log(error));
  }

  const onMoveStatusChange = (tacticHash, status, moveIndex) => {
    let tempTactics = JSON.parse(JSON.stringify(tacticsData.tactics));
    setTacticsData({
      tactics: tempTactics.map((tactic) => {
        if (tactic.hash === tacticHash) {
          tactic.teamData.moves[moveIndex].status = status;
        }
        return tactic;
      }),
      tacticsCount: tacticsData.tacticsCount,
      totalTacticsCount: tacticsData.totalTacticsCount,
    });
    axiosInstance.post(
      '/api/team-tactics/' + params.teamHash + '/' + tacticHash + '/move-status-change',
      { status, moveIndex }
    ).then(() => {}).catch(error => console.log(error));
  }

  const onTacticStatusChange = (tacticHash, status) => {
    let tempTactics = JSON.parse(JSON.stringify(tacticsData.tactics));
    setTacticsData({
      tactics: tempTactics.map((tactic) => {
        if (tactic.hash === tacticHash) {
          tactic.teamData.status = status;
        }
        return tactic;
      }),
      tacticsCount: tacticsData.tacticsCount,
      totalTacticsCount: tacticsData.totalTacticsCount,
    });
    axiosInstance.post(
      '/api/team-tactics/' + params.teamHash + '/' + tacticHash + '/status-change',
      { status }
    ).then(() => {}).catch(error => console.log(error));
  }

  const onTeamTacticEdit = (hash) => {
    axiosInstance.get('/api/team-tactics/' + params.teamHash + '/get/' + hash)
      .then((response) => {
        onTacticEditorOpen(response.data.tactic, 'edit');
      }).catch(error => console.log(error));
  }

  const onTacticClone = (hash, targetTeamHash, isPersonal = false, successMessage = '') => {
    if (isPersonal) {
      axiosInstance.post('/api/tactics/' + params.game + '/clone', { hash, targetTeamHash })
        .then((response) => {
          console.log(response);
          notification.success({
            message: successMessage || 'Tactic cloned'
          });
        }).catch(error => console.log(error));
    } else {
      axiosInstance.post('/api/team-tactics/' + params.teamHash + '/clone', { hash, targetTeamHash })
        .then((response) => {
          let tempTactics = JSON.parse(JSON.stringify(tacticsData.tactics));
          if (params.teamHash === targetTeamHash) {
            tempTactics.splice(0, 0, response.data.tactic);
            setTacticsData({
              tactics: tempTactics,
              tacticsCount: tacticsData.tacticsCount + 1,
              totalTacticsCount: tacticsData.totalTacticsCount + 1,
            });
            document.getElementById('root').scrollIntoView({behavior: 'smooth'});
          }
          notification.success({
            message: successMessage || 'Tactic cloned'
          });
        }).catch(error => console.log(error));
    }
  }

  const onSubscribe = () => {
    axiosInstance.post('/api/author/subscribe/' + author.hash)
      .then((response) => {
        forceUserReload();
      }).catch(error => console.log(error));
  }

  const onUnsubscribe = () => {
    axiosInstance.post('/api/author/unsubscribe/' + author.hash)
      .then((response) => {
        forceUserReload();
      }).catch(error => console.log(error));
  }

  const getDummyHeader = () => {
    return {
      teamFeed: 'No Tactics',
      saved: 'No Tactics',
      liked: 'No Likes',
      subscriptions: 'No Subscriptions'
    }[props.feed] || 'No Tactics';
  }

  const getDummyIcon = () => {
    return {
      teamFeed: (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_782_5583)">
            <path opacity="0.4" d="M50.0002 21.875C56.0549 21.875 60.9377 16.9922 60.9377 10.9375C60.9377 4.88281 56.0549 0 50.0002 0C43.9455 0 39.0627 4.88281 39.0627 10.9375C39.0627 16.9922 43.9455 21.875 50.0002 21.875ZM98.633 35.9375L92.969 32.8125C91.6018 31.8359 89.844 32.4219 88.6721 33.7891C86.5237 36.7188 83.008 38.0859 79.4924 37.3047C75.0002 36.5234 71.8752 32.2266 71.8752 27.5391C71.8752 26.1719 70.7034 25 69.3362 25H61.719C60.5471 25 59.3752 25.7812 59.1799 26.9531C58.2034 31.25 54.4924 34.375 50.0002 34.375C45.508 34.375 41.7971 31.25 40.8205 26.9531C40.6252 25.7812 39.4534 25 38.2815 25H30.6643C29.2971 25 28.1252 26.1719 28.1252 27.5391C28.1252 32.6172 24.4143 37.1094 19.3362 37.5C16.2112 37.6953 13.0862 36.3281 11.3284 33.7891C10.1565 32.4219 8.39867 31.8359 7.03148 32.8125L1.36742 35.9375C0.0002289 36.9141 -0.390396 38.6719 0.390854 40.0391L20.3127 81.25H79.6877L99.6096 40.0391C100.391 38.4766 100 36.9141 98.633 35.9375Z" fill="#2A6CED"/>
            <path d="M87.5 96.875C87.5 98.6328 86.1328 100 84.375 100H15.625C13.8672 100 12.5 98.6328 12.5 96.875C12.5 92.7734 15.0391 89.4531 18.75 88.0859V84.375C18.75 82.6172 20.1172 81.25 21.875 81.25H78.125C79.8828 81.25 81.25 82.6172 81.25 84.375V88.0859C84.9609 89.4531 87.5 92.7734 87.5 96.875Z" fill="#2A6CED"/>
          </g>
          <defs>
            <clipPath id="clip0_782_5583">
              <rect width="100" height="100" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      ),
      saved: (
        <svg style={{marginBottom: 0}} width="200" height="200" viewBox="-4 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="#2a6ced" className="icon-saved-1" opacity="0.4" d="M11.25 9.75V7.875C11.25 7.66406 11.0859 7.5 10.875 7.5H10.125C9.91406 7.5 9.75 7.66406 9.75 7.875V9.75C9.75 10.1719 9.42188 10.5 9 10.5H3C2.57812 10.5 2.25 10.1719 2.25 9.75V7.875C2.25 7.66406 2.08594 7.5 1.875 7.5H1.125C0.914062 7.5 0.75 7.66406 0.75 7.875V9.75C0.75 10.9922 1.75781 12 3 12H9C10.2422 12 11.25 10.9922 11.25 9.75Z"/>
          <path fill="#2a6ced" className="icon-saved-2" d="M6 8.25C6.1875 8.25 6.375 8.17969 6.53906 8.03906L9.63281 4.92188C9.79688 4.78125 9.79688 4.54688 9.63281 4.38281L9.11719 3.86719C8.95312 3.70312 8.71875 3.70312 8.57812 3.86719L6.75 5.69531V0.375C6.75 0.164063 6.58594 0 6.375 0L5.625 0C5.41406 0 5.25 0.164063 5.25 0.375V5.69531L3.42188 3.86719C3.28125 3.70313 3.04688 3.70313 2.88281 3.86719L2.36719 4.38281C2.20312 4.54688 2.20312 4.78125 2.36719 4.92188L5.46094 8.03906C5.625 8.17969 5.8125 8.25 6 8.25Z"/>
        </svg>
      ),
      liked: (
        <svg width="100" height="100" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.04167 3.73333C8.37083 3.73333 7.72917 4.05417 7.32083 4.4625L7 4.8125L6.67917 4.4625C6.27083 4.05417 5.62917 3.73333 4.95833 3.73333C4.52083 3.73333 4.025 3.87917 3.61667 4.25833C2.59583 5.13333 2.5375 6.7375 3.44167 7.7L6.62083 11.025C6.825 11.2583 7.175 11.2583 7.37917 11.025L10.5583 7.7C11.4625 6.7375 11.4042 5.13333 10.3833 4.25833C9.975 3.87917 9.47917 3.73333 9.04167 3.73333ZM9.8875 7.05833L7 10.0917L4.1125 7.05833C3.61667 6.5625 3.52917 5.54167 4.2 4.95833C4.4625 4.75417 4.725 4.66667 4.9875 4.66667C5.39583 4.66667 5.775 4.87083 6.00833 5.13333L7 6.15417L7.99167 5.13333C8.225 4.87083 8.60417 4.66667 9.0125 4.66667C9.275 4.66667 9.5375 4.75417 9.77083 4.95833C10.4708 5.54167 10.3833 6.5625 9.8875 7.05833ZM7 0C3.12083 0 0 3.12083 0 7C0 10.8792 3.12083 14 7 14C10.8792 14 14 10.8792 14 7C14 3.12083 10.8792 0 7 0ZM7 13.0667C3.64583 13.0667 0.933333 10.3542 0.933333 7C0.933333 3.64583 3.64583 0.933333 7 0.933333C10.3542 0.933333 13.0667 3.64583 13.0667 7C13.0667 10.3542 10.3542 13.0667 7 13.0667Z" fill="#2A6CED"/>
        </svg>
      ),
      subscriptions: (
        <svg width="100" height="100" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 2.66667C6.02778 2.66667 4.44444 4.25 4.44444 6.22222C4.44444 8.19444 6.02778 9.77778 8 9.77778C9.97222 9.77778 11.5556 8.19444 11.5556 6.22222C11.5556 4.25 9.97222 2.66667 8 2.66667ZM8 8.88889C6.52778 8.88889 5.33333 7.69444 5.33333 6.22222C5.33333 4.75 6.52778 3.55556 8 3.55556C9.47222 3.55556 10.6667 4.75 10.6667 6.22222C10.6667 7.69444 9.47222 8.88889 8 8.88889ZM15.9167 5.75C14.3889 2.33333 11.4167 0 8 0C4.58333 0 1.61111 2.33333 0.0833333 5.75C0.0555556 5.86111 0 6.08333 0 6.22222C0 6.36111 0.0555556 6.58333 0.0833333 6.69444C1.61111 10.1111 4.58333 12.4444 8 12.4444C11.4167 12.4444 14.3889 10.1111 15.9167 6.69444C15.9444 6.58333 16 6.36111 16 6.22222C16 6.08333 15.9444 5.86111 15.9167 5.75ZM15.0833 6.33333C13.6667 9.55556 10.9722 11.5556 8 11.5556C5.02778 11.5556 2.33333 9.55556 0.916667 6.36111C0.916667 6.33333 0.888889 6.25 0.888889 6.22222C0.888889 6.19444 0.916667 6.11111 0.916667 6.11111C2.33333 2.88889 5.02778 0.888889 8 0.888889C10.9722 0.888889 13.6667 2.88889 15.0833 6.08333C15.0833 6.11111 15.1111 6.19444 15.1111 6.22222C15.1111 6.25 15.0833 6.33333 15.0833 6.33333Z" fill="#AAC4F8"/>
        </svg>
      )
    }[props.feed] || (
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_782_5583)">
          <path opacity="0.4" d="M50.0002 21.875C56.0549 21.875 60.9377 16.9922 60.9377 10.9375C60.9377 4.88281 56.0549 0 50.0002 0C43.9455 0 39.0627 4.88281 39.0627 10.9375C39.0627 16.9922 43.9455 21.875 50.0002 21.875ZM98.633 35.9375L92.969 32.8125C91.6018 31.8359 89.844 32.4219 88.6721 33.7891C86.5237 36.7188 83.008 38.0859 79.4924 37.3047C75.0002 36.5234 71.8752 32.2266 71.8752 27.5391C71.8752 26.1719 70.7034 25 69.3362 25H61.719C60.5471 25 59.3752 25.7812 59.1799 26.9531C58.2034 31.25 54.4924 34.375 50.0002 34.375C45.508 34.375 41.7971 31.25 40.8205 26.9531C40.6252 25.7812 39.4534 25 38.2815 25H30.6643C29.2971 25 28.1252 26.1719 28.1252 27.5391C28.1252 32.6172 24.4143 37.1094 19.3362 37.5C16.2112 37.6953 13.0862 36.3281 11.3284 33.7891C10.1565 32.4219 8.39867 31.8359 7.03148 32.8125L1.36742 35.9375C0.0002289 36.9141 -0.390396 38.6719 0.390854 40.0391L20.3127 81.25H79.6877L99.6096 40.0391C100.391 38.4766 100 36.9141 98.633 35.9375Z" fill="#2A6CED"/>
          <path d="M87.5 96.875C87.5 98.6328 86.1328 100 84.375 100H15.625C13.8672 100 12.5 98.6328 12.5 96.875C12.5 92.7734 15.0391 89.4531 18.75 88.0859V84.375C18.75 82.6172 20.1172 81.25 21.875 81.25H78.125C79.8828 81.25 81.25 82.6172 81.25 84.375V88.0859C84.9609 89.4531 87.5 92.7734 87.5 96.875Z" fill="#2A6CED"/>
        </g>
        <defs>
          <clipPath id="clip0_782_5583">
            <rect width="100" height="100" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    );
  }

  const getDummyText = () => {
    return {
      teamFeed: (
        <>
          You haven't added any tactics<br/>
          You can create your own or add from the feed
        </>
      ),
      saved: 'Hmm... looks like you haven\'t saved anything yet',
      liked: 'Hmm... looks like you haven\'t liked anything yet',
      subscriptions: 'Hmm... looks like you haven\'t subscribed for anybody yet'
    }[props.feed] || (
      <>
        You haven't added any tactics<br/>
        You can create your own or add from the feed
      </>
    );
  }

  if (props.feed !== 'mainFeed' && props.feed !== 'author' && !user) {
    return <LoginViaSteam/>;
  }

  return (
    <>
      <AppHeader
        feed={props.feed}
        game={params.game}
        teamHash={params.teamHash}
        teams={teams}
        author={author}
        authorParam={params.author}
        user={user}
        onSubscribe={onSubscribe}
        onUnsubscribe={onUnsubscribe}
      />
      {loaded ? (
        (props.feed === 'mainFeed' || tacticsData.totalTacticsCount > 0 ) ? (
          <>
            <div className="filter-wrap">
              <FeedFilter
                filters={filterData.filters}
                filterValues={filterData.filterValues}
                searchPhrase={searchPhrase}
                feed={props.feed}
                onSelectFilter={onSelectFilter}
                onChangeSearchPhrase={(searchPhrase) => {
                  if (searchPhrase) {
                    navigate('?search=' + encodeURIComponent(searchPhrase), { replace: true });
                  } else {
                    navigate('', { replace: true });
                  }
                  setSearchPhrase(searchPhrase);
                }}
              />
            </div>
            <FeedHeader
              tacticsCount={tacticsData.tacticsCount}
              orderBy={orderBy}
              onOrderByChange={setOrderBy}
              feed={props.feed}
              teamHash={params.teamHash}
              user={user}
              axiosInstance={axiosInstance}
              assignees={teamFilterData.assignees}
              statuses={teamFilterData.statuses}
              viewMode={viewMode}
              setViewMode={(value) => {setViewMode(value)}}
              onTeamFilterChange={setTeamFilterData}
            />
            <Feed
              game={params.game}
              feed={props.feed}
              teamHash={params.teamHash}
              teams={teams}
              tacticsCount={tacticsData.tacticsCount}
              tactics={tacticsData.tactics}
              filters={filterData.filters}
              assignees={teamFilterData.assignees}
              statuses={teamFilterData.statuses}
              user={user}
              author={author}
              viewMode={viewMode}
              onLike={onLike}
              onUnlike={onUnlike}
              onTacticSave={onTacticSave}
              onTacticUnsave={onTacticUnsave}
              onTacticDelete={onTacticDelete}
              onScrollEnd={onFeedScrollEnd}
              onTacticEdit={onTacticEdit}
              onMemberAssign={onMemberAssign}
              onMoveStatusChange={onMoveStatusChange}
              onTacticStatusChange={onTacticStatusChange}
              onTeamTacticEdit={onTeamTacticEdit}
              onTacticClone={onTacticClone}
            />
          </>
        ) : (
          <div className="no-tactics">
            {getDummyIcon()}
            <h1>{getDummyHeader()}</h1>
            <div className="disclaimer">
              {getDummyText()}
            </div>
            <div className="create-tactic-wrap">
              {props.feed === 'teamFeed' ? (
                <IconButton
                  title="Create Tactic"
                  size="big"
                  icon={<IconPlus/>}
                  onClick={onTacticCreate}
                />
              ) : (
                <IconButton
                  title="Go to the Main Feed"
                  size="big"
                  onClick={() => {
                    navigate('/mainFeed/' + params.game);
                  }}
                />
              )}
            </div>
          </div>
        )
      ) : null}
      <BackTop />
    </>
  )
}
