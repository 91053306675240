import React from "react";

import './ImageBlock.css';

class ImageBlock extends React.Component {

  availableTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
  ];
  maxSize = 5 * 1024 * 1024;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      id: Math.floor(Math.random() * 9999) + '_' + (new Date()).getTime(),
      onDrag: false,
    }
  }

  onFileInput = (e) => {
    console.log(e);

    const fileList = e.target.files;
    if (fileList.length === 0) {
      return;
    }
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (!this.availableTypes.includes(file.type)) {
        console.log('Wrong file extension'); // @todo Add errors
        continue;
      }
      if (file.size > this.maxSize) {
        console.log('Too big file'); // @todo Add errors
        continue;
      }
      const reader = new FileReader();
      reader.onloadend = () => this.onLoadEnd(reader.result, file.name);
      reader.readAsDataURL(file);
    }
  }

  onDrop = (e) => {
    e.preventDefault();
    console.log(e);

    let file = null;
    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          file = e.dataTransfer.items[i].getAsFile();
          console.log(file);
        }
      }
    } else {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        file = e.dataTransfer.files;
        console.log(file);
      }
    }

    if (file !== null) {

      if (!this.availableTypes.includes(file.type)) {
        console.log('Wrong file extension'); // @todo Add errors
        return;
      }
      if (file.size > this.maxSize) {
        console.log('Too big file'); // @todo Add errors
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => this.onLoadEnd(reader.result, file.name);
      reader.readAsDataURL(file);
    }
  }

  onLoadEnd = (imageData, fileName) => {
    this.props.axiosInstance.post('/api/upload/tactic-file', { imageData })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          return '';
        }
        this.props.onChange({
          type: 'image',
          value: {
            src: response.data.url,
          }
        });
      }).catch(error => console.log(error));
  }

  onDragEnter = (e) => {
    this.setState({
      onDrag: true,
    })
  }

  onDragLeave = (e) => {
    this.setState({
      onDrag: false,
    })
  }

  render() {
    return (
      <div className={"image-block " + (this.state.onDrag ? ' on-drag ' : '') + (this.props.isMove ? ' is-move ' : '')}>
        {this.props.value.src ? (
          <img src={this.props.value.src} />
        ) : (
          <label
            htmlFor={"image_upload_" + this.state.id}
            className="dropzone"
            onDrop={this.onDrop}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
          >
            <div className="label">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M54.375 3.75H5.625C2.57812 3.75 0 6.32812 0 9.375V50.625C0 53.6719 2.57812 56.25 5.625 56.25H54.375C57.4219 56.25 60 53.6719 60 50.625V9.375C60 6.32812 57.4219 3.75 54.375 3.75ZM13.125 11.25C16.1719 11.25 18.75 13.8281 18.75 16.875C18.75 19.9219 16.1719 22.5 13.125 22.5C10.0781 22.5 7.5 19.9219 7.5 16.875C7.5 13.8281 10.0781 11.25 13.125 11.25ZM52.2656 47.8125C51.9141 48.3984 51.3281 48.75 50.625 48.75H9.60938C8.90625 48.75 8.20312 48.3984 7.96875 47.6953C7.61719 47.1094 7.73438 46.2891 8.08594 45.8203L16.2891 34.5703C16.6406 33.9844 17.2266 33.75 17.8125 33.75C18.3984 33.75 18.9844 33.9844 19.3359 34.5703L23.0859 39.7266L34.1016 23.3203C34.4531 22.8516 35.0391 22.5 35.625 22.5C36.2109 22.5 36.7969 22.8516 37.1484 23.3203L52.1484 45.8203C52.6172 46.4062 52.6172 47.1094 52.2656 47.8125Z" fill="#AAC4F8"/>
                <path d="M52.265 47.8125C51.9134 48.3984 51.3275 48.75 50.6244 48.75H9.60876C8.90563 48.75 8.20251 48.3984 7.96813 47.6953C7.61657 47.1094 7.73376 46.2891 8.08532 45.8203L16.2884 34.5703C16.64 33.9844 17.2259 33.75 17.8119 33.75C18.3978 33.75 18.9838 33.9844 19.3353 34.5703L23.0853 39.7266L34.1009 23.3203C34.4525 22.8516 35.0384 22.5 35.6244 22.5C36.2103 22.5 36.7963 22.8516 37.1478 23.3203L52.1478 45.8203C52.6166 46.4062 52.6166 47.1094 52.265 47.8125Z" fill="#AAC4F8"/>
              </svg>
              <span className="label-text">
              <div className="bold">Drop the file here or browse ...</div>
              <div className="regular">Allowed file formats  .JPG, .PNG</div>
            </span>
            </div>
            <input
              onChange={this.onFileInput}
              style={{display: 'none'}}
              id={"image_upload_" + this.state.id}
              type="file"
              accept=".jpg, .jpeg, .png"
            />
          </label>
        ) }
      </div>
    );
  }
}

export default ImageBlock;
