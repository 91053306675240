import React from "react";

import './TextareaInput.css';

class TextareaInput extends React.Component {

  constructor(props) {
    super(props);
    this.textarea = React.createRef();
    this.state = {
      focus: false,
    };
  }

  componentDidMount() {
    this.autoHeight();
    this.textarea.current.setAttribute("style", "height:" + (this.textarea.current.scrollHeight) + "px;overflow-y:hidden;");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.autoHeight();
  }

  autoHeight = () => {
    this.textarea.current.style.height = "auto";
    this.textarea.current.style.height = (this.textarea.current.scrollHeight + 1) + "px";
  }

  render() {
    return (
      <div className={"textarea-input " + (this.state.focus ? 'focused ' : '') + (this.props.className || '')}>
        <div className="textarea-input-background"/>
        <label>{this.props.label}</label>
        <textarea
          rows="1"
          ref={this.textarea}
          value={this.props.value || ''}
          onChange={(e) => {
            this.autoHeight();
            this.props.onChange(e.target.value);
          }}
          onFocus={() => this.setState({ focus: true })}
          onBlur={() => this.setState({ focus: false })}
        />
      </div>
    );
  }
}

export default TextareaInput;
