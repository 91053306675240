import React from "react";
import { Link } from "react-router-dom";

import './Tactic.css';
import {Avatar, Button, Col, Dropdown, Menu, Modal, Radio, Row, Space, Tooltip, notification, BackTop} from "antd";
import moment from "moment";
import IconButton from "../../ui/IconButton/IconButton";
import IconCheck from "../../ui/Icons/IconCheck";
import IconSave from "../../ui/Icons/IconSave";
import TextInput from '../../ui/Input/TextInput';
import env from "react-dotenv";

class Tactic extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filters: null,
      tactic: null,
      team: null,
      toDelete: false,
      toClone: false,
      toUnsave: false,
      isCloning: false,
      toCloneMoveIndex: false,
      teams: [],
      selectedTeamHash: props.teamHash,
      selectedTeamToCloneMoveHash: false,
      selectedTacticToCloneMoveHash: false,
      tacticsToCloneMove: [],
      tacticSearchText: '',
      availableStatuses: [
        {
          status: 'to_do',
          label: 'To do'
        },
        {
          status: 'in_progress',
          label: 'In progress'
        },
        {
          status: 'done',
          label: 'Done'
        },
      ],
      moveRefs: [],
    };
  }

  searchTimerId = 0;

  componentDidMount() {
    this.loadTactic();
    let url = '/api/filters/' + this.props.game;
    if (this.isTeamFeed()) {
      url = '/api/team-filters/' + this.props.teamHash;
    }
    this.props.axiosInstance.get(url)
      .then((response) => {
        this.setState({
          filters: response.data,
        });
      }).catch(error => console.log(error));
    if (this.props.user) {
      this.loadTeams();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user && !prevProps.user) {
      this.loadTactic();
      this.loadTeams();
    }
    if (this.props.forceReload && !prevProps.forceReload) {
      this.loadTactic();
    }
    if (this.state.selectedTeamToCloneMoveHash && !prevState.selectedTeamToCloneMoveHash) {
      this.loadTacticsToCloneMove();
    }
    if (this.state.tacticSearchText !== prevState.tacticSearchText) {
      if (this.searchTimerId) {
        clearTimeout(this.searchTimerId);
      }
      this.searchTimerId = setTimeout(this.loadTacticsToCloneMove, 500);
    }
  }

  loadTeams = () => {
    if (this.props.user) {
      this.props.axiosInstance.get('/api/teams')
        .then((response) => {
          this.setState({
            teams: response.data.teams
          });
        }).catch(error => console.log(error));
    }
  }

  loadTactic = () => {
    let url = '/api/tactics/' + this.props.game + '/get/' + this.props.hash;
    if (this.isTeamFeed()) {
      url = '/api/team-tactics/' + this.props.teamHash + '/get/' + this.props.hash;
      if (!this.props.user) {
        return;
      }
    }
    this.props.axiosInstance.get(url)
      .then((response) => {
        this.setState({
          tactic: response.data.tactic,
          moveRefs: [],
        }, () => {
          this.setState({
            moveRefs: this.getMoves().map(() => { return React.createRef() })
          });
        })
      }).catch(error => console.log(error));
    if (this.isTeamFeed()) {
      this.props.axiosInstance.get('/api/team/' + this.props.teamHash)
        .then((response) => {
          this.setState({
            team: response.data,
          })
        }).catch(error => console.log(error));
    }
  }

  loadTacticsToCloneMove = () => {
    let url = '/api/team-tactics/' + this.state.selectedTeamToCloneMoveHash;
    this.props.axiosInstance.get(url, { params: {
      searchPhrase: this.state.tacticSearchText,
      except: this.state.tactic.hash,
      limit: 10
    } })
      .then((response) => {
        this.setState({
          tacticsToCloneMove: response.data.tactics,
          selectedTacticToCloneMoveHash: false
        });
      }).catch((error) => console.log(error));
  }

  onTacticSave = () => {
    let tactic = JSON.parse(JSON.stringify(this.state.tactic));
    tactic.saved = true;
    this.setState({ tactic }, () => {
      this.props.axiosInstance.post('/api/tactics/' + this.props.game + '/save', { hash: tactic.hash })
        .then((response) => {
          tactic.saves = response.data.saves;
          this.setState({ tactic });
        }).catch(error => console.log(error));
    })
  }

  onLike = () => {
    let tactic = JSON.parse(JSON.stringify(this.state.tactic));
    tactic.liked = true;
    this.setState({ tactic }, () => {
      this.props.axiosInstance.post('/api/tactics/' + this.props.game + '/like', { hash: tactic.hash })
        .then((response) => {
          tactic.likes = response.data.likes;
          this.setState({ tactic });
        }).catch(error => console.log(error));
    })
  }

  onUnlike = () => {
    let tactic = JSON.parse(JSON.stringify(this.state.tactic));
    tactic.liked = false;
    this.setState({ tactic }, () => {
      this.props.axiosInstance.post('/api/tactics/' + this.props.game + '/unlike', { hash: tactic.hash })
          .then((response) => {
            tactic.likes = response.data.likes;
            this.setState({ tactic });
          }).catch(error => console.log(error));
    })
  }

  isTeamOwner = (tactic) => {
    return this.props.user
      && tactic.teamData
      && tactic.teamData.members.filter((member) => member.isAdmin)[0].hash === this.props.user.hash;
  }

  getFeedName = () => {
    return {
      mainFeed: 'Main Feed',
      teamFeed: 'Team Feed',
      saved: 'Saved',
    }[this.props.feed] || '';
  }

  getGameData = () => {
    return {
      csgo: {
        icon: '/img/csgo_icon.svg',
        name: 'CS:GO',
      }
    }[this.props.game || this.state.tactic.game] || {
      icon: null,
      name: '',
    };
  }

  getParamName = (param, value) => {
    if (!this.state.filters) {
      return '';
    }
    return this.state.filters.find((filter) => {
      return filter.name === param;
    }).options.find((option) => {
      return option.value === value;
    }).name;
  }

  getMoves = () => {
    if (!this.state.tactic) {
      return [];
    }
    return this.state.tactic.content.blocks.filter((block) => block.type === 'move');
  }

  getMoveIndex = (blockIndex) => {
    return this.state.tactic.content.blocks.reduce((accumulator, block, index) => {
      return index > blockIndex || block.type !== 'move' ? accumulator : ++accumulator;
    }, -1);
  }

  isTeamFeed = () => {
    return this.props.feed === 'teamFeed';
  }

  isPersonal = () => {
    return this.props.feed === 'saved';
  }

  isMainFeed = () => {
    return this.props.feed === 'mainFeed';
  }

  onDeleteModalOk = () => {
    let url = '/api/tactics/' + this.props.game + '/delete';
    if (this.isTeamFeed()) {
      url = '/api/team-tactics/' + this.props.teamHash + '/delete';
    }
    let message = 'Tactic ' + this.state.tactic.name + ' was removed from the Main Feed';
    if (this.isTeamFeed()) {
      const team = this.getAvailableTeamsToClone(this.state.tactic).find((team) => team.hash === this.props.teamHash );
      message = 'Tactic ' + this.state.tactic.name + ' was removed from the Team Feed ' + team.name;
    }

    this.props.axiosInstance.post(url, { hash: this.state.tactic.hash })
      .then(() => {
        if (this.isTeamFeed()) {
          this.props.navigate('/' + this.props.feed + '/' + this.props.teamHash);
        } else {
          this.props.navigate('/' + this.props.feed + '/' + this.props.game);
        }
        notification.error({
          message: message,
        });
      }).catch(error => console.log(error));
  }

  onDeleteModalCancel = () => {
    this.setState({ toDelete: false });
  }

  onTacticStatusChange = (status) => {
    this.props.axiosInstance.post(
      '/api/team-tactics/' + this.props.teamHash + '/' + this.props.hash + '/status-change',
      { status }
    ).then(() => {
      this.loadTactic();
    }).catch(error => console.log(error));
  }

  onMoveStatusChange = (status, moveIndex) => {
    this.props.axiosInstance.post(
      '/api/team-tactics/' + this.props.teamHash + '/' + this.props.hash + '/move-status-change',
      { status, moveIndex }
    ).then(() => {
      this.loadTactic();
    }).catch(error => console.log(error));
  }

  onMemberAssign = (member, moveIndex) => {
    this.props.axiosInstance.post(
      '/api/team-tactics/' + this.props.teamHash + '/' + this.props.hash + '/assign',
      { hash: member.hash, moveIndex }
    ).then(() => {
      this.loadTactic();
    }).catch(error => console.log(error));
  }

  onCloneModalOk = () => {
    if (!this.state.selectedTeamHash) {
      return;
    }
    let url = this.isTeamFeed()
      ? '/api/team-tactics/' + this.props.teamHash + '/clone'
      : '/api/tactics/' + this.props.game + '/clone';

    let message = 'Tactic ' + this.state.tactic.name + ' was cloned to Your Feed';
    if (this.state.selectedTeamHash !== 'personal') {
      const team = this.getAvailableTeamsToClone(this.state.tactic).find((team) => team.hash === this.state.selectedTeamHash );
      message = 'Tactic ' + this.state.tactic.name + ' was cloned to the Team Feed ' + team.name;
    }

    this.props.axiosInstance.post(url, {
      hash: this.state.tactic.hash,
      targetTeamHash: this.state.selectedTeamHash
    })
      .then(() => {
        notification.success({
          message: message,
        });
        this.setState({ toClone: null, selectedTeamHash: this.props.teamHash });
      }).catch(error => console.log(error));
  }

  onCloneModalCancel = () => {
    this.setState({ toClone: false });
  }

  onCloneMoveModalOk = () => {
    if (!this.state.selectedTacticToCloneMoveHash) {
      return;
    }
    this.setState({
      isCloning: true,
    }, () => {
      let url = '/api/team-tactics/' + this.props.teamHash + '/clone-move';
      if (!this.isTeamFeed()) {
        url = '/api/tactics/' + this.props.game + '/clone-move';
      }
      this.props.axiosInstance.post(url, {
        hash: this.state.tactic.hash,
        targetHash: this.state.selectedTacticToCloneMoveHash,
        moveIndex: this.state.toCloneMoveIndex,
      })
      .then(() => {
        this.onCloneMoveModalCancel();
      }).catch(error => console.log(error));
    });
  }

  onCloneMoveModalCancel = () => {
    this.setState({
      toCloneMoveIndex: false,
      selectedTeamToCloneMoveHash: false,
      selectedTacticToCloneMoveHash: false,
      tacticsToCloneMove: [],
      tacticSearchText: '',
      isCloning: false,
    });
  }

  onSaveModalOk = () => {
    if (!this.state.selectedTeamHash) {
      return;
    }
    let message = 'Tactic ' + this.state.tactic.name + ' was saved to Your Feed';
    if (this.state.selectedTeamHash !== 'personal') {
      const team = this.getAvailableTeamsToClone(this.state.tactic).find((team) => team.hash === this.state.selectedTeamHash );
      message = 'Tactic ' + this.state.tactic.name + ' was saved to the Team Feed ' + team.name;
    }

    this.props.axiosInstance.post('/api/tactics/' + this.props.game + '/save', {
      hash: this.state.tactic.hash,
      targetTeamHash: this.state.selectedTeamHash
    })
      .then((response) => {
        let tactic = JSON.parse(JSON.stringify(this.state.tactic));
        tactic.saved = true;
        tactic.saves = response.data.saves;
        tactic.savedData = response.data.tactic.savedData;
        this.setState({
          toSave: null,
          selectedTeamHash: null,
          tactic
        });
        notification.success({
          message: message,
        });
      }).catch(error => console.log(error));
  }

  onSaveModalCancel = () => {
    this.setState({ toSave: null });
  }

  onUnsaveModalOk = () => {
    let message = 'Tactic ' + this.state.tactic.name + ' was removed from Your Feed';
    if (!this.state.tactic.savedData.isPersonal) {
      message = 'Tactic ' + this.state.tactic.name + ' was removed from the Team Feed ' + this.state.tactic.savedData.teamName;
    }
    this.props.axiosInstance.post('/api/tactics/' + this.props.game + '/unsave', { hash: this.state.tactic.hash })
      .then((response) => {
        let tactic = JSON.parse(JSON.stringify(this.state.tactic));
        tactic.saved = false;
        tactic.saves = response.data.saves;
        delete(tactic.savedData);
        this.setState({
          toUnsave: false,
          tactic
        });
        notification.error({ message });
      }).catch(error => console.log(error));
  }

  onUnsaveModalCancel = () => {
    this.setState({ toUnsave: false });
  }

  getAvailableTeamsToClone = (tactic) => {
    return this.state.teams.filter((team) => team.game === tactic.game);
  }

  renderPlayerIcon = () => {
    return (
      <span className="player-icon">
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25Z" fill="#828282"/>
        </svg>
      </span>
    );
  }

  renderLikeButton = () => {
    // @todo to specific component
    const tactic = this.state.tactic;
    const liked = tactic.liked || false;
    const notLikedIcon = (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.04167 3.73333C8.37083 3.73333 7.72917 4.05417 7.32083 4.4625L7 4.8125L6.67917 4.4625C6.27083 4.05417 5.62917 3.73333 4.95833 3.73333C4.52083 3.73333 4.025 3.87917 3.61667 4.25833C2.59583 5.13333 2.5375 6.7375 3.44167 7.7L6.62083 11.025C6.825 11.2583 7.175 11.2583 7.37917 11.025L10.5583 7.7C11.4625 6.7375 11.4042 5.13333 10.3833 4.25833C9.975 3.87917 9.47917 3.73333 9.04167 3.73333ZM9.8875 7.05833L7 10.0917L4.1125 7.05833C3.61667 6.5625 3.52917 5.54167 4.2 4.95833C4.4625 4.75417 4.725 4.66667 4.9875 4.66667C5.39583 4.66667 5.775 4.87083 6.00833 5.13333L7 6.15417L7.99167 5.13333C8.225 4.87083 8.60417 4.66667 9.0125 4.66667C9.275 4.66667 9.5375 4.75417 9.77083 4.95833C10.4708 5.54167 10.3833 6.5625 9.8875 7.05833ZM7 0C3.12083 0 0 3.12083 0 7C0 10.8792 3.12083 14 7 14C10.8792 14 14 10.8792 14 7C14 3.12083 10.8792 0 7 0ZM7 13.0667C3.64583 13.0667 0.933333 10.3542 0.933333 7C0.933333 3.64583 3.64583 0.933333 7 0.933333C10.3542 0.933333 13.0667 3.64583 13.0667 7C13.0667 10.3542 10.3542 13.0667 7 13.0667Z" fill="#2A6CED"/>
      </svg>
    );
    const likedIcon = (
      <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.61668 4.69979L5.37918 8.02479C5.17502 8.25812 4.82502 8.25812 4.62085 8.02479L1.41252 4.69979C0.479183 3.7373 0.508349 2.13313 1.55835 1.25813C2.49168 0.470636 3.83335 0.616469 4.67918 1.4623L5.00002 1.8123L5.35002 1.4623C6.16668 0.616469 7.53752 0.470636 8.44168 1.25813C9.49168 2.13313 9.55002 3.7373 8.61668 4.69979Z" fill="#D078FF"/>
      </svg>
    );
    return (
      <button
        className={"like-button " + (liked ? 'liked' : 'not-liked')}
        onClick={() => {
          if (this.props.user) {
            if (!liked) {
              this.onLike();
            } else {
              this.onUnlike();
            }
          } else {
            notification.info({
              message: (
                <span>
                  You can vote on tactics to help everyone find the best content with a Varcharr account. By continuing, you agree to our User Agreement and Privacy Policy.
                </span>
              ),
              btn: (
                <Button type="primary" onClick={() => { window.location.href = env.API_URL + '/auth/steam/redirect' }}>
                  Login via Steam
                </Button>
              ),
              duration: 9,
            });
          }
        }}
      >
        <span className="icon-wrap">
          {liked ? likedIcon : notLikedIcon}
        </span>
        {liked ? 'Liked' : 'Like'}
      </button>
    );
  }

  renderSaveButton = () => {
    // @todo to specific component
    const tactic = this.state.tactic;
    const saved = tactic.saved || false;
    return (
      <span className={"save-button-wrap " + (saved ? 'saved' : 'not-saved')}>
        <IconButton
          title={saved ? "Saved" : "Save Tactic"}
          size="big"
          cantPress={saved}
          icon={saved ? <IconCheck /> : <IconSave />}
          onClick={() => {
            if (!this.props.user) {
              notification.info({
                message: (
                  <span>
                    You can save any tactic to read later or share with your team with a Varcharr account. By continuing, you agree to our User Agreement and Privacy Policy.
                  </span>
                ),
                btn: (
                  <Button type="primary" onClick={() => { window.location.href = env.API_URL + '/auth/steam/redirect' }}>
                    Login via Steam
                  </Button>
                ),
                duration: 9,
              });
              return;
            }
            if (!saved) {
              this.setState({
                toSave: true,
                toUnsave: false,
              });
            } else {
              this.setState({
                toSave: false,
                toUnsave: true,
              });
            }
          }}
        />
      </span>
    );
  }

  renderTextBlock = (value, index) => {
    return (
      <p key={"block-" + index} className="block-text">{value}</p>
    );
  }

  renderImageBlock = (value, index) => {
    return (
      <img key={"block-" + index} className="block-image" src={value.src} />
    );
  }

  renderYoutubeBlock = (value, index, isMove = false) => {
    return (
      <iframe
        key={"block-" + index}
        width={isMove ? "704" : "820"}
        height={isMove ? "396" : "461"}
        src={"https://www.youtube.com/embed/" + value.id}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }

  renderGifBlock = (value, index, isMove) => {
    if (value.src) {
      return (
        <img key={"block-" + index} className="block-image" src={value.src} />
      );
    }
    if (value.id) {
      return (
        <iframe
          key={"block-" + index}
          src={'https://gfycat.com/ifr/' + value.id + '?autoplay=0'}
          frameBorder='0'
          scrolling='no'
          allowFullScreen
          width={isMove ? "704" : "820"}
          height={isMove ? "444" : "518"}
        />
      );
    }

    return null;
  }

  renderMoveDropdown = (moveIndex) => {
    return (
      <Dropdown
        placement="bottomRight"
        overlay={(
          <Menu className="app-dropdown move-action-select">
            <Menu.Item
              key="clone-move"
              className="move-action-select-item"
              onClick={() => { this.setState({ toCloneMoveIndex: moveIndex }) }}
            >
              <span>Clone move</span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <span className="move-action-select-dots">
          <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.75 0.25C0.792969 0.25 0 1.04297 0 2C0 2.95703 0.792969 3.75 1.75 3.75C2.70703 3.75 3.5 2.95703 3.5 2C3.5 1.04297 2.70703 0.25 1.75 0.25ZM7 0.25C6.04297 0.25 5.25 1.04297 5.25 2C5.25 2.95703 6.04297 3.75 7 3.75C7.95703 3.75 8.75 2.95703 8.75 2C8.75 1.04297 7.95703 0.25 7 0.25ZM12.25 0.25C11.293 0.25 10.5 1.04297 10.5 2C10.5 2.95703 11.293 3.75 12.25 3.75C13.207 3.75 14 2.95703 14 2C14 1.04297 13.207 0.25 12.25 0.25Z" fill="#333333"/>
          </svg>
        </span>
      </Dropdown>
    );
  }

  renderMove = (value, index, moveIndex) => {
    let teamDataMove = {};
    let statusClass = '';
    if (this.isTeamFeed()) {
      teamDataMove = this.state.tactic.teamData.moves[moveIndex];
      statusClass = teamDataMove.status;
    }
    return (
      <div ref={this.state.moveRefs[moveIndex]} key={"block-" + index} className="block-move">
        <Row>
          <Col flex="30px" className="left-column">
            <span className={"move-number-label " + statusClass}>{moveIndex + 1}</span>
          </Col>
          <Col flex="auto">
            <div className="move-content">
              <b>{value.name}</b>
              {value.summary ? (<p className="move-summary">{value.summary}</p>) : null}
              {value.blocks.map((block, subIndex) => {
                const key = 'block-' + index + '-' + subIndex;
                switch (block.type) {
                  case 'text':
                    return this.renderTextBlock(block.value, key);
                  case 'image':
                    return this.renderImageBlock(block.value, key);
                  case 'youtube':
                    return this.renderYoutubeBlock(block.value, key, true);
                  case 'gif':
                    return this.renderGifBlock(block.value, key, true);
                }
                return null;
              })}
            </div>
          </Col>
          <Col flex="140px" className="right-column">
            {this.isTeamFeed() ? (
              <div className="assignee-data">
                {this.renderStatusDropdown(teamDataMove.status, (status) => { this.onMoveStatusChange(status, moveIndex) })}
                <Tooltip
                  key={'assignee_' + index}
                  overlayClassName="app-tooltip"
                  mouseEnterDelay={1}
                  title={teamDataMove.assignee ? teamDataMove.assignee.name : 'Click to assign'}
                >
                  <Dropdown
                    placement="bottomRight"
                    overlay={(
                      <Menu className="member-assign-select">
                        {this.state.tactic.teamData.members.filter((member) => member.status === 'active').map((member) => {
                          return (
                            <Menu.Item
                              key={member.hash}
                              className="member-assign-select-item"
                              onClick={() => this.onMemberAssign(member, moveIndex) }
                            >
                              <div
                                className="member-avatar"
                                style={member.avatar ? { backgroundImage: 'url("' + member.avatar + '")' } : {}}
                              >
                                {member.avatar ? null : (
                                  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25Z" fill="#828282"/>
                                  </svg>
                                )}
                              </div>
                              <span>{member.name}</span>
                            </Menu.Item>
                          );
                        })}
                      </Menu>
                    )}
                    trigger={['click']}
                  >
                    <div
                      className="member-avatar"
                      style={(teamDataMove.assignee && teamDataMove.assignee.avatar) ? { backgroundImage: 'url("' + teamDataMove.assignee.avatar + '")' } : {}}
                    >
                      {(teamDataMove.assignee && teamDataMove.assignee.avatar) ? null : (
                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25Z" fill="#828282"/>
                        </svg>
                      )}
                    </div>
                  </Dropdown>
                </Tooltip>
                {this.renderMoveDropdown(moveIndex)}
              </div>
            ) : (
              <div className="player-data">
                {this.renderPlayerIcon()}
                Player #{value.player}
                {this.props.user ? (
                  this.renderMoveDropdown(moveIndex)
                ) : null}
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  /** @todo Move to separate component */
  renderStatusDropdown = (status, callback, position = 'bottomRight') => {
    const currentStatus = this.state.availableStatuses.filter((item) => item.status === status)[0];
    return (
      <Dropdown
        placement={position}
        overlay={(
          <Menu className="member-assign-select">
            {this.state.availableStatuses.map((item) => {
              return (
                <Menu.Item
                  key={item.status}
                  className="member-assign-select-item"
                  onClick={() => callback(item.status) }
                >
                  <span>{item.label}</span>
                </Menu.Item>
              );
            })}
          </Menu>
        )}
        trigger={['click']}
      >
        <span className={"status-dropdown " + currentStatus.status}>
          {currentStatus.label}
          <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.131956 0.703344L3.68537 3.87918C3.77791 3.96548 3.88896 4 4 4C4.11104 4 4.22209 3.96548 4.31463 3.87918L7.86804 0.703344C8.03461 0.548004 8.05312 0.289105 7.86804 0.116505C7.70148 -0.0388346 7.42387 -0.0388346 7.23879 0.116505L4 3.01618L0.761207 0.116505C0.576134 -0.0388346 0.298523 -0.0388346 0.131956 0.116505C-0.0531175 0.289105 -0.0346102 0.548004 0.131956 0.703344Z" fill="white"/>
          </svg>
        </span>
      </Dropdown>
    );
  }

  renderTactic = () => {
    const gameData = this.getGameData();
    const tactic = this.state.tactic;
    const moves = this.getMoves();

    const canEdit = this.props.user && (this.props.user.role === 'superadmin' || tactic.userIsAuthor || this.isTeamOwner(tactic));
    const canClone = !this.isMainFeed();
    const canDelete = canEdit;

    return (
      <div className={"tactic-view " + this.props.feed + (this.props.user ? '' : ' not-logged ')}>
        <div className="summary-data">
          <span>{this.getFeedName()}</span>
          <span><img src={gameData.icon}/>{gameData.name}</span>
          {this.state.team ? (
            <span>
              {this.state.team.icon ? (
                <img src={this.state.team.icon}/>
              ) : (
                <span className="no-team-icon">
                  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25ZM5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM8.8125 5.64844C9.21094 5.88281 9.65625 6 10.125 6C11.5781 6 12.75 4.82812 12.75 3.375C12.75 1.92188 11.5781 0.75 10.125 0.75C9.82031 0.75 9.53906 0.796875 9.25781 0.890625C9.04688 0.960938 8.95312 1.17188 9.02344 1.38281C9.09375 1.57031 9.30469 1.66406 9.49219 1.61719C9.70312 1.54688 9.91406 1.5 10.125 1.5C11.1562 1.5 12 2.34375 12 3.375C12 4.40625 11.1562 5.25 10.125 5.25C9.79688 5.25 9.46875 5.15625 9.1875 4.99219C9 4.89844 8.78906 4.94531 8.67188 5.13281C8.57812 5.32031 8.625 5.55469 8.8125 5.64844ZM11.5078 7.5H10.5C10.2891 7.5 10.125 7.66406 10.125 7.875C10.125 8.08594 10.2891 8.25 10.5 8.25H11.5078C13.0078 8.25 14.25 9.49219 14.25 10.9922C14.25 11.1328 14.1328 11.25 13.9922 11.25H11.625C11.4141 11.25 11.25 11.4141 11.25 11.625C11.25 11.8359 11.4141 12 11.625 12H13.9922C14.5547 12 15 11.5547 15 10.9922C15 9.07031 13.4297 7.5 11.5078 7.5Z" fill="#828282"/>
                </svg>
                </span>
              )}
              {this.state.team.name}
            </span>
          ) : null}
          <span>{this.getParamName('map', tactic.map)}</span>
          <span>Tickrate {this.getParamName('tickrate', tactic.tickrate)}</span>
          <span>{this.getParamName('side', tactic.side)}</span>
          <span>{this.getParamName('economics', tactic.economics)}</span>
          <span>{tactic.players} player{tactic.players !== 1 ? 's' : ''}</span>
          {this.props.user && (canEdit || canClone || canDelete) ? (
            <Dropdown
              placement="bottomRight"
              overlay={(
                <Menu className="feed-action-menu">
                  {canEdit ? (
                    <Menu.Item
                      key="edit"
                      onClick={() => { this.props.onTacticEditorOpen(tactic, 'edit') }}
                    >
                      <span>Edit</span>
                    </Menu.Item>
                  ) : null}
                  {canClone ? (
                    <Menu.Item
                      key="clone"
                      onClick={() => { this.setState({ toClone: true })} }
                    >
                      <span>Clone</span>
                    </Menu.Item>
                  ) : null}
                  {canDelete ? (
                    <Menu.Item
                      key="delete"
                      className="delete"
                      onClick={() => { this.setState({ toDelete: true })} }
                    >
                      <span>Delete</span>
                    </Menu.Item>
                  ) : null}
                </Menu>
              )}
              trigger={['click']}
            >
              <div className="actions">
                <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.75 0.25C0.792969 0.25 0 1.04297 0 2C0 2.95703 0.792969 3.75 1.75 3.75C2.70703 3.75 3.5 2.95703 3.5 2C3.5 1.04297 2.70703 0.25 1.75 0.25ZM7 0.25C6.04297 0.25 5.25 1.04297 5.25 2C5.25 2.95703 6.04297 3.75 7 3.75C7.95703 3.75 8.75 2.95703 8.75 2C8.75 1.04297 7.95703 0.25 7 0.25ZM12.25 0.25C11.293 0.25 10.5 1.04297 10.5 2C10.5 2.95703 11.293 3.75 12.25 3.75C13.207 3.75 14 2.95703 14 2C14 1.04297 13.207 0.25 12.25 0.25Z" fill="#828282"/>
                </svg>
              </div>
            </Dropdown>
          ) : null}
          <Modal
            title={'Delete Tactic'}
            visible={this.state.toDelete}
            className={'app-modal'}
            onOk={this.onDeleteModalOk}
            onCancel={this.onDeleteModalCancel}
            closeIcon={(
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
              </svg>
            )}
            footer={[
              <IconButton title="Delete" size="big" className="danger" onClick={this.onDeleteModalOk}/>
            ]}
          >
            {this.state.toDelete ? (
              <p>Are you sure you want to delete tactic "{this.state.tactic.name}"?</p>
            ) : null}
          </Modal>
          <Modal
            title={'Clone Tactic'}
            visible={this.state.toClone}
            className={'app-modal'}
            onOk={this.onCloneModalOk}
            onCancel={this.onCloneModalCancel}
            closeIcon={(
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
              </svg>
            )}
            footer={[
              !this.state.isCloning ? (
                <IconButton
                  title="Clone"
                  size="big"
                  cantPress={!this.state.selectedTeamHash}
                  onClick={this.onCloneModalOk}
                />
              ) : null
            ]}
          >
            <p>Сhoose a team feed to clone</p>
            <Radio.Group
              className="app-radio-group"
              onChange={(e) => {
                this.setState({
                  selectedTeamHash: e.target.value
                })
              }}
              value={this.state.selectedTeamHash}
            >
              {this.state.toClone ? (
                <Space direction="vertical">
                  {this.getAvailableTeamsToClone(this.state.tactic).map((team) => {
                    return (
                      <Radio
                        value={team.hash}
                        key={team.hash}
                      >
                        Team Feed <b>{team.name}</b>
                      </Radio>
                    );
                  })}
                </Space>
              ) : null}
            </Radio.Group>
          </Modal>
          <Modal
            title={'Save Tactic'}
            visible={this.state.toSave}
            className={'app-modal'}
            onOk={this.onSaveModalOk}
            onCancel={this.onSaveModalCancel}
            closeIcon={(
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
              </svg>
            )}
            footer={[
              !this.state.isCloning ? (
                <IconButton
                  title="Save"
                  size="big"
                  cantPress={!this.state.selectedTeamHash}
                  onClick={this.onSaveModalOk}
                />
              ) : null
            ]}
          >
            <p>Сhoose a team feed to save</p>
            <Radio.Group
              className="app-radio-group"
              onChange={(e) => {
                this.setState({
                  selectedTeamHash: e.target.value
                })
              }}
              value={this.state.selectedTeamHash}
            >
              {this.state.toSave ? (
                <Space direction="vertical">
                  {this.getAvailableTeamsToClone(this.state.tactic).map((team) => {
                    return (
                      <Radio
                        value={team.hash}
                        key={team.hash}
                      >
                        Team Feed <b>{team.name}</b>
                      </Radio>
                    );
                  })}
                  <Radio
                    value='personal'
                    key='personal'
                  >
                    My Feed
                  </Radio>
                </Space>
              ) : null}
            </Radio.Group>
          </Modal>
          <Modal
            title={'Unsave Tactic'}
            visible={this.state.toUnsave}
            className={'app-modal'}
            onOk={this.onUnsaveModalOk}
            onCancel={this.onUnsaveModalCancel}
            closeIcon={(
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
              </svg>
            )}
            footer={[
              <IconButton title="Ok" size="big" className="danger" onClick={this.onUnsaveModalOk}/>
            ]}
          >
            <p>Are you sure to unsave this tactic? This tactic will be removed from your feed.</p>
          </Modal>
          <Modal
              title={'Clone Move'}
              visible={this.state.toCloneMoveIndex !== false}
              width={900}
              className={'app-modal clone-move-modal'}
              onOk={this.onCloneMoveModalOk}
              onCancel={this.onCloneMoveModalCancel}
              closeIcon={(
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.60854 0L0.0234375 0.614357L7.05738 8L0.0234375 15.3856L0.60854 16L7.64249 8.61436L14.6764 16L15.2615 15.3856L8.22759 8L15.2615 0.614357L14.6764 0L7.64249 7.38564L0.60854 0Z" fill="#828282"/>
                  </svg>
              )}
              footer={[
                this.state.selectedTeamToCloneMoveHash && !this.state.isCloning ? (
                  <IconButton
                    title="Clone"
                    size="big"
                    cantPress={!this.state.selectedTacticToCloneMoveHash}
                    onClick={this.onCloneMoveModalOk}
                  />
                ) : null
              ]}
          >
            {!this.state.selectedTeamToCloneMoveHash ? (
              <>
                <p>Сhoose a team</p>
                <Radio.Group
                    className="app-radio-group"
                    onChange={(e) => {
                      this.setState({
                        selectedTeamToCloneMoveHash: e.target.value
                      })
                    }}
                    value={this.state.selectedTeamToCloneMoveHash}
                >
                  <Space direction="vertical">
                    {this.getAvailableTeamsToClone(this.state.tactic).map((team) => {
                      return (
                          <Radio
                              value={team.hash}
                              key={team.hash}
                          >
                            Team <b>{team.name}</b>
                          </Radio>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </>
            ) : (
              <>
                <p>Сhoose a tactic</p>
                <TextInput
                    value={this.state.tacticSearchText}
                    label="Search tactic"
                    onChange={(tacticSearchText) => {
                      this.setState({
                        tacticSearchText
                      }, () => {

                      });
                    }}
                />
                <div className="tactics-to-clone-wrapper">
                  {this.state.tacticsToCloneMove.length === 0 ? (
                    <div className="no-tactics">
                      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_782_5583)">
                          <path opacity="0.4" d="M50.0002 21.875C56.0549 21.875 60.9377 16.9922 60.9377 10.9375C60.9377 4.88281 56.0549 0 50.0002 0C43.9455 0 39.0627 4.88281 39.0627 10.9375C39.0627 16.9922 43.9455 21.875 50.0002 21.875ZM98.633 35.9375L92.969 32.8125C91.6018 31.8359 89.844 32.4219 88.6721 33.7891C86.5237 36.7188 83.008 38.0859 79.4924 37.3047C75.0002 36.5234 71.8752 32.2266 71.8752 27.5391C71.8752 26.1719 70.7034 25 69.3362 25H61.719C60.5471 25 59.3752 25.7812 59.1799 26.9531C58.2034 31.25 54.4924 34.375 50.0002 34.375C45.508 34.375 41.7971 31.25 40.8205 26.9531C40.6252 25.7812 39.4534 25 38.2815 25H30.6643C29.2971 25 28.1252 26.1719 28.1252 27.5391C28.1252 32.6172 24.4143 37.1094 19.3362 37.5C16.2112 37.6953 13.0862 36.3281 11.3284 33.7891C10.1565 32.4219 8.39867 31.8359 7.03148 32.8125L1.36742 35.9375C0.0002289 36.9141 -0.390396 38.6719 0.390854 40.0391L20.3127 81.25H79.6877L99.6096 40.0391C100.391 38.4766 100 36.9141 98.633 35.9375Z" fill="#2A6CED"/>
                          <path d="M87.5 96.875C87.5 98.6328 86.1328 100 84.375 100H15.625C13.8672 100 12.5 98.6328 12.5 96.875C12.5 92.7734 15.0391 89.4531 18.75 88.0859V84.375C18.75 82.6172 20.1172 81.25 21.875 81.25H78.125C79.8828 81.25 81.25 82.6172 81.25 84.375V88.0859C84.9609 89.4531 87.5 92.7734 87.5 96.875Z" fill="#2A6CED"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_782_5583">
                            <rect width="100" height="100" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                      <h1>No Tactics</h1>
                    </div>
                  ) : null}
                  {this.state.tacticsToCloneMove.map((tactic) => {
                    return (
                        <div
                            className={"tactic " + (this.state.selectedTacticToCloneMoveHash === tactic.hash ? 'selected' : '')}
                            onClick={() => {
                              this.setState({
                                selectedTacticToCloneMoveHash: this.state.selectedTacticToCloneMoveHash === tactic.hash ? false : tactic.hash
                              })
                            }}
                        >
                          <Row>
                            <Col>
                              <span className="image" style={{ backgroundImage: 'url("' + tactic.main_image + '")' }} />
                            </Col>
                            <Col>
                              <div className="name">{tactic.name}</div>
                              <div className="summary-data">
                                <span>{this.getParamName('map', tactic.map)}</span>
                                <span>Tickrate {this.getParamName('tickrate', tactic.tickrate)}</span>
                                <span>{this.getParamName('side', tactic.side)}</span>
                                <span>{this.getParamName('economics', tactic.economics)}</span>
                                <span>{tactic.players} player{tactic.players !== 1 ? 's' : ''}</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                    );
                  })}
                </div>
              </>
            )}
          </Modal>
        </div>
        <h1>{tactic.name}</h1>
        <div className="stats-row">
          <div className="author-wrap">
            <Avatar size={20} src={tactic.author.avatar} />
            <Link className="author-name" to={'/author/' + tactic.author.name + '/csgo'}>{tactic.author.name}</Link>
            <span className="date"> by {moment(tactic.created_at).format("D MMM'YY")}</span>
          </div>
          {!this.isPersonal() ? (
            <div className="stats">
              <span className="stat">
                <Tooltip overlayClassName="app-tooltip" mouseEnterDelay={1} title="Views">
                  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 2.66667C6.02778 2.66667 4.44444 4.25 4.44444 6.22222C4.44444 8.19444 6.02778 9.77778 8 9.77778C9.97222 9.77778 11.5556 8.19444 11.5556 6.22222C11.5556 4.25 9.97222 2.66667 8 2.66667ZM8 8.88889C6.52778 8.88889 5.33333 7.69444 5.33333 6.22222C5.33333 4.75 6.52778 3.55556 8 3.55556C9.47222 3.55556 10.6667 4.75 10.6667 6.22222C10.6667 7.69444 9.47222 8.88889 8 8.88889ZM15.9167 5.75C14.3889 2.33333 11.4167 0 8 0C4.58333 0 1.61111 2.33333 0.0833333 5.75C0.0555556 5.86111 0 6.08333 0 6.22222C0 6.36111 0.0555556 6.58333 0.0833333 6.69444C1.61111 10.1111 4.58333 12.4444 8 12.4444C11.4167 12.4444 14.3889 10.1111 15.9167 6.69444C15.9444 6.58333 16 6.36111 16 6.22222C16 6.08333 15.9444 5.86111 15.9167 5.75ZM15.0833 6.33333C13.6667 9.55556 10.9722 11.5556 8 11.5556C5.02778 11.5556 2.33333 9.55556 0.916667 6.36111C0.916667 6.33333 0.888889 6.25 0.888889 6.22222C0.888889 6.19444 0.916667 6.11111 0.916667 6.11111C2.33333 2.88889 5.02778 0.888889 8 0.888889C10.9722 0.888889 13.6667 2.88889 15.0833 6.08333C15.0833 6.11111 15.1111 6.19444 15.1111 6.22222C15.1111 6.25 15.0833 6.33333 15.0833 6.33333Z" fill="#AAC4F8"/>
                  </svg>
                  {tactic.views}
                </Tooltip>
              </span>
              {this.isMainFeed() ? (
                <>
                  <span className="stat">
                    <Tooltip overlayClassName="app-tooltip" mouseEnterDelay={1} title="Saves">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 9.625C7.10938 9.625 7.21875 9.57031 7.30078 9.48828L11.2383 5.55078C11.4297 5.38672 11.4297 5.11328 11.2383 4.94922C11.0469 4.78516 10.8008 4.75781 10.6367 4.94922L7.4375 8.12109L7.4375 0.4375C7.4375 0.191406 7.24609 0 7 0C6.75391 0 6.5625 0.191406 6.5625 0.4375L6.5625 8.12109L3.36328 4.94922C3.19922 4.75781 2.92578 4.75781 2.76172 4.94922C2.59766 5.14063 2.57031 5.38672 2.76172 5.55078L6.69922 9.48828C6.78125 9.57031 6.89063 9.625 7 9.625ZM13.125 11.8125V9.1875C13.125 8.94141 12.9336 8.75 12.6875 8.75C12.4414 8.75 12.25 8.94141 12.25 9.1875V11.8125C12.25 12.5234 11.6484 13.125 10.9375 13.125H3.0625C2.35156 13.125 1.75 12.5234 1.75 11.8125V9.1875C1.75 8.94141 1.55859 8.75 1.3125 8.75C1.06641 8.75 0.875 8.94141 0.875 9.1875V11.8125C0.875 13.0156 1.85938 14 3.0625 14H10.9375C12.1406 14 13.125 13.0156 13.125 11.8125Z" fill="#AAC4F8"/>
                      </svg>
                      {tactic.saves}
                    </Tooltip>
                  </span>
                      <span className="stat">
                    <Tooltip overlayClassName="app-tooltip" mouseEnterDelay={1} title="Likes">
                      <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.633 0.875022C11.1565 -0.410134 8.91429 -0.191384 7.5471 1.23049L7.00022 1.80471L6.45335 1.23049C5.3596 0.0820534 3.09007 -0.60154 1.36741 0.875022C-0.355246 2.32424 -0.437277 4.94924 1.09397 6.53518L6.37132 12.0039C6.56272 12.1953 6.78147 12.2774 7.00022 12.2774C7.21897 12.2774 7.43772 12.1953 7.62913 12.0039L12.9065 6.53518C14.4377 4.94924 14.3557 2.32424 12.633 0.875022ZM12.2776 5.93362L7.00022 11.4024L1.72288 5.93362C0.656473 4.86721 0.437723 2.78908 1.94163 1.53127C3.41819 0.246116 5.19554 1.1758 5.82444 1.8594L7.00022 3.06252L8.176 1.8594C8.80491 1.20315 10.5823 0.27346 12.0862 1.53127C13.5627 2.78908 13.344 4.86721 12.2776 5.93362Z" fill="#AAC4F8"/>
                      </svg>
                      {tactic.likes}
                    </Tooltip>
                  </span>
                </>
              ) : null}
            </div>
          ) : null}
          {this.isTeamFeed() ? (
            <div className="status-select-wrap">
              {this.renderStatusDropdown(tactic.teamData.status, (status) => { this.onTacticStatusChange(status) })}
            </div>
          ) : null}
          {this.isMainFeed() ? (
            <div className="buttons-wrap">
              <div className="buttons">
                {this.renderLikeButton()}
                {this.renderSaveButton()}
              </div>
            </div>
          ) : null}
        </div>
        {(!this.isTeamFeed() && moves.length > 0) ? (
          <div className="move-row">
            <h2>{moves.length} Move{moves.length > 1 ? 's' : ''}</h2>
            {moves.map((move, index) => {
              return (
                <div key={index} className="short-move">
                  <Row>
                    <Col flex="auto">
                      <p
                        className="move-name"
                        onClick={() => {
                          this.state.moveRefs[index].current.scrollIntoView({behavior: 'smooth'});
                        }}
                      >
                        {move.value.name}
                      </p>
                    </Col>
                    <Col flex="120px">
                      <p className="player-name">{this.renderPlayerIcon()} Player #{move.value.player}</p>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        ) : null}
        {(this.isTeamFeed() && tactic.teamData.moves.length > 0) ? (
          <div className="move-row moves">
            <h2>{tactic.teamData.moves.length} Move{tactic.teamData.moves.length > 1 ? 's' : ''}</h2>
            {tactic.teamData.moves.map((move, index) => {
              return (
                <Row key={index} className="move">
                  <Col flex="35px" className="left-column">
                    <Tooltip
                      key={'assignee_' + index}
                      overlayClassName="app-tooltip"
                      mouseEnterDelay={1}
                      title={move.assignee ? move.assignee.name : 'Click to assign'}
                    >
                      <Dropdown
                        placement="bottomLeft"
                        overlay={(
                          <Menu className="member-assign-select">
                            {tactic.teamData.members.map((member) => {
                              return (
                                <Menu.Item
                                  key={member.hash}
                                  className="member-assign-select-item"
                                  onClick={() => this.onMemberAssign(member, index) }
                                >
                                  <div
                                    className="member-avatar"
                                    style={member.avatar ? { backgroundImage: 'url("' + member.avatar + '")' } : {}}
                                  >
                                    {member.avatar ? null : (
                                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25Z" fill="#828282"/>
                                      </svg>
                                    )}
                                  </div>
                                  <span>{member.name}</span>
                                </Menu.Item>
                              );
                            })}
                          </Menu>
                        )}
                        trigger={['click']}
                      >
                        <span className={"member"} >
                          <div
                            className="member-avatar"
                            style={(move.assignee && move.assignee.avatar) ? { backgroundImage: 'url("' + move.assignee.avatar + '")' } : {}}
                          >
                            {(move.assignee && move.assignee.avatar) ? null : (
                              <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.25 6C6.91406 6 8.25 4.66406 8.25 3C8.25 1.33594 6.91406 0 5.25 0C3.58594 0 2.25 1.33594 2.25 3C2.25 4.66406 3.58594 6 5.25 6ZM5.25 0.75C6.49219 0.75 7.5 1.75781 7.5 3C7.5 4.24219 6.49219 5.25 5.25 5.25C4.00781 5.25 3 4.24219 3 3C3 1.75781 4.00781 0.75 5.25 0.75ZM6.44531 7.125H4.05469C1.82812 7.125 0 8.95312 0 11.1797C0 11.625 0.375 12 0.820312 12H9.67969C10.125 12 10.5 11.625 10.5 11.1797C10.5 8.95312 8.67188 7.125 6.44531 7.125ZM9.67969 11.25H0.820312C0.773438 11.25 0.75 11.2266 0.75 11.1797C0.75 9.35156 2.22656 7.875 4.05469 7.875H6.44531C8.27344 7.875 9.75 9.35156 9.75 11.1797C9.75 11.2266 9.72656 11.25 9.67969 11.25Z" fill="#828282"/>
                              </svg>
                            )}
                          </div>
                        </span>
                      </Dropdown>
                    </Tooltip>
                  </Col>
                  <Col flex="auto" className="middle-column">
                    <span
                      className="move-name"
                      onClick={() => {
                        this.state.moveRefs[index].current.scrollIntoView({behavior: 'smooth'});
                      }}
                    >
                      {move.name}
                    </span>
                  </Col>
                  <Col flex="auto" className="right-column">
                    {this.renderStatusDropdown(move.status, (status) => { this.onMoveStatusChange(status, index) })}
                  </Col>
                </Row>
              );
            })}
          </div>
        ) : null}
        {tactic.content.summary ? this.renderTextBlock(tactic.content.summary, -1) : null}
        {tactic.content.blocks.map((block, index) => {
          const moveIndex = this.getMoveIndex(index);
          switch (block.type) {
            case 'move':
              return this.renderMove(block.value, index, moveIndex);
            case 'text':
              return this.renderTextBlock(block.value, index);
            case 'image':
              return this.renderImageBlock(block.value, index);
            case 'youtube':
              return this.renderYoutubeBlock(block.value, index);
            case 'gif':
              return this.renderGifBlock(block.value, index);
          }
          return null;
        })}
        <div className="tags-wrap">
          {tactic.tags.map((tag) => {
            return (
              <Link
                to={'/' + this.props.feed + '/' + this.props.game + '?search=' + encodeURIComponent('#' + tag)}
                className="tag"
                key={tag}
              >
                #{tag}
              </Link>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="tactic-view-wrap">
        {this.state.tactic ? this.renderTactic() : null}
        <BackTop />
      </div>
    );
  }
}

export default Tactic;
