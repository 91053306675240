import React from "react";

import './TextInputBlock.css';

class TextInputBlock extends React.Component {

  render() {
    return (
      <div className={"text-input-block " + this.props.className}>
        <input
          type="text"
          placeholder={this.props.placeholder}
          value={this.props.value || ''}
          onChange={(e) => {
            this.props.onChange({
              type: 'textInput',
              value: e.target.value
            });
          }}
        />
      </div>
    );
  }
}

export default TextInputBlock;
