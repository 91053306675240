import React, { useState, useEffect, useContext } from 'react';
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Button, Layout, notification} from 'antd';
import Cookies from "universal-cookie";
import Echo from 'laravel-echo';

import AxiosContext from "./context/AxiosContext";
import UserContext from "./context/UserContext";

import AppSider from './layout/AppSider/AppSider';

import 'antd/dist/antd.css'
import './App.css';
import TacticEditor from "./components/TacticEditor/TacticEditor";
import ForceTacticReloadContext from "./context/ForceTacticReloadContext";
import ForceUserReloadContext from "./context/ForceUserReloadContext";
import TacticEditorContext from "./context/TacticEditorContext";
import env from "react-dotenv";

window.io = require('socket.io-client');

export default function App () {

  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [game, setGame] = useState('csgo');
  const [isEditorOpened, setIsEditorOpened] = useState(false);
  const [editorTactic, setEditorTactic] = useState(null);
  const [editorMode, setEditorMode] = useState('create');
  const [forceTacticReload, setForceTacticReload] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const axiosInstance = useContext(AxiosContext);

  let location = useLocation();
  let navigate = useNavigate();

  const onTacticEditorOpen = (tactic, mode) => {
    setEditorTactic(tactic);
    setEditorMode(mode);
    setIsEditorOpened(true);
  }

  const onForceUserReload = () => {
    axiosInstance.get('/api/me')
      .then((response) => {
        setUser(response.data);
      }).catch(error => console.log(error));
  }

  const loadUnreadNotificationCount = () => {
    axiosInstance.get('/api/notification/unread')
      .then((response) => {
        setUnreadNotificationCount(response.data);
      }).catch(error => console.log(error));
  }

  useEffect(() => {
    if (!window.location.search.replace(/\?token=/, '') && location.pathname === '/') {
      navigate('/mainFeed/csgo')
    }
  });

  useEffect(() => {
    const cookies = new Cookies();

    let tempToken = window.location.search.replace(/\?token=/, '');
    if (tempToken) {
      window.history.replaceState('', 'document.title', window.location.href.replace(window.location.search, ''));
    }
    if (!tempToken) {
      tempToken = cookies.get('token');
    }
    if (tempToken) {
      cookies.set('token', tempToken, {
        maxAge: 3600 * 24 * 30,
        path: '/',
      });
      axiosInstance.interceptors.request.use(function (config) {
        config.headers.Authorization = 'Bearer ' + tempToken;
        return config;
      });
      setToken(tempToken);

      axiosInstance.get('/api/me')
        .then((response) => {
          setUser(response.data);
          if (typeof window.io !== "undefined") {
            window.Echo = new Echo({
              broadcaster: "socket.io",
              host: env.WEBSOCKETS_URL,
              auth: {
                headers: {
                  Authorization: 'Bearer ' + cookies.get('token'),
                  Accept: "application/json",
                },
              }
            });
          }
          loadUnreadNotificationCount();
          if (location.pathname === '/') {
            setTimeout(() => {
              navigate('/mainFeed/csgo');
            }, 100);
          }
        }).catch(error => console.log(error));
    }
  }, []);

  useEffect(() => {
    if (document.body.classList.contains('fullscreen-modal-opened') && !isEditorOpened) {
      document.body.classList.remove('fullscreen-modal-opened');
    }
    if (!document.body.classList.contains('fullscreen-modal-opened') && isEditorOpened) {
      document.body.classList.add('fullscreen-modal-opened');
    }
  }, [isEditorOpened]);

  useEffect(() => {
    if (!user) {
      return;
    }
    window.Echo.channel(env.QUEUE_PREFIX + 'notification.' + user.hash)
      .stopListening('.Notification')
      .listen('.Notification', (e) => {
        loadUnreadNotificationCount();
      })
  }, [user]);

  const { Content } = Layout;

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <AppSider
          axiosInstance={axiosInstance}
          user={user}
          game={game}
          unreadNotificationCount={unreadNotificationCount}
          setUnreadNotificationCount={setUnreadNotificationCount}
          onCreateTactic={() => {
            if (user) {
              setIsEditorOpened(true);
              setEditorTactic(null);
            } else {
              notification.info({
                message: (
                  <span>
                    You can create tactics with a Varcharr account. By continuing, you agree to our User Agreement and Privacy Policy.
                  </span>
                ),
                btn: (
                  <Button type="primary" onClick={() => { window.location.href = env.API_URL + '/auth/steam/redirect' }}>
                    Login via Steam
                  </Button>
                ),
                duration: 9,
              });
            }
          }}
          onLogout={() => {
            console.log('onLogout');
            const cookies = new Cookies();
            cookies.remove('token');
            setTimeout(() => {
              window.location.pathname = '/';
            }, 500);
          }}
        />
        <Layout className="app-content-wrap">
          <Content className="app-content">
            <UserContext.Provider value={user}>
              <ForceUserReloadContext.Provider value={onForceUserReload}>
                <ForceTacticReloadContext.Provider value={forceTacticReload}>
                  <TacticEditorContext.Provider value={onTacticEditorOpen}>
                    <Outlet />
                  </TacticEditorContext.Provider>
                </ForceTacticReloadContext.Provider>
              </ForceUserReloadContext.Provider>
            </UserContext.Provider>
          </Content>
        </Layout>
      </Layout>
      {isEditorOpened ? (
        <TacticEditor
          axiosInstance={axiosInstance}
          user={user}
          tactic={editorTactic}
          mode={editorMode}
          navigate={navigate}
          onClose={(reload) => {
            setIsEditorOpened(false);
            setEditorTactic(null);
            setEditorMode('create')
            if (reload) {
              setForceTacticReload(true);
              setTimeout(() => {
                setForceTacticReload(false);
              }, 1000)
            }
          }}
        />
      ) : null}
    </>
  );
}
