import React from "react";

import 'antd/dist/antd.css'
import './IconButton.css';

class IconButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pressed: false,
    };
  }

  onMouseDown = () => {
    if (this.props.cantPress) {
      return;
    }
    this.setState({
      pressed: true,
    });
  }

  onMouseUp = () => {
    if (this.props.cantPress) {
      return;
    }
    this.setState({
      pressed: false,
    });
  }

  render() {
    return (
      <button
        className={
          "icon-button "
          + this.props.size
          + " "
          + (this.state.pressed ? 'pressed ' : '')
          + (this.props.cantPress ? 'cant-press ' : '')
          + (this.props.className)
        }
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseUp}
        onClick={this.props.onClick}
      >
        {this.props.icon}{this.props.title}{this.props.iconBehind}
      </button>
    );
  }
}

export default IconButton;
