import React from "react";

import {Col, Dropdown, Menu, Row} from "antd";
import TextInputBlock from "./TextInputBlock";
import TextareaBlock from "./TextareaBlock";
import BlockAdder from "../BlockAdder";

import './MoveBlock.css';
import BlockControl from "../BlockControl";
import ImageBlock from "./ImageBlock";
import YoutubeBlock from "./YoutubeBlock";
import GifBlock from "./GifBlock";

class MoveBlock extends React.Component {

  updateName = (blockData) => {
    let move = this.getMoveCopy();
    move.name = blockData.value;
    this.props.onMoveChange(move);
  }

  updateSummary = (blockData) => {
    let move = this.getMoveCopy();
    move.summary = blockData.value;
    this.props.onMoveChange(move);
  }

  getMoveCopy = () => {
    return JSON.parse(JSON.stringify(this.props.move));
  }

  updatePlayer = (player) => {
    let move = this.getMoveCopy();
    move.player = player;
    this.props.onMoveChange(move);
  }

  onBlockAdd = (blockDefaultData, afterIndex) => {
    let move = this.getMoveCopy();
    if (afterIndex === null) {
      move.blocks.push(blockDefaultData);
    } else if (afterIndex === -1) {
      move.blocks.unshift(blockDefaultData);
    } else {
      move.blocks.splice(afterIndex + 1, 0, blockDefaultData);
    }
    this.props.onMoveChange(move);
  }

  onBlockChange = (blockData, index) => {
    let move = this.getMoveCopy();
    move.blocks[index].value = blockData;
    this.props.onMoveChange(move);
  }

  onBlockDelete = (index) => {
    let move = this.getMoveCopy();
    move.blocks.splice(index, 1);
    this.props.onMoveChange(move);
  }

  onBlockUp = (index) => {
    let move = this.getMoveCopy();
    const block = move.blocks[index];
    move.blocks[index] = move.blocks[index - 1];
    move.blocks[index - 1] = block;
    this.props.onMoveChange(move);
  }

  onBlockDown = (index) => {
    let move = this.getMoveCopy();
    const block = move.blocks[index];
    move.blocks[index] = move.blocks[index + 1];
    move.blocks[index + 1] = block;
    this.props.onMoveChange(move);
  }

  render() {
    return (
      <div className={"move-wrap-block " + (this.props.className || '')}>
        <Row>
          <Col flex="30px" className="left-column">
            <span className={"move-number-label " + (this.props.move.name ? 'highlighted' : '')}>
              {this.props.index + 1}
            </span>
          </Col>
          <Col flex="auto">
            <TextInputBlock
              value={this.props.move.name}
              className="move-block"
              placeholder={"Title Move #" + (this.props.index + 1)}
              onChange={this.updateName}
            />
            <TextareaBlock
              value={this.props.move.summary}
              className="move-block"
              placeholder="Summary Move"
              onChange={this.updateSummary}
            />
            <BlockAdder
              isMove={true}
              moveIndex={this.props.index}
              afterIndex={-1}
              onBlockAdd={this.onBlockAdd}
            />
            {this.props.move.blocks.map((block, index) => {
              return (
                <BlockControl
                  key={'block-' + index}
                  blockIndex={index}
                  showUp={index !== 0}
                  showDown={index < this.props.move.blocks.length - 1}
                  onBlockDelete={() => this.onBlockDelete(index)}
                  onBlockUp={() => this.onBlockUp(index)}
                  onBlockDown={() => this.onBlockDown(index)}
                >
                  {
                    {
                      text: (
                        <TextareaBlock
                          value={block.value}
                          className="regular-block"
                          placeholder="Enter text"
                          onChange={(blockData) => this.onBlockChange(blockData.value, index)}
                        />
                      ),
                      image: (
                        <ImageBlock
                          value={block.value}
                          isMove={true}
                          onChange={(blockData) => this.onBlockChange(blockData.value, index)}
                          axiosInstance={this.props.axiosInstance}
                        />
                      ),
                      youtube: (
                        <YoutubeBlock
                          value={block.value}
                          isMove={true}
                          onChange={(blockData) => this.onBlockChange(blockData.value, index)}
                        />
                      ),
                      gif: (
                        <GifBlock
                          value={block.value}
                          isMove={true}
                          onChange={(blockData) => this.onBlockChange(blockData.value, index)}
                        />
                      ),
                    }[block.type]
                  }
                  <BlockAdder
                    isMove={true}
                    moveIndex={this.props.index}
                    afterIndex={index}
                    onBlockAdd={this.onBlockAdd}
                  />
                </BlockControl>
              );
            })}
          </Col>
          <Col flex="86px" className="right-column">
            <Dropdown
              placement="bottomLeft"
              overlay={(
                <Menu className="move-player-select">
                  {[1, 2, 3, 4, 5].map((player) => {
                    return (
                      <Menu.Item
                        key={player}
                        className="move-player-select-item"
                        onClick={() => this.updatePlayer(player)}
                      >
                        <span className="selected">P{player}</span>
                        <span>Player # {player}</span>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              )}
              trigger={['click']}
            >
              <div className="player-select">
                {this.props.move.player ? (
                  <span className="selected">P{this.props.move.player}</span>
                ) : (
                  <span className="not-selected">Select</span>
                )}
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.147052 1.47926L3.68622 4.87097C3.77839 4.96313 3.88899 5 3.99959 5C4.11019 5 4.22078 4.96313 4.31295 4.87097L7.85212 1.47926C8.01802 1.31336 8.03645 1.03687 7.85212 0.852535C7.68622 0.686636 7.40972 0.686636 7.22539 0.852535L3.99959 3.94931L0.77378 0.852535C0.589449 0.686636 0.312951 0.686636 0.147052 0.852535C-0.0372796 1.03687 -0.0188464 1.31336 0.147052 1.47926Z" fill="#333333"/>
                </svg>
              </div>
            </Dropdown>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MoveBlock;
