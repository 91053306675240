import React from "react";

import IconButton from "../../../ui/IconButton/IconButton";
import './YoutubeBlock.css';

class YoutubeBlock extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReadonly: true,
      canApply: false,
      videoId: null,
    }
  }

  onChange = (e) => {
    const videoId = this.getVideoId(e.target.value);
    this.setState({
      canApply: !!videoId,
      videoId,
    });
  }

  getVideoId = (url) => {
    const match = url.match(/youtube\.com\/watch\?v=(.+)&|youtube\.com\/watch\?v=(.+)|youtube\.com\/embed\/([^"]+)/m);
    return (match && match.length > 1) ? match[1] || match[2] || match[3]  : null;
  }

  render() {
    return (
      <div className={"youtube-block " + (this.props.value.id ? 'has-link ' : '') + (this.props.isMove ? 'is-move' : '')}>
        {this.props.value.id ? (
          <iframe
            width={this.props.isMove ? "704" : "820"}
            height={this.props.isMove ? "396" : "461"}
            src={"https://www.youtube.com/embed/" + this.props.value.id}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <div className="action-row">
            <svg width="68" height="60" viewBox="0 0 68 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_725_17553)">
                <path opacity="0.4" d="M45 13.125V46.875C45 49.9219 42.4219 52.5 39.375 52.5H5.625C2.57812 52.5 0 49.9219 0 46.875V13.125C0 10.0781 2.57812 7.5 5.625 7.5H39.375C42.4219 7.5 45 10.0781 45 13.125Z" fill="#AAC4F8"/>
                <path d="M67.5 14.8825V44.9997C67.5 48.0466 64.1016 49.8044 61.6406 48.0466L48.75 39.2575V20.7419L61.6406 11.9528C64.1016 10.195 67.5 11.9528 67.5 14.8825Z" fill="#AAC4F8"/>
              </g>
              <defs>
                <clipPath id="clip0_725_17553">
                  <rect width="67.5" height="60" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <input
              type="text"
              placeholder="URL Youtube"
              readOnly={this.state.isReadonly}
              onFocus={() => this.setState({ isReadonly: false })}
              onBlur={() => this.setState({ isReadonly: true })}
              onChange={this.onChange}
            />
            <IconButton
              title="Apply"
              size="big"
              className={this.state.canApply ? "" : "disabled"}
              cantPress={!this.state.canApply}
              onClick={() => {
                if (this.state.videoId) {
                  this.props.onChange({
                    type: 'youtube',
                    value: {
                      id: this.state.videoId
                    }
                  });
                }
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default YoutubeBlock;
