import React from "react";

import './BlockControl.css';
import {Col, Row} from "antd";

class BlockControl extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <div className={"block-control "}>
        <Row>
          <Col flex="60px">
            <div className="control-button-wrap">
              {this.props.showUp ? (
                <div className="control-button" onClick={this.props.onBlockUp}>
                  <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 4.896 0.176 L 8.833 4.113 C 8.938 4.219 9.009 4.359 9.009 4.5 C 9.009 4.641 8.938 4.781 8.833 4.887 C 8.622 5.133 8.271 5.133 8.06 4.887 L 5.071 1.934 L 5.071 16.066 L 3.946 16.066 L 3.946 1.934 L 0.958 4.887 C 0.747 5.133 0.396 5.133 0.185 4.887 C -0.026 4.641 -0.062 4.324 0.185 4.113 L 4.122 0.176 C 4.333 -0.07 4.685 -0.07 4.896 0.176 Z" fill="#828282"/>
                  </svg>
                </div>
              ) : null}
              {this.props.showDown ? (
                <div className="control-button" onClick={this.props.onBlockDown}>
                  <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 3.946 1.934 L 5.071 1.934 L 5.071 16.066 L 8.06 13.113 C 8.271 12.867 8.622 12.867 8.833 13.113 C 8.938 13.219 9.009 13.359 9.009 13.5 C 9.009 13.641 8.938 13.781 8.833 13.887 L 4.896 17.824 C 4.685 18.07 4.333 18.07 4.122 17.824 L 0.185 13.887 C -0.062 13.676 -0.062 13.324 0.185 13.113 C 0.431 12.902 0.747 12.867 0.958 13.113 L 3.946 16.066 L 3.946 1.934 Z" fill="#828282"/>
                  </svg>
                </div>
              ) : null}
            </div>
          </Col>
          <Col flex="auto">
            {this.props.children}
          </Col>
          <Col flex="60px">
            <div className="control-button-wrap">
              <div className="control-button" onClick={this.props.onBlockDelete}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.9179 0.000295356C16.6386 0.00688783 16.3733 0.123788 16.1799 0.325445L8.99976 7.50557L1.81964 0.325445C1.72101 0.224066 1.60305 0.143509 1.47273 0.0885449C1.34241 0.0335806 1.20239 0.00532667 1.06096 0.00545646C0.850772 0.00571678 0.645438 0.0686277 0.471182 0.186153C0.296927 0.303678 0.161662 0.470481 0.0826669 0.665255C0.00367161 0.860029 -0.0154675 1.07393 0.0276943 1.27963C0.0708562 1.48534 0.17436 1.67351 0.324982 1.8201L7.50511 9.00023L0.324982 16.1804C0.223539 16.2778 0.142548 16.3944 0.0867547 16.5235C0.030961 16.6526 0.00148563 16.7915 5.47265e-05 16.9321C-0.00137618 17.0728 0.025266 17.2123 0.0784213 17.3425C0.131577 17.4727 0.210176 17.5909 0.309617 17.6904C0.409058 17.7898 0.527341 17.8684 0.657539 17.9216C0.787736 17.9747 0.927232 18.0014 1.06786 17.9999C1.20848 17.9985 1.3474 17.969 1.47649 17.9132C1.60558 17.8574 1.72224 17.7765 1.81964 17.675L8.99976 10.4949L16.1799 17.675C16.2773 17.7765 16.3939 17.8574 16.523 17.9132C16.6521 17.969 16.791 17.9985 16.9317 17.9999C17.0723 18.0014 17.2118 17.9747 17.342 17.9216C17.4722 17.8684 17.5905 17.7898 17.6899 17.6904C17.7893 17.5909 17.8679 17.4727 17.9211 17.3425C17.9743 17.2123 18.0009 17.0728 17.9995 16.9321C17.998 16.7915 17.9686 16.6526 17.9128 16.5235C17.857 16.3944 17.776 16.2778 17.6745 16.1804L10.4944 9.00023L17.6745 1.8201C17.8283 1.67261 17.934 1.48209 17.9776 1.27351C18.0212 1.06493 18.0008 0.848034 17.919 0.65127C17.8372 0.454506 17.6978 0.28706 17.5192 0.170892C17.3405 0.0547246 17.131 -0.00474062 16.9179 0.000295356Z" fill="#828282"/>
                </svg>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BlockControl;
