import React, { useState, useContext, useEffect, useRef } from "react";
import moment from "moment";

import './Account.css';
import {Avatar, Col, Row} from "antd";
import UserContext from "../../context/UserContext";

export default function Account () {
    const user = useContext(UserContext);
    console.log(user);
    return (
        <div className="account-wrap">
            <h1>Account</h1>
            {user ? (
                <Row>
                    <Col>
                        <Avatar size={60} src={user.avatar}/>
                        <span className="name">{user.name}</span>
                        <span className={"plan " + user.plan}>{user.plan}</span>
                        <span className="registered">until {moment(user.created_at).format("MMMM D, YYYY")}</span>
                    </Col>
                </Row>
            ) : null}
        </div>
    );
}