import React from "react";

import './TextInput.css';

class TextInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      focus: false,
    };
  }

  render() {
    return (
      <>
        <div className={"text-input " + (this.state.focus ? 'focused ' : '') + (this.props.error ? 'error ' : '') + (this.props.className || '')}>
          <div className="text-input-background"/>
          <label>{this.props.label}</label>
          <input
            type="text"
            value={this.props.value || ''}
            onChange={(e) => this.props.onChange(e.target.value)}
            onFocus={() => this.setState({ focus: true })}
            onBlur={() => this.setState({ focus: false })}
          />
        </div>
        {this.props.error ? (
          <span className="text-input-error-message">{this.props.error}</span>
        ) : null}
      </>
    );
  }
}

export default TextInput;
