import React, {useContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, useParams, useNavigate, Outlet } from "react-router-dom";
import axios from "axios";
import env from "react-dotenv";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import FeedView from "./views/FeedView";
import TeamsView from "./views/TeamsView";
import Tactic from "./components/Tactic/Tactic";
import Team from "./components/Team/Team";

import AxiosContext from "./context/AxiosContext";
import UserContext from "./context/UserContext";
import TacticEditorContext from "./context/TacticEditorContext";
import ForceTacticReloadContext from "./context/ForceTacticReloadContext";
import Info from "./components/Info/Info";
import Account from "./components/Account/Account";
import LoginViaSteam from "./dummies/LoginViaSteam";

let axiosInstance = axios;
axiosInstance.defaults.baseURL = env.API_URL;

const TacticWrapper = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const axiosInstance = useContext(AxiosContext);
  const user = useContext(UserContext);
  const onTacticEditorOpen = useContext(TacticEditorContext);
  const forceReload = useContext(ForceTacticReloadContext);
  return (
    <Tactic
      feed={props.feed}
      game={params.game}
      hash={params.alias.split('-')[0]}
      teamHash={params.teamHash}
      axiosInstance={axiosInstance}
      user={user}
      forceReload={forceReload}
      onTacticEditorOpen={onTacticEditorOpen}
      navigate={navigate}
    />
  );
}

const TeamWrapper = () => {
  const params = useParams();
  const axiosInstance = useContext(AxiosContext);
  const user = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <Team
      hash={params.hash}
      axiosInstance={axiosInstance}
      user={user}
      navigate={navigate}
    />
  );
}

const RootTeamFeed = () => {
  const params = useParams();
  const axiosInstance = useContext(AxiosContext);
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const [teams, setTeams] = useState([]);
  const [teamsLoaded, setTeamsLoaded] = useState(false);

  const teamHash = params.teamHash;

  useEffect(() => {
    if (user) {
      loadTeams()
    }
  }, []);

  useEffect(() => {
    if (user) {
      loadTeams()
    }
  }, [user]);

  useEffect(() => {
    if (!teamHash && teamsLoaded) {
      if (teams.length > 0) {
        navigate('/teamFeed/' +teams[0].hash);
      } else {
        navigate('/teams');
      }
    }
    if (!teamHash && !teamsLoaded && user) {
      loadTeams()
    }
  }, [teamHash]);

  const loadTeams = () => {
    if (teamHash || teamsLoaded) {
      return;
    }
    axiosInstance.get('/api/teams')
      .then((response) => {
        setTeams(response.data.teams);
        setTeamsLoaded(true);
        if (response.data.teams.length > 0) {
          navigate('/teamFeed/' + response.data.teams[0].hash);
        } else {
          navigate('/teams');
        }
      }).catch(error => console.log(error));
  };

  if (!user) {
    return <LoginViaSteam/>;
  }

  return <Outlet />;
}

ReactDOM.render(
  <React.StrictMode>
    <AxiosContext.Provider value={axiosInstance}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="mainFeed">
              <Route path=":game" element={<FeedView feed="mainFeed"/>} />
              <Route path=":game/:alias" element={<TacticWrapper feed="mainFeed"/>} />
            </Route>
            <Route path="teamFeed" element={<RootTeamFeed />}>
              <Route path=":teamHash" element={<FeedView feed="teamFeed"/>} />
              <Route path=":teamHash/:alias" element={<TacticWrapper feed="teamFeed"/>} />
            </Route>
            <Route path="saved">
              <Route path=":game" element={<FeedView feed="saved"/>} />
              <Route path=":game/:alias" element={<TacticWrapper feed="saved"/>} />
            </Route>
            <Route path="liked">
              <Route path=":game" element={<FeedView feed="liked"/>} />
              <Route path=":game/:alias" element={<TacticWrapper feed="liked"/>} />
            </Route>
            <Route path="subscriptions">
              <Route path=":game" element={<FeedView feed="subscriptions"/>} />
              <Route path=":game/:alias" element={<TacticWrapper feed="subscriptions"/>} />
            </Route>
            <Route path="author/:author">
              <Route path=":game" element={<FeedView feed="author"/>} />
              <Route path=":game/:alias" element={<TacticWrapper feed="mainFeed"/>} />
            </Route>
            <Route path="teams" element={<TeamsView />} />
            <Route path="team/:hash" element={<TeamWrapper />} />
            <Route path="/policies" element={<Info page="privacy-policy"/>} />
            <Route path="/policies/privacy-policy" element={<Info page="privacy-policy"/>} />
            <Route path="/policies/terms-of-use" element={<Info page="terms-of-use"/>} />
            <Route path="/policies/cookie-policy" element={<Info page="cookie-policy"/>} />
            <Route path="/account" element={<Account />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AxiosContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

window.addEventListener('scroll', function(e) {
  if (window.scrollY > 125) {
    document.getElementById('root').classList.add("scrolled");
  } else {
    document.getElementById('root').classList.remove("scrolled");
  }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
