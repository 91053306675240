import React from "react";

import './SelectInput.css';

class SelectInput extends React.Component {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      focus: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.ref && !this.ref.current.contains(event.target)) {
      this.setState({ focus: false })
    }
  }

  render() {
    return (
      <div ref={this.ref} className='select-input-wrapper'>
        <div className={"select-input " + (this.state.focus ? 'focused ' : '') + (this.props.className || '')}>
          <div className="select-input-background"/>
          <label>{this.props.label}</label>
          <span
            className="select-value"
            onClick={() => this.setState({ focus: true })}
          >
            {this.props.values[this.props.value] || (<span className='no-value'>Choose value</span>)}
            <svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.5 3L4.66506 0H0.334936L2.5 3Z" fill="#333333"/>
            </svg>
          </span>
        </div>
        {this.state.focus ? (
          <div className="select-input-list">
            {Object.keys(this.props.values).map((gameValue) => {
              return (
                <div
                  key={gameValue}
                  onClick={() => {
                    this.setState({
                      focus: false,
                    }, () => {
                      this.props.onChange(gameValue)
                    })
                  }}
                  className={"select-input-list-item " + (gameValue === this.props.value ? 'selected' : '')}
                >
                  {this.props.values[gameValue]}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

export default SelectInput;
