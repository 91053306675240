import React from "react";

import './TextareaBlock.css';

class TextareaBlock extends React.Component {

  constructor(props) {
    super(props);
    this.textarea = React.createRef();
  }

  componentDidMount() {
    this.autoHeight();
    this.textarea.current.setAttribute("style", "height:" + (this.textarea.current.scrollHeight) + "px;overflow-y:hidden;");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.autoHeight();
  }

  autoHeight = () => {
    this.textarea.current.style.height = "auto";
    this.textarea.current.style.height = (this.textarea.current.scrollHeight + 1) + "px";
  }

  render() {
    return (
      <div className={"textarea-block " + this.props.className}>
        <textarea
          rows="1"
          ref={this.textarea}
          value={this.props.value || ''}
          placeholder={this.props.placeholder}
          onChange={(e) => {
            this.autoHeight();
            this.props.onChange({
              type: 'textarea',
              value: e.target.value
            });
          }}
        />
      </div>
    );
  }
}

export default TextareaBlock;
